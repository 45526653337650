import { Component, OnInit } from '@angular/core';
import { LoadingService } from 'src/app/services/loading.service';
import { MainCrudService } from 'src/app/services/main-crud.service';
import { Utils } from 'src/app/utils';
import html2canvas from 'html2canvas';
import { ToastrService } from 'ngx-toastr';
import { FilterService } from 'src/app/services/filter.service';

@Component({
  selector: 'v360-table',
  templateUrl: './table.component.html',
  styleUrls: ['./table.component.scss']
})
export class TableComponent implements OnInit {

  groupBy = '';
  queryId: number;
  reportName: string;
  reportId: number;
  title = '';
  style = '';
  columnsToCurrency = [];
  columnsToPercentage = [];
  columnsToPercentagePoint = [];
  cellWidth = 120;
  tableScroll = '200px'

  dataset;
  columns = [];
  lines = [];
  values = [];
  maxColumns = 0;

  datasetValues = [];
  cellPadding = '';

  marcas = [];

  filters;

  printCanvas;
  downloadOptions = [
    {label: 'Excel', icon: 'pi pi-file-excel', command: () => this.exportExcel()},
    {label: 'PNG', icon: 'pi pi-file', command: () => this.exportPng()}
  ]

  dataSetError = false;
  showExportBtn = false;
  currencyFormat = new Intl.NumberFormat('pt-BR', {
    style: 'currency',
    currency: 'BRL',
  });

  queryConfig;
  fileName = '';

  constructor(
    private mainCrudService: MainCrudService,
    private loadingService: LoadingService,
    private toastr: ToastrService,
    private filterService: FilterService,
  ) { }

  ngOnInit() {
    this.getDataset();
    this.lines = this.lines.map(line => {
      line['rowspan'] = this.columns.length + 1;
      return line;
    });
  }

  getDataset() {
    this.mainCrudService.getDataset(this.queryId, this.reportId, null, this.filters, this.queryConfig)
      .subscribe((response: any[]) => {
        this.dataSetError = false;
        this.dataset = response;
        this.columns = this.columns.map((column, index) => {
          column['values'] = [...new Set(this.dataset.map(val => val[column.name]))];
          if (column.order == 'desc') {
            column['values'] = Utils.orderDESC(column['values']);
          } else {
            column['values'] = Utils.orderASC(column['values']);
          }
          return column;
        });
        this.extractGroupData(response);
      }, error => {
        this.toastr.error(error.error.msg || 'Houve um erro ao carregar as informações.');
        this.dataSetError = true;
      });
  }

  reloadDataset() {
    this.dataset = null;
    this.dataSetError = false;
    this.getDataset();
  }

  getVarType(val) {
    return typeof val;
  }

  customStyle(columnName, cellValue) {
    let conditional;
    for (const value of this.values) {
      if (value.name === columnName) {
        conditional = value.conditionalStyle;
      }
    }
    if (conditional) {
      let response = '';
      const keys = Utils.orderASC(Object.keys(conditional).map(val => parseInt(val)));
      for (const key of keys) {
        if (key >= cellValue) {
          response = conditional[key];
          break;
        }
      }
      return response;
    }
    return ;
  }

  getCellAlign(val, columnName) {
    let value = this.values.filter(item => item.name === columnName)[0];
    if (value && value.cellAlignment) {
      return value.cellAlignment;
    } else if (this.getVarType(val) === 'string') {
      return 'left';
    } else if (!val && val !== 0) {
      return 'left';
    }
    return 'right';
  }

  formatColumn(val, columnName) {
    if (this.columnsToCurrency.includes(columnName)) {
      return this.currencyFormat.format(val);
    } else if (this.columnsToPercentage.includes(columnName)) {
      return `${val}%`;
    } else if (this.columnsToPercentagePoint.includes(columnName)) {
      return val < 0 ? `${val}pp` : `+${val}pp`;
    } else {
      return val;
    }
  }

  extractGroupData(values) {
    for (const val of values) {
      if (this.datasetValues.length === 0) {
        this.datasetValues.push(this.createNewTableRow(val, this.groupBy));
      } else {
        let filtered = this.datasetValues.filter(data => data[this.groupBy] === val[this.groupBy]);
        // Dado do pivot ja iniciado
        if (filtered.length === 0) {
          this.datasetValues.push(this.createNewTableRow(val, this.groupBy));
        }
      }
    }
    this.datasetValues = this.datasetValues.map(data => {
      const dataValues = values.filter(val => val[this.groupBy] === data[this.groupBy]);
      for (const line of this.lines) {
        if (line.name !== this.groupBy) {
          data[line.name] = dataValues[0][line.name];
        }
      }
      this.columns[0].values.forEach(ano => {
        data[ano] = Utils.orderArrayASC(dataValues.filter(value => value['ano'] === ano), 'ranking_vendas');
        let diference = data[ano].length - this.columns[1].values.length;
        if (diference < 0) {
          let emptyItem = {};
          for (const key in data[ano][0]) {
            emptyItem[key] = '';
          }
          for (diference; diference < 0; diference++) {
            data[ano].push(emptyItem);
          }
        }
      });
      return data;
    });
  }

  createNewTableRow(val, key) {
    let prod = {};
    prod[key] = val[key];
    return prod;
  }

  extractColumns(value: any) {
    const val = Object.keys(value).map(key => {
      return {
        field: key,
        header: this.columnFormatText(key)
      }
    });
    this.columns = [...this.columns, ...val];
  }

  columnFormatText(value: string): string {
    const string = value.replace(new RegExp('_', 'g'), ' ');
    return string.charAt(0).toLocaleUpperCase() + string.slice(1);
  }

  exportPng() {
    this.loadingService.showLoading();
    let chart = document.getElementById('custom-table');
    const out = this;
    html2canvas(chart)
      .then((canvas) => {
        out.printCanvas = canvas;
        let imageData = out.printCanvas.toDataURL("image/png");
        var link = document.createElement('a');
        link.download = `${Utils.formatFileName(this.fileName, this.queryConfig)} - ${Utils.getActualDateAsString()}.png` || 'export.png';
        link.href = imageData
        link.click();
        this.loadingService.closeLoading(true);
      }).catch(error => {
        this.toastr.error('Houve um erro ao gerar o PNG dos dados, tente novamente. Caso o problema persista, avise nosso suporte.')
      })
  }

  exportExcel() {
    this.loadingService.showLoading();
    this.mainCrudService.exportExcel(this.reportId, this.queryId, this.filterService.formatFilters(this.filterService.dashboardFilter), this.queryConfig)
      .subscribe((response: any) => {
        let dataType = response.type;
        let binaryData = [];
        binaryData.push(response);
        let downloadLink = document.createElement('a');
        downloadLink.href = window.URL.createObjectURL(new Blob(binaryData, {type: dataType}));
        if (`${Utils.formatFileName(this.fileName, this.queryConfig)} - ${Utils.getActualDateAsString()}.xlsx`)
            downloadLink.setAttribute('download', `${Utils.formatFileName(this.fileName, this.queryConfig)} - ${Utils.getActualDateAsString()}.xlsx` || 'export.xlsx');
        document.body.appendChild(downloadLink);
        downloadLink.click();
        this.loadingService.closeLoading(true);
      }, error => {
        this.toastr.error(error.msg || 'Houve um erro ao exportar os dados, tente novamente.Caso o problema persista, avise nosso suporte.');
        this.loadingService.closeLoading(true);
      });
  }
}
