import { AfterViewChecked, Component, Input, OnInit, ViewChild } from '@angular/core';
import { NavigationStart, Router } from '@angular/router';
import { PageParamServiceAkita } from '@state/page/param/page-param.service';
import * as _ from 'lodash';
import { MultiSelect } from 'primeng/multiselect';
import { OverlayPanel } from 'primeng/overlaypanel';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { filter } from 'rxjs/operators';

@Component({
  selector: 'v360-param-selector',
  templateUrl: './param-selector.component.html',
  styleUrls: ['./param-selector.component.scss']
})

export class ParamSelectorComponent implements OnInit, AfterViewChecked {
  wasParamInserted$: any;
  allPageParamsAreInitialized: boolean;
  @Input('items') set transformItemsArrayToValidListOfItems(items: string) {
    const parsedItems = JSON.parse(this.replaceForDoubleQuote(items)) as {shownValue: string, DBValue: string}[]
    this.items = parsedItems
    this._items = parsedItems?.map((item, index) => {
      return { name: Object.keys(item)[0], value:Object.values(item)[0], id: index }
    });
  }
  @Input('unique') unique: boolean = false;
  @Input('default_value') set defaultValueKey(defaultValue: string | null) {
    if(!defaultValue){
      return
    } 
    this._defaultValue = defaultValue
  }
  @Input('reportId') reportId: number;
  @Input('placeholder') placeholder: string;
  @Input('mandatory') mandatory = false;

  @ViewChild('select') select: MultiSelect;

  @ViewChild('mandatoryOverlay') mandatoryOverlay: OverlayPanel;
  
  items: {shownValue: string, DBValue: string}[] = [];
  _items: {name: string, value:string, id: number}[] = [];

  _defaultValue: string = '';

  _active: boolean = false;

  showSelectAll = true;
  selectedItems: {name: string, value: string, id: number}[] = [];
  previousSelectedItems: {name: string, value: string, id: number}[] = [];
  wasInitialized: boolean = false;

  protected _onDestroy = new Subject<void>();

  overlayOpened: boolean = false;
  constructor(private pageParamServiceAkita: PageParamServiceAkita,
    private router: Router) {
    this.router.events
      .pipe(filter(event => event instanceof NavigationStart))
      .subscribe(({url}: NavigationStart) => {
        if (url.includes('report')) {
          const goingToPage = url.split("/");
          if(+goingToPage[2] != this.reportId){
            this.destroy()
          }
          if(!this.wasInitialized){
            this.destroy()
          }
        }else{
          this.destroy()
        }
      });
  }

  ngOnInit(): void {
    if(!this._defaultValue){
      this.pageParamServiceAkita.getAllParams().forEach(param => {
        if(param.param.toLowerCase() == this.placeholder.toLowerCase()){
          this._defaultValue = param.value.toLowerCase()
        }
      })
    }
    if(this._defaultValue){
      this._defaultValue = this._items.filter(item => {return (item.name.toLowerCase() == this._defaultValue.toLowerCase()) || (item.value.toLowerCase() == this._defaultValue.toLowerCase())})[0].value
      this.selectedItems = this._items.filter(item => {return (item.name.toLowerCase() == this._defaultValue.toLowerCase()) || (item.value.toLowerCase() == this._defaultValue.toLowerCase())})
      this.wasInitialized = true;
    }

    this.pageParamServiceAkita.add(this.placeholder, this._defaultValue, this.wasInitialized, this.selectedItemsLabelAsString());

    this.pageParamServiceAkita.selectActive()
    
    if(this._defaultValue){
      this.pageParamServiceAkita.updateUIStateIfAllParamsAreInitialized()
    }
    
    this.pageParamServiceAkita.getPageParamUIState().pipe(takeUntil(this._onDestroy)).subscribe(allPageParamsAreInitialized => {
      if(allPageParamsAreInitialized){
        this.allPageParamsAreInitialized = true;
        this._active = true;
        if(!this.wasInitialized){
          this.wasInitialized = true;
        }
      }
    })
    this.pageParamServiceAkita.observeActive().pipe(takeUntil(this._onDestroy)).subscribe(activePageParam => {
      if(activePageParam){
        if(!this.allPageParamsAreInitialized){
          this._active = activePageParam.toLowerCase() == this.placeholder.toLowerCase()
        }
      }else{
        this._active = true
      }
    })
  }

  selectItemIfUnique(event, item) {
    if(this.unique){
      this.selectedItems = [item];
      this.select.close(event);
    }
  }

  theresChanges(): boolean {
    return JSON.stringify(this.previousSelectedItems) !== JSON.stringify(this.selectedItems);
  }
  
  ngAfterViewChecked(): void {
    if(!this.wasInitialized && this._active){
      if(this.mandatoryOverlay){
        this.mandatoryOverlay.show(new Event("mouse"), this.select.el.nativeElement);
      }
    }
  }

  destroy(){
    if(!this.wasInitialized){
      this.pageParamServiceAkita.deleteByParam(this.placeholder)
      this.pageParamServiceAkita.updateUIStateIfAllParamsAreInitialized()
    }
    this._onDestroy.next();
    this._onDestroy.complete();
  }

  replaceForDoubleQuote(text: string){
    return text.replace(/'/g, '"')
  }
  
  selectedItemsAsString(): string{
    let itemsAsString = this.selectedItems.reduce((acumulador, valorAtual) => {
      return acumulador + valorAtual.value + ','
    }, '');
    return (itemsAsString.length > 0) ? itemsAsString.substring(0, itemsAsString.length-1): ''
  }

  selectedItemsLabelAsString(): string{
    let itemsAsString = this.selectedItems.reduce((acumulador, valorAtual) => {
      return acumulador + valorAtual.name + ','
    }, '');
    return (itemsAsString.length > 0) ? itemsAsString.substring(0, itemsAsString.length-1): ''
  }

  selectOpen() {
    if (this.selectedItems) {
      this.previousSelectedItems = JSON.parse(JSON.stringify(this.selectedItems));
    }
    this.overlayOpened = true;
  }

  selectClose(event) {
    if ((this.mandatory && this.selectedItems?.length === 0)) {
      this.select.show();
      return;
    }
    if(!this.wasInitialized){
      this.wasInitialized = true;
      this.pageParamServiceAkita.initialize(this.placeholder);
      if(this._active){
        this.pageParamServiceAkita.activeNext();
      }
    }
    if(this.theresChanges()){
      this.previousSelectedItems = [...this.selectedItems];
      this.pageParamServiceAkita.updateValue(this.placeholder, this.selectedItemsAsString(), this.selectedItemsLabelAsString())
    }
    this.overlayOpened = false;
  }

  itemChanged(event) {
    if (this.selectedItems?.length === 0 && this.mandatory) {
      this.selectedItems.push(event.itemValue);
      return;
    }
    if (this.unique && this.selectedItems.length > 1) {
      this.selectedItems.shift();
    }
    this.selectItemIfUnique(event.originalEvent, event.itemValue)
  }

  close(event) {
    this.select.close(event);
  }

  clearSelected(event) {
    if (this.select._filterValue && (this.select._filterValue.length > 0)) {
      this.select._filterValue = "";
    }
    if (this.selectedItems.length > 0) {
      this.selectedItems = [];
    }
    this.select.filterValue = '';
  }

  somente(event, item) {
    this.selectedItems = [item];
    this.select.close(event);
  }
}
