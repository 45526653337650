import { Injectable } from '@angular/core';
import { QueryEntity } from '@datorama/akita';
import { FilterStore, FilterState } from './filter.store';

@Injectable({ providedIn: 'root' })
export class FilterQuery extends QueryEntity<FilterState> {
  filterName: string;

  getAppliedFilters$ = this.selectAll()
  getFiltersByFilterName$ = this.selectAll({
    filterBy: filterName => filterName.filterName == this.filterName
  })
  constructor(protected store: FilterStore) {
    super(store);
  }
  setFilterName(filterName: string){
    this.filterName = filterName;
  }
}