<div class="controls" *ngIf="dataset" 
  [hidden]="!showExportBtn"
  (mouseenter)="showExportBtn = true"
  (mouseleave)="showExportBtn = false">
  <button type="button" pButton icon="pi pi-download" label="" (click)="menu.toggle($event)" [pTooltip]="'Baixar dados'"></button>
  <p-menu #menu [popup]="true" [model]="downloadOptions"></p-menu>
</div>
<div [style]="style">
  <p-table id="custom-table" *ngIf="dataset" [value]="datasetValues" [autoLayout]="true" [scrollable]="true" 
    [scrollHeight]="tableScroll" styleClass="p-datatable-striped"
    (mouseenter)="showExportBtn = true" (mouseleave)="showExportBtn = false">
    <ng-template pTemplate="colgroup">
      <colgroup>
        <col *ngFor="let line of lines" [style.width.px]="line.cellWidth || cellWidth">
      </colgroup>
      <ng-container *ngFor="let val of columns[0].values">
        <colgroup *ngFor="let col of columns[1].values">
          <col *ngFor="let val of values" [style.width.px]="val.cellWidth || cellWidth">
        </colgroup>
      </ng-container>
    </ng-template>
    <ng-template pTemplate="header">
      <tr>
        <th *ngFor="let line of lines" [rowSpan]="line.rowspan" [style.padding]="cellPadding">
          {{ line.header }}
        </th>
        <ng-container *ngIf="!columns[0].hidden">
          <th *ngFor="let col of columns[0].values" [colSpan]="columns[1].values.length * values.length" [style.padding]="cellPadding">
            {{col}}
          </th>
        </ng-container>
      </tr>
      <tr *ngIf="!columns[1].hidden">
        <ng-container *ngFor="let val of columns[0].values">
          <th *ngFor="let col of columns[1].values" [colSpan]="values.length" [style.padding]="cellPadding">
            {{col}}
          </th>
        </ng-container>
      </tr>
      <tr>
        <ng-container *ngFor="let val of columns[0].values">
          <ng-container *ngFor="let col of columns[1].values">
            <th *ngFor="let val of values" [style.padding]="cellPadding">
              {{ val.header }} {{ val.mergeParent ? col : '' }}
            </th>
          </ng-container>
        </ng-container>
      </tr>
    </ng-template>
    <ng-template pTemplate="body" let-product>
      <tr>
        <td *ngFor="let line of lines" [style.padding]="cellPadding">
          {{ product[line.name ]}}
        </td>
        <ng-container *ngFor="let val of columns[0].values">
          <ng-container *ngFor="let col of columns[1].values">
            <ng-container *ngIf="product[val][col-1]">
              <td *ngFor="let prod of values" [style.text-align]="getCellAlign(product[val][col-1][prod.name], prod.name)" 
                [style.padding]="cellPadding" [style.word-break]="'break-word'" [style]="customStyle(prod.name, product[val][col-1][prod.name])">
                <ng-container *ngIf="product[val][col-1][prod.name] || product[val][col-1][prod.name] === 0">
                  {{ formatColumn(product[val][col-1][prod.name], prod.name) }}
                </ng-container>
                <ng-container *ngIf="!product[val][col-1][prod.name] && product[val][col-1][prod.name] !== 0">
                  -
                </ng-container>
              </td>
            </ng-container>
            <ng-container *ngIf="!product[val][col-1]">
              <td>
                t
              </td>
            </ng-container>
          </ng-container>
        </ng-container>
      </tr>
    </ng-template>
  </p-table>
</div>
<v360-error *ngIf="dataSetError" (reload)="reloadDataset()"></v360-error>
<v360-loading *ngIf="!dataset && !dataSetError"></v360-loading>