import { Component, OnInit } from "@angular/core";
import html2canvas from "html2canvas";
import { ApexOptions } from "ng-apexcharts";
import { ToastrService } from "ngx-toastr";
import { delay } from "rxjs/operators";
import { FilterService } from "src/app/services/filter.service";
import { LoadingService } from "src/app/services/loading.service";
import { MainCrudService } from "src/app/services/main-crud.service";
import { ReportsService } from "src/app/services/reports.service";
import { Utils } from "src/app/utils";
import { ChartDataset } from "../pie-chart/pie-chart.component";

@Component({
  selector: "v360-line-chart",
  templateUrl: "./line-chart.component.html",
  styleUrls: ["./line-chart.component.scss"],
})
export class LineChartComponent implements OnInit {
  printCanvas;
  downloadOptions = [
    {
      label: "Excel",
      icon: "pi pi-file-excel",
      command: () => this.exportExcel(),
    },
    {
      label: "PNG",
      icon: "pi pi-file",
      command: () => this.exportPng(this.componentId),
    },
  ];
  showExportBtn = false;
  currencyFormat = false;
  percentageFormat = false;
  precision = 0;
  abbreviation = false;
  filters;
  legendTextSize = 14;
  xAxisName = "";
  showXAxis = true;
  datasetName = "";
  dimension = "";
  reportName = "";
  queryId: number;
  reportId: number;
  dataset: ChartDataset[];
  dataSetError = false;
  chartHeight = "";
  showDataLabels = true;
  enableLegendClick = true;
  title = "";
  queryConfig;
  yAxisText = undefined;
  xAxisText = undefined;
  mainDataSeries = [];
  legendBeforeText = "";
  showLegend = true;
  chartOptions: ApexOptions;
  componentId;
  dotSize = 5;
  colorDimension;
  gridPadding = 50;
  chartStyle = "";
  fileName = "";
  fixedYAxisMin = 0;
  fixedYAxisMax: number = null;
  forceNiceScale = true;
  report;

  constructor(
    private mainCrudService: MainCrudService,
    private toastr: ToastrService,
    private filterService: FilterService,
    private loadingService: LoadingService,
    private reportService: ReportsService
  ) {}

  async ngOnInit() {
    this.componentId = `line-chart-${
      1 + Math.floor((100000 - 1) * Math.random())
    }`;
    if (!this.report) {
      this.report = await this.reportService.getSelectedReport(this.reportId);
    }
    if (this.dimension && this.dimension.includes("<")) {
      const val = this.dimension.replace("<", "").replace(">", "");
      this.dimension = this.queryConfig[val];
    }
    this.getDataSet(
      this.queryId,
      this.dimension,
      this.reportId,
      this.filters,
      this.queryConfig
    );
  }

  getDataSet(
    queryId: number,
    pivot: string,
    reportId: number,
    filters,
    queryConfig
  ) {
    this.mainCrudService
      .getDataset(queryId, reportId, pivot, filters, queryConfig)
      .pipe(delay(0))
      .subscribe(
        (response: any[]) => {
          this.dataSetError = false;
          let series = [];
          this.dataset = response;
          for (const data of this.dataset) {
            const serie = {
              name: data["group"],
              data: data.data.map((val) => {
                return { y: val[this.datasetName], x: val[this.xAxisName] };
              }),
            };
            if (data.data.length > 0) {
              this.mainDataSeries = [
                ...this.mainDataSeries,
                ...serie.data.map((serie) => serie.x),
              ];
            }
            series.push(serie);
          }
          this.mainDataSeries = [...new Set(this.mainDataSeries)].sort();
          series = series.map((serie) => {
            serie.data = this.mainDataSeries.map((date) => {
              let data = serie.data.filter((val) => val.x === date)[0];
              if (!data) {
                data = { y: null, x: date };
              }
              return data;
            });
            return serie;
          });
          this.chartOptions = this.createChartOptions(this.orderSeries(series));
        },
        (error) => {
          this.toastr.error(
            error.error.msg || "Houve um erro ao carregar as informações."
          );
          this.dataSetError = true;
        }
      );
  }

  orderSeries(series) {
    let outros;
    series = series.filter((serie) => {
      if (serie.name.toLowerCase() === "outros") {
        outros = serie;
        return false;
      }
      return true;
    });
    if (outros) {
      series.push(outros);
    }
    return series;
  }

  getDimensionColor(dimensionValue: string) {
    if (
      this.colorDimension &&
      this.queryConfig &&
      Object.keys(this.queryConfig).includes(
        this.colorDimension.replace("<", "").replace(">", "")
      )
    ) {
      const dimension =
        this.queryConfig[this.colorDimension.replace("<", "").replace(">", "")];
      this.colorDimension = dimension;
    }

    let color;
    if (
      this.report?.colors &&
      this.colorDimension &&
      this.report.colors.hasOwnProperty(this.colorDimension)
    ) {
      const colors = this.report.colors[`${this.colorDimension}`];
      color = colors[`${dimensionValue.toUpperCase()}`]
        ? colors[`${dimensionValue.toUpperCase()}`]
        : Utils.getColor(dimensionValue);
    } else {
      color = Utils.getColor(dimensionValue);
    }
    return color;
  }

  createChartOptions(series: any[]): ApexOptions {
    let colors = series.map((serie) => this.getDimensionColor(serie["name"]));
    let out = this;
    const options = {
      chart: {
        type: "line",
        animations: {
          enabled: false,
        },
        height: this.chartHeight || "400px",
        toolbar: {
          show: false,
        },
        zoom: {
          enabled: false,
        },
        parentHeightOffset: 0,
      },
      title: {
        text: this.title,
        align: "center",
        floating: true,
        style: {
          fontSize: "20px",
          fontWeight: "normal",
        },
      },
      grid: {
        padding: {
          right: this.showDataLabels ? this.gridPadding : 0,
          left: this.showDataLabels ? this.gridPadding : 15,
          top: 20,
        },
      },
      markers: {
        size: this.dotSize,
      },
      stroke: {
        width: 2,
      },
      colors: colors,
      series,
      xaxis: {
        type: "category",
        tooltip: {
          enabled: false,
        },
        labels: {
          show: this.showXAxis,
        },
        title: {
          text: this.xAxisText,
          offsetX: 0,
          offsetY: 0,
          style: {
            color: undefined,
            fontSize: "12px",
            fontFamily: "Helvetica, Arial, sans-serif",
            fontWeight: 600,
            cssClass: "apexcharts-yaxis-title",
          },
        },
      },
      yaxis: {
        forceNiceScale: this.forceNiceScale,
        labels: {
          show: true,
          formatter: (val) => {
            return out.formatValue(val);
          },
        },
        title: {
          text: this.yAxisText,
          rotate: -90,
          offsetX: 0,
          offsetY: 0,
          style: {
            color: undefined,
            fontSize: "12px",
            fontFamily: "Helvetica, Arial, sans-serif",
            fontWeight: 600,
            cssClass: "apexcharts-yaxis-title",
          },
        },
      },
      dataLabels: {
        enabled: this.showDataLabels,
        offsetY: -5,
        offsetX: 5,
        formatter: (val) => {
          return out.formatValue(val);
        },
      },
      legend: {
        show: this.showLegend,
        showForSingleSeries: true,
        fontSize: `${this.legendTextSize}px`,
        onItemClick: {
          toggleDataSeries: this.enableLegendClick,
        },
      },
      tooltip: {
        shared: true,
        intersect: false,
        followCursor: true,
        custom: function ({ series, seriesIndex, dataPointIndex, w }) {
          let tooltip;
          let header;
          let data = w.config.series
            .map((val, index) => {
              if (val.data.length > 0) {
                if (!header && val.data[dataPointIndex]?.y) {
                  header = out.legendBeforeText
                    ? `${out.legendBeforeText}: ${val.data[dataPointIndex].x}`
                    : val.data[dataPointIndex].x;
                }
                return {
                  name: val.name,
                  data: val.data[dataPointIndex].y,
                  color: w.config.colors[index],
                };
              }
            })
            .filter((val) => val);
          data = data.filter((d) => {
            return d.data != null;
          });
          data = Utils.orderArrayDESC(data, "data");
          let lines = data.map((val) => {
            return (
              `<div style="text-align: left; display: flex; align-items: center; margin-top: 5px; padding: 5px 10px">` +
              `<span class="tooltip-color" style="background-color: ${val.color}; height: 15px; width: 15px; display: inline-block; border-radius: 10px; margin-right: 10px"></span>` +
              `<span>${val.name}: <strong>${out.formatValue(
                val.data
              )}</strong></span>` +
              `</div>`
            );
          });
          tooltip =
            '<div class="arrow_box">' +
            `<div style="background-color: #eceff1; padding: 10px; border-bottom: 1px solid #dddddd">${header}</div>` +
            lines.join("");
          ("</div>");
          return tooltip;
        },
      },
    } as ApexOptions;
    if (!this.forceNiceScale) {
      options.yaxis["min"] = this.fixedYAxisMin;
      if (this.fixedYAxisMax) {
        options.yaxis["max"] = this.fixedYAxisMax;
      }
    } else {
      delete options.yaxis["min"];
    }
    return options;
  }

  formatValue(value) {
    return Utils.formatChartNumbers(
      value,
      this.currencyFormat,
      this.percentageFormat,
      this.precision,
      this.abbreviation
    );
  }

  reloadDataset() {
    this.dataset = null;
    this.dataSetError = false;
    this.getDataSet(
      this.queryId,
      this.dimension,
      this.reportId,
      this.filters,
      this.queryConfig
    );
  }

  exportPng(componentId: string) {
    this.loadingService.showLoading();
    let chart = document.getElementById(componentId);
    const out = this;
    html2canvas(chart)
      .then((canvas) => {
        out.printCanvas = canvas;
        let imageData = out.printCanvas.toDataURL("image/png");
        var link = document.createElement("a");
        link.download =
          `${Utils.formatFileName(
            this.fileName,
            this.queryConfig
          )} - ${Utils.getActualDateAsString()}.png` || "export.png";
        link.href = imageData;
        link.click();
        this.loadingService.closeLoading(true);
      })
      .catch((error) => {
        this.toastr.error(
          "Houve um erro ao gerar o PNG dos dados, tente novamente. Caso o problema persista, avise nosso suporte."
        );
      });
  }

  exportExcel() {
    this.loadingService.showLoading();
    this.mainCrudService
      .exportExcel(
        this.reportId,
        this.queryId,
        this.filterService.formatFilters(this.filterService.dashboardFilter),
        this.queryConfig
      )
      .subscribe(
        (response: any) => {
          let dataType = response.type;
          let binaryData = [];
          binaryData.push(response);
          let downloadLink = document.createElement("a");
          downloadLink.href = window.URL.createObjectURL(
            new Blob(binaryData, { type: dataType })
          );
          if (
            `${Utils.formatFileName(
              this.fileName,
              this.queryConfig
            )} - ${Utils.getActualDateAsString()}.xlsx`
          )
            downloadLink.setAttribute(
              "download",
              `${Utils.formatFileName(
                this.fileName,
                this.queryConfig
              )} - ${Utils.getActualDateAsString()}.xlsx` || "export.xlsx"
            );
          document.body.appendChild(downloadLink);
          downloadLink.click();
          this.loadingService.closeLoading(true);
        },
        (error) => {
          this.toastr.error(
            error.msg ||
              "Houve um erro ao exportar os dados, tente novamente.Caso o problema persista, avise nosso suporte."
          );
          this.loadingService.closeLoading(true);
        }
      );
  }
}
