import { Component, OnInit } from "@angular/core";
import { ApexOptions } from "ng-apexcharts";
import { delay } from "rxjs/operators";
import { LoadingService } from "src/app/services/loading.service";
import { MainCrudService } from "src/app/services/main-crud.service";
import { Utils } from "src/app/utils";
import html2canvas from "html2canvas";
import { ToastrService } from "ngx-toastr";
import { FilterService } from "src/app/services/filter.service";
import { ReportsService } from "src/app/services/reports.service";

@Component({
  selector: "v360-bar-chart",
  templateUrl: "./bar-chart.component.html",
  styleUrls: ["./bar-chart.component.scss"],
})
export class BarChartComponent implements OnInit {
  printCanvas;
  downloadOptions = [
    {
      label: "Excel",
      icon: "pi pi-file-excel",
      command: () => this.exportExcel(),
    },
    {
      label: "PNG",
      icon: "pi pi-file",
      command: () => this.exportPng(this.componentId),
    },
  ];
  showExportBtn = false;
  currencyFormat = false;
  percentageFormat = false;
  precision = 0;
  abbreviation = false;
  dataSetError = false;
  stacked = false;
  stackedType = "100%" as any;
  chartHeight = "";
  chartOptions: ApexOptions;
  title = "";
  fullTooltip = false;
  reportName = "";
  legendPosition = "bottom";
  horizontal = false;
  tooltipFormat = "";
  shortTooltipValue = false;
  tooltipPrecision = 0;

  showGrid = true;
  queryId: number;
  reportId: number;
  datasetName = "";
  dimension = "";
  xAxisName = "";
  showXAxis = true;
  showYAxis = true;
  dataLabelOffset = 0;
  xDataLabelOffset = 0;
  legendTextSize = "14px";
  tooltipTextSize = "14px";
  labelTextSize = "14px";
  enableLegendClick = true;
  labelName = "";
  showDataLabel = true;
  showLegend = true;
  color = "";
  fixedAxis = false;
  queryConfig;
  yAxisText;
  xAxisText;

  filters;
  dataset;
  mainDataSeries = [];
  componentId;
  colorDimension;

  maxValue = 0;
  chartStyle = "display: inline-block; width: 100%";
  fileName = "";
  fixedYAxisMin = 0;
  fixedYAxisMax: number = null;
  forceNiceScale = true;

  report;

  constructor(
    private mainCrudService: MainCrudService,
    private loadingService: LoadingService,
    private toastr: ToastrService,
    private filterService: FilterService,
    private reportService: ReportsService
  ) {}

  async ngOnInit() {
    this.componentId = `bar-chart-${
      1 + Math.floor((100000 - 1) * Math.random())
    }`;
    if (!this.report) {
      this.report = await this.reportService.getSelectedReport(this.reportId);
    }
    if (this.dimension && this.dimension.includes("<")) {
      const val = this.dimension.replace("<", "").replace(">", "");
      this.dimension = this.queryConfig[val];
    }
    this.getDataSet(
      this.queryId,
      this.dimension,
      this.reportId,
      this.filters,
      this.queryConfig
    );
  }

  getDataSet(
    queryId: number,
    pivot: string,
    reportId: number,
    filters,
    queryConfig
  ) {
    this.mainCrudService
      .getDataset(queryId, reportId, pivot, filters, queryConfig)
      .pipe(delay(0))
      .subscribe(
        (response: any[]) => {
          this.dataSetError = false;
          let series = [];
          this.dataset = response;
          if (this.xAxisName.includes("<")) {
            const key = this.xAxisName.replace("<", "").replace(">", "");
            this.xAxisName = this.queryConfig[key];
          }
          for (const data of this.dataset) {
            let serie;
            if (pivot) {
              serie = {
                name: data["group"],
                data: data.data.map((val) => {
                  val[this.datasetName] > this.maxValue
                    ? (this.maxValue = val[this.datasetName])
                    : "";
                  return { y: val[this.datasetName], x: val[this.xAxisName] };
                }),
              };
              if (serie.data.length > this.mainDataSeries.length) {
                this.mainDataSeries = serie.data.map((serie) => serie.x);
              }
              series.push(serie);
            } else {
              if (series.length === 0) {
                data[this.datasetName] > this.maxValue
                  ? (this.maxValue = data[this.datasetName])
                  : "";
                serie = {
                  name: this.labelName,
                  data: [
                    { y: data[this.datasetName], x: data[this.xAxisName] },
                  ],
                };
                series.push(serie);
              } else {
                series[0].data.push({
                  y: data[this.datasetName],
                  x: data[this.xAxisName],
                });
              }
            }
          }
          if (pivot) {
            series = series.map((serie) => {
              serie.data = this.mainDataSeries.map((date) => {
                let data = serie.data.filter((val) => val.x === date)[0];
                if (!data) {
                  data = { y: null, x: date };
                }
                return data;
              });
              return serie;
            });
            this.chartOptions = this.createChartOptions(
              this.orderSeries(series)
            );
          } else {
            this.chartOptions = this.createChartOptions(series);
          }
        },
        (error) => {
          this.dataSetError = true;
        }
      );
  }

  reloadDataset() {
    this.dataset = null;
    this.dataSetError = false;
    this.getDataSet(
      this.queryId,
      this.dimension,
      this.reportId,
      this.filters,
      this.queryConfig
    );
  }

  orderSeries(series) {
    let outros;
    series = series.filter((serie) => {
      if (serie.name.toLowerCase() === "outros") {
        outros = serie;
        return false;
      }
      return true;
    });
    if (outros) {
      series.push(outros);
    }
    return series;
  }

  formatValue(value) {
    return Utils.formatChartNumbers(
      value,
      this.currencyFormat,
      this.percentageFormat,
      this.precision,
      this.abbreviation
    );
  }

  formatTooltipValue(value) {
    return Utils.formatChartNumbers(
      value,
      this.tooltipFormat === "$",
      this.tooltipFormat === "%",
      this.tooltipPrecision,
      this.shortTooltipValue
    );
  }

  getDimensionColor(dimensionValue: string) {
    if (
      this.colorDimension &&
      this.queryConfig &&
      Object.keys(this.queryConfig).includes(
        this.colorDimension.replace("<", "").replace(">", "")
      )
    ) {
      const dimension =
        this.queryConfig[this.colorDimension.replace("<", "").replace(">", "")];
      this.colorDimension = dimension;
    }

    let color;
    if (
      this.report?.colors &&
      this.colorDimension &&
      this.report.colors.hasOwnProperty(this.colorDimension)
    ) {
      const colors = this.report.colors[`${this.colorDimension}`];
      color = colors[`${dimensionValue.toUpperCase()}`]
        ? colors[`${dimensionValue.toUpperCase()}`]
        : Utils.getColor(dimensionValue);
    } else {
      color = Utils.getColor(dimensionValue);
    }
    return color;
  }

  private extractTooltipVal(val) {
    if (!val.name) {
      return "";
    }
    if (this.stacked) {
      return val.pointer
        ? `<strong style="text-decoration: underline">${val.name}:</strong>`
        : `${val.name}:`;
    }
    return `${val.name}:`;
  }

  createChartOptions(series: any[]): ApexOptions {
    let colors;
    if (this.dimension) {
      colors = series.map((serie) => this.getDimensionColor(serie["name"]));
    }
    let out = this;
    let options: ApexOptions = {
      chart: {
        type: "bar",
        animations: {
          enabled: false,
        },
        stacked: out.stacked,
        height: this.chartHeight || "400px",
        toolbar: {
          show: false,
        },
        zoom: {
          enabled: false,
        },
        parentHeightOffset: 0,
      },
      title: {
        text: this.title,
        align: "center",
        floating: true,
        style: {
          fontSize: "20px",
          fontWeight: "normal",
        },
      },
      grid: {
        show: this.showGrid,
        padding: {
          top: 20,
        },
      },
      dataLabels: {
        enabled: this.showDataLabel,
        offsetY: this.dataLabelOffset,
        offsetX: this.xDataLabelOffset,
        formatter: (val, opts) => {
          return this.formatValue(val);
        },
        style: {
          fontSize: out.labelTextSize,
        },
      },
      fill: {
        opacity: 1,
      },
      series,
      xaxis: {
        type: "category",
        labels: {
          show: this.showXAxis,
        },
        title: {
          text: this.xAxisText,
          offsetX: 0,
          offsetY: 0,
          style: {
            color: undefined,
            fontSize: "12px",
            fontFamily: "Helvetica, Arial, sans-serif",
            fontWeight: 600,
            cssClass: "apexcharts-yaxis-title",
          },
        },
        tooltip: {
          enabled: false,
        },
      },
      legend: {
        show: this.showLegend,
        position: this.legendPosition as any,
        fontSize: out.legendTextSize,
        showForSingleSeries: true,
        itemMargin: {
          horizontal: 3,
        },
        onItemClick: {
          toggleDataSeries: this.enableLegendClick,
        },
      },
      tooltip: {
        followCursor: false,
        style: {
          fontSize: out.tooltipTextSize,
        },
        custom: function ({ series, seriesIndex, dataPointIndex, w }) {
          let tooltip;
          let header;
          let data = w.config.series
            .map((val, index) => {
              if (val.data.length > 0) {
                if (!header && val.data[dataPointIndex]?.y) {
                  header = val.data[dataPointIndex].x;
                }
                return {
                  name: val.name,
                  data: val.data[dataPointIndex].y,
                  color: w.config.colors[index],
                  pointer: index === seriesIndex,
                };
              }
            })
            .filter((val) => val.data);
          data = Utils.orderArrayDESC(data, "data");
          let lines = data.map((val) => {
            return (
              `<div style="text-align: left; display: flex; align-items: center; margin-top: 5px; padding: 10px;">` +
              `<span class="tooltip-color" style="background-color: ${val.color}; height: 15px; width: 15px; display: inline-block; border-radius: 10px; margin-right: 10px"></span>` +
              `<span>${out.extractTooltipVal(
                val
              )} <strong>${out.formatTooltipValue(val.data)}</strong></span>` +
              `</div>`
            );
          });
          tooltip =
            '<div class="arrow_box" style="top: 30px;">' +
            `<div style="background-color: #eceff1; padding: 10px; border-bottom: 1px solid #dddddd">${header}</div>` +
            lines.join("");
          ("</div>");
          return tooltip;
        },
      },
      yaxis: {
        forceNiceScale: this.forceNiceScale,
        labels: {
          show: this.showYAxis,
          formatter: (val) => {
            if (typeof val === "number") {
              return out.formatValue(val);
            } else {
              return val;
            }
          },
        },
        title: {
          text: this.yAxisText,
          rotate: -90,
          offsetX: 0,
          offsetY: 0,
          style: {
            color: undefined,
            fontSize: "12px",
            fontFamily: "Helvetica, Arial, sans-serif",
            fontWeight: 600,
            cssClass: "apexcharts-yaxis-title",
          },
        },
      },
      plotOptions: {
        bar: {
          horizontal: this.horizontal,
          dataLabels: {
            hideOverflowingLabels: true,
          },
        },
      },
    };
    if (!this.stackedType) {
      options.yaxis["min"] = this.fixedYAxisMin;
      if (this.fixedYAxisMax) {
        options.yaxis["max"] = this.fixedYAxisMax;
      }
    }
    if (this.stacked) {
      options["chart"]["stackType"] = this.stackedType;
      options.plotOptions.bar.dataLabels.position = "center";
    } else {
      options.plotOptions.bar.dataLabels.position = "top";
    }
    if (colors) {
      options["colors"] = colors;
    }
    if (!this.stacked && this.color) {
      options["colors"] = [this.color];
      options.dataLabels.style.colors = ["#000"];
    }
    if (this.fixedAxis) {
      options.xaxis.tickPlacement = "on";
    }
    return options as ApexOptions;
  }

  exportExcel() {
    this.loadingService.showLoading();
    this.mainCrudService
      .exportExcel(
        this.reportId,
        this.queryId,
        this.filterService.formatFilters(this.filterService.dashboardFilter),
        this.queryConfig
      )
      .subscribe(
        (response: any) => {
          let dataType = response.type;
          let binaryData = [];
          binaryData.push(response);
          let downloadLink = document.createElement("a");
          downloadLink.href = window.URL.createObjectURL(
            new Blob(binaryData, { type: dataType })
          );
          if (
            `${Utils.formatFileName(
              this.fileName,
              this.queryConfig
            )} - ${Utils.getActualDateAsString()}.xlsx`
          )
            downloadLink.setAttribute(
              "download",
              `${Utils.formatFileName(
                this.fileName,
                this.queryConfig
              )} - ${Utils.getActualDateAsString()}.xlsx` || "export.xlsx"
            );
          document.body.appendChild(downloadLink);
          downloadLink.click();
          this.loadingService.closeLoading(true);
        },
        (error) => {
          this.toastr.error(
            error.msg ||
              "Houve um erro ao exportar os dados, tente novamente.Caso o problema persista, avise nosso suporte."
          );
          this.loadingService.closeLoading(true);
        }
      );
  }
  exportPng(componentId: string) {
    this.loadingService.showLoading();
    let chart = document.getElementById(componentId);
    let downloadName = Utils.formatFileName(this.fileName, this.queryConfig);
    const out = this;
    if (!downloadName) {
      downloadName = "admin-page";
    }
    html2canvas(chart)
      .then((canvas) => {
        out.printCanvas = canvas;
        let imageData = out.printCanvas.toDataURL("image/png");
        var link = document.createElement("a");
        link.download =
          `${downloadName} - ${Utils.getActualDateAsString()}.png` ||
          "export.png";
        link.href = imageData;
        link.click();
        this.loadingService.closeLoading(true);
      })
      .catch((error) => {
        this.toastr.error(
          "Houve um erro ao gerar o PNG dos dados, tente novamente. Caso o problema persista, avise nosso suporte."
        );
      });
  }
}
