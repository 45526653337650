export class Credentials {
  token: string;
  refreshToken: string;
  type: string;

  constructor(token: string, refreshToken: string, type: string) {
    this.token = token;
    this.refreshToken = refreshToken;
    this.type = type;
  }

  getActualCredential(): string {
    return this.token;
  }
}
