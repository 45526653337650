import { guid, ID } from '@datorama/akita';

export type PageParamStateModel = {
  id: ID | null;
  param: string | null;
  value: string | null;
  initialized: boolean;
  label: string | null;
};

export function createPageParamStateModel(param: string, value: string, initialized: boolean, label: string) {
  return {
    id: guid(),
    param,
    value,
    initialized,
    label
  } as PageParamStateModel;
}