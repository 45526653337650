import { Component } from "@angular/core";
import { Router, NavigationStart } from "@angular/router";
import { RouterControllerService } from "./services/router-controller.service";
import { filter } from "rxjs/operators";
import { AuthService } from "@services/auth.service";
import { environment } from "src/environments/environment";
import Cookies from "js-cookie";

@Component({
  selector: "v360-root",
  templateUrl: "./app.component.html",
})
export class AppComponent {
  title = "v360-dashboard-frontend";
  constructor(
    private router: Router,
    private routerController: RouterControllerService,
    private authService: AuthService
  ) {
    this.router.events
      .pipe(filter((event) => event instanceof NavigationStart))
      .subscribe(({ url }: NavigationStart) => {
        if (url.includes("report")) {
          if (!this.authService.getCredentials()) {
            window.location.replace(`${environment.migrationUrl}/login`);
            Cookies.set(`reportRoute${environment.tokenSulfix}`, url, {
              domain: environment.migrationDomain,
              path: "/",
            });
          }
          this.routerController.routerHistory = [
            ...routerController.routerHistory,
            url,
          ];
        }
      });
  }
}
