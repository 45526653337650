import { EventEmitter, Injectable } from '@angular/core';
@Injectable({
  providedIn: 'root'
})
export class FilterButtonOverlayService {
    overlayController = new EventEmitter();
    loadingController = new EventEmitter();
    constructor() { }

    showPanel(){
      this.overlayController.emit('show');
    }

    hidePanel(){
      this.overlayController.emit('hide');
    }

    loading(loadingTime){
      this.loadingController.emit(loadingTime);
    }
}
