import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { User } from "../models/user";
import { HttpClient } from "@angular/common/http";
import { environment } from "src/environments/environment";
import { map } from "rxjs/operators";
import { ReportsService } from "./reports.service";
import Cookies from "js-cookie";

@Injectable({
  providedIn: "root",
})
export class UserService {
  private userInfos: User;

  constructor(
    private http: HttpClient,
    private reportService: ReportsService
  ) {}

  getUserInfo(): Observable<Object> {
    return this.http.get(`${environment.root}/me`).pipe(
      map((response: User) => {
        this.saveUserLocal(response);
        return response;
      })
    );
  }

  updateNotificationPreferences(enable: boolean) {
    return this.http
      .put(`${environment.root}/account/${this.userInfos.id}/user`, {
        notification: enable,
      })
      .pipe(
        map((response) => {
          this.userInfos.notification = enable;
          this.saveUserLocal(this.userInfos);
          return response;
        })
      );
  }

  userChangedPwd() {
    this.userInfos = this.getLocalUser();
    this.userInfos.reset = false;
    this.saveUserLocal(this.userInfos);
  }

  saveUserLocal(user: User) {
    this.userInfos = user;
    localStorage.setItem("user", JSON.stringify(user));
  }

  removeLocalUser() {
    localStorage.removeItem("user");
    this.userInfos = null;
    Cookies.remove(`userInfo${environment.tokenSulfix}`, {
      domain: environment.migrationDomain,
      path: "/",
    });
    this.reportService.removeLocalFolders();
  }

  getLocalUser(): User {
    if (this.userInfos) {
      return this.userInfos;
    }
    const userString = Cookies.get(`userInfo${environment.tokenSulfix}`);
    if (userString) {
      this.userInfos = JSON.parse(userString);
    } else {
      this.userInfos = JSON.parse(localStorage.getItem("user"));
    }
    return this.userInfos;
  }

  userIsAdmin(): boolean {
    if (!this.userInfos) {
      this.getLocalUser();
    }
    return this.userInfos.admin;
  }
}
