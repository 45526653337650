import { Injectable } from '@angular/core';
import { EntityState, EntityStore, StoreConfig, EntityUIStore } from '@datorama/akita';
import { Filter } from './filter.model';

export interface FilterState extends EntityState<Filter>{
  appliedFilters: Filter[]
}


export function createInitialState(): FilterState {
  return {
    appliedFilters: []
  };
}

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'filter'})
export class FilterStore extends EntityStore<FilterState, Filter> {

  constructor() {
    super(createInitialState());
  }
}