import { FormControl } from "@angular/forms";

export class Utils {
  static sortKeysDESC(obj_1) {
    var key = Object.keys(obj_1).sort((key1, key2) => {
      if (key1 < key2) return -1;
      else if (key1 > key2) return +1;
      else return 0;
    });

    // Taking the object in 'temp' object
    // and deleting the original object.
    var temp = {};

    for (var i = 0; i < key.length; i++) {
      temp[key[i]] = obj_1[key[i]];
      delete obj_1[key[i]];
    }

    // Copying the object from 'temp' to
    // 'original object'.
    for (var i = 0; i < key.length; i++) {
      obj_1[key[i]] = temp[key[i]];
    }
    return obj_1;
  }
  static isLetter(str) {
    return str.length === 1 && str.match(/[a-z]/i);
  }
  static copyString(original_string) {
    return (" " + original_string).slice(1);
  }
  static hasPrefix(str, prefix) {
    return str.charAt(0) == prefix;
  }
  static removeNotPrefix(str) {
    const hasNotPrefix = str.charAt(0) == "!";
    if (hasNotPrefix) {
      str = str.slice(1);
    }
    return { hasNotPrefix: hasNotPrefix, string: str };
  }
  static sortKeysASC(obj_1) {
    var key = Object.keys(obj_1).sort((key1, key2) => {
      if (key1 > key2) return -1;
      else if (key1 < key2) return +1;
      else return 0;
    });

    // Taking the object in 'temp' object
    // and deleting the original object.
    var temp = {};

    for (var i = 0; i < key.length; i++) {
      temp[key[i]] = obj_1[key[i]];
      delete obj_1[key[i]];
    }

    // Copying the object from 'temp' to
    // 'original object'.
    for (var i = 0; i < key.length; i++) {
      obj_1[key[i]] = temp[key[i]];
    }
    return obj_1;
  }
  static isJSON(str) {
    if (str[0] === "[") {
      return false;
    }
    try {
      const a = JSON.parse(str);
      return typeof a === "object" ? true : false;
    } catch (e) {
      return false;
    }
  }

  static capitalizeFirstLetterOfEveryWordOnly(text: string) {
    return text.toLocaleLowerCase().replace(/\b(\w)/g, (s) => s.toUpperCase());
  }

  static orderASC(arr: any[]): Array<any> {
    return arr.sort((a, b) => {
      if (a > b) {
        return 1;
      }
      if (a < b) {
        return -1;
      }
      // a must be equal to b
      return 0;
    });
  }
  static orderDESC(arr: any[]): Array<any> {
    return arr.sort((a, b) => {
      if (a < b) {
        return 1;
      }
      if (a > b) {
        return -1;
      }
      // a must be equal to b
      return 0;
    });
  }
  static orderArrayASCLocale(arr: any[], keyToOrder: string): any[] {
    return arr.sort((a, b) => {
      return a[keyToOrder].localeCompare(b[keyToOrder]);
    });
  }
  static orderArrayASC(arr: any[], keyToOrder: string): any[] {
    return arr.sort((a, b) => {
      if (a[keyToOrder] > b[keyToOrder]) {
        return 1;
      }
      if (a[keyToOrder] < b[keyToOrder]) {
        return -1;
      }
      // a must be equal to b
      return 0;
    });
  }
  static orderArrayDESC(arr: any[], keyToOrder: string): any[] {
    return arr.sort((a, b) => {
      if (a[keyToOrder] < b[keyToOrder]) {
        return 1;
      }
      if (a[keyToOrder] > b[keyToOrder]) {
        return -1;
      }
      // a must be equal to b
      return 0;
    });
  }

  static chartColors = [
    "#0000A6",
    "#003177",
    "#00447D",
    "#004547",
    "#0045D2",
    "#00489C",
    "#004B28",
    "#004D43",
    "#005844",
    "#005C8B",
    "#006039",
    "#006679",
    "#006A66",
    "#006C31",
    "#006FA6",
    "#00768B",
    "#007699",
    "#0080CF",
    "#00846F",
    "#0086ED",
    "#008941",
    "#0089A3",
    "#00905E",
    "#009087",
    "#0091BE",
    "#0098FF",
    "#009A2E",
    "#009B75",
    "#00A45F",
    "#00A6AA",
    "#00AB4D",
    "#00B433",
    "#00B57F",
    "#00B6C5",
    "#0100E2",
    "#012C58",
    "#013349",
    "#01400B",
    "#014833",
    "#014A68",
    "#018615",
    "#02227B",
    "#023C32",
    "#025117",
    "#02525F",
    "#02684E",
    "#029BDB",
    "#02D346",
    "#032641",
    "#033C61",
    "#03919A",
    "#03AD89",
    "#04784D",
    "#0568EC",
    "#067EAF",
    "#075C00",
    "#077D84",
    "#0AA6D8",
    "#0CBD66",
    "#0E72C5",
    "#15A08A",
    "#1A3A2A",
    "#1A7B42",
    "#1B2A25",
    "#1B4400",
    "#1B782A",
    "#1CA370",
    "#1D5958",
    "#1E2324",
    "#1E2B39",
    "#1E6E00",
    "#1F263C",
    "#1F2A1A",
    "#1F3F22",
    "#20204C",
    "#20370E",
    "#203B3C",
    "#208462",
    "#222800",
    "#224451",
    "#237363",
    "#250E35",
    "#252F99",
    "#274740",
    "#285366",
    "#286154",
    "#29201D",
    "#29607C",
    "#2A2000",
    "#2A364C",
    "#2A373F",
    "#2A7FFF",
    "#2C3533",
    "#2C5742",
    "#2C6135",
    "#2C656A",
    "#2C7F77",
    "#2E3C30",
    "#2E57AA",
    "#2EB500",
    "#2F2E2C",
    "#2F4A36",
    "#2F5D9B",
    "#303052",
    "#305230",
    "#314C1E",
    "#3156DC",
    "#316E47",
    "#320033",
    "#322C3E",
    "#322EDF",
    "#323925",
    "#323F61",
    "#324E72",
    "#325800",
    "#332C22",
    "#337D00",
    "#342142",
    "#34362D",
    "#34475A",
    "#353339",
    "#363DFF",
    "#36574E",
    "#365D25",
    "#370E77",
    "#373917",
    "#374527",
    "#378FDB",
    "#384644",
    "#392935",
    "#396D2B",
    "#3A2465",
    "#3A3F00",
    "#3A404A",
    "#3A7271",
    "#3A9459",
    "#3B5859",
    "#3B5DFF",
    "#3B9700",
    "#3C3E6E",
    "#3D4F44",
    "#3D7397",
    "#3DB5A7",
    "#3E3D52",
    "#3E4141",
    "#3E7158",
    "#3E89BE",
    "#3FA1B8",
    "#404E55",
    "#408543",
    "#4145A7",
    "#425218",
    "#427745",
    "#435740",
    "#435E6A",
    "#436E83",
    "#443A38",
    "#445083",
    "#449896",
    "#452C2C",
    "#453C23",
    "#456648",
    "#456D75",
    "#457D8B",
    "#4621B2",
    "#464836",
    "#4692AD",
    "#47675D",
    "#47688C",
    "#484449",
    "#489079",
    "#48B176",
    "#494B5A",
    "#497200",
    "#4979B0",
    "#4A3B53",
    "#4A5132",
    "#4AC684",
    "#4B3A83",
    "#4B4B6A",
    "#4B5C71",
    "#4B5D56",
    "#4B6BA5",
    "#4B8160",
    "#4C257F",
    "#4C325D",
    "#4C534F",
    "#4C6001",
    "#4C7B6D",
    "#4C83A1",
    "#4CA43B",
    "#4D5C5E",
    "#4D913E",
    "#4E4452",
    "#4E4E01",
    "#4E5F37",
    "#4F4A43",
    "#4FC601",
    "#513A01",
    "#518A87",
    "#51A058",
    "#52323C",
    "#525D82",
    "#528500",
    "#52CE79",
    "#536D31",
    "#536EFF",
    "#549E79",
    "#555196",
    "#555558",
    "#55813B",
    "#55C899",
    "#561A02",
    "#563930",
    "#564620",
    "#56735F",
    "#57344C",
    "#575329",
    "#576E4A",
    "#5771DA",
    "#577977",
    "#578F9B",
    "#584D39",
    "#5875C1",
    "#58AFAD",
    "#59738A",
    "#598C5A",
    "#5A0007",
    "#5A9BC2",
    "#5B113C",
    "#5B3213",
    "#5B4534",
    "#5B4E51",
    "#5B62C1",
    "#5B6965",
    "#5B9C8F",
    "#5C011A",
    "#5C424D",
    "#5C727B",
    "#5D3033",
    "#5D5462",
    "#5D5A48",
    "#5D697B",
    "#5E4665",
    "#5EAADD",
    "#5EB393",
    "#5EBCD1",
    "#5F4276",
    "#5F816D",
    "#602B70",
    "#604143",
    "#608EFF",
    "#614A44",
    "#61615A",
    "#617D9F",
    "#61AB1F",
    "#620B70",
    "#62ACB7",
    "#62E674",
    "#63243C",
    "#635000",
    "#636375",
    "#6367A9",
    "#636A01",
    "#637B5D",
    "#643127",
    "#64547B",
    "#646241",
    "#647095",
    "#6542D2",
    "#656E5B",
    "#658188",
    "#6635AF",
    "#664327",
    "#66796D",
    "#671190",
    "#674E60",
    "#6751BB",
    "#679FA0",
    "#67C4BB",
    "#682021",
    "#685D75",
    "#688151",
    "#696628",
    "#698EB1",
    "#6A004D",
    "#6A3A4C",
    "#6A5950",
    "#6A714A",
    "#6A8781",
    "#6A9573",
    "#6A9D3B",
    "#6AA776",
    "#6AD450",
    "#6B002C",
    "#6B3A64",
    "#6B543F",
    "#6B6466",
    "#6B7900",
    "#6B8295",
    "#6B94AA",
    "#6C3F3B",
    "#6C5E46",
    "#6C6D74",
    "#6C8F7D",
    "#6D3800",
    "#6D80BA",
    "#6DA6BD",
    "#6E2B52",
    "#6E4636",
    "#6E746E",
    "#6E96E3",
    "#6F0062",
    "#6F7586",
    "#6F9755",
    "#706690",
    "#707D3D",
    "#70968E",
    "#71555A",
    "#71695B",
    "#71B2F5",
    "#71BB8C",
    "#72361F",
    "#72418F",
    "#72A58C",
    "#73013E",
    "#736060",
    "#738671",
    "#73BE54",
    "#742A33",
    "#74569E",
    "#7499A1",
    "#74B671",
    "#754C78",
    "#7560D5",
    "#75797C",
    "#762988",
    "#766B7E",
    "#766E52",
    "#76912F",
    "#772600",
    "#776133",
    "#77733C",
    "#77837F",
    "#77D796",
    "#784149",
    "#785715",
    "#78726D",
    "#788D66",
    "#789EC9",
    "#78AFA1",
    "#78C8EB",
    "#7900D7",
    "#790229",
    "#794B61",
    "#797868",
    "#79B4D6",
    "#79DB21",
    "#7A001D",
    "#7A4900",
    "#7A5C54",
    "#7A617E",
    "#7A7BFF",
    "#7A8EBA",
    "#7AC5A6",
    "#7B4F4B",
    "#7C2B73",
    "#7C6571",
    "#7C6755",
    "#7C8060",
    "#7CB9BA",
    "#7D5A44",
    "#7D74A9",
    "#7D9F00",
    "#7DBF32",
    "#7E6405",
    "#7E7311",
    "#7E7C93",
    "#7E9285",
    "#7EA4C1",
    "#7ED379",
    "#7F8427",
    "#7F8C94",
    "#7F9EFF",
    "#7FA670",
    "#802647",
    "#806C66",
    "#807781",
    "#809693",
    "#813D3A",
    "#81817B",
    "#8181D5",
    "#830055",
    "#834357",
    "#836BBA",
    "#837393",
    "#83A485",
    "#83AB58",
    "#845D70",
    "#848590",
    "#8498AE",
    "#8502AA",
    "#8502FF",
    "#852C19",
    "#856465",
    "#857A50",
    "#858182",
    "#859253",
    "#85ABB4",
    "#863B60",
    "#866097",
    "#867175",
    "#8683A9",
    "#868E7E",
    "#86B487",
    "#874AA6",
    "#885138",
    "#885578",
    "#887868",
    "#890039",
    "#89006A",
    "#893DE3",
    "#89412E",
    "#895563",
    "#897334",
    "#897B77",
    "#8A5E2D",
    "#8A9F45",
    "#8B4A4E",
    "#8BB400",
    "#8BC891",
    "#8C4787",
    "#8C675E",
    "#8C9FA8",
    "#8D5700",
    "#8D6F81",
    "#8D8546",
    "#8D918E",
    "#8D9C83",
    "#8DA4DB",
    "#8F5DF8",
    "#8F7F00",
    "#8FB0FF",
    "#8FCCBE",
    "#90956F",
    "#90B0AB",
    "#90C1D3",
    "#91028C",
    "#9131AF",
    "#915C4E",
    "#917100",
    "#922329",
    "#92678B",
    "#927D5E",
    "#92896B",
    "#92BEA5",
    "#943A4D",
    "#944575",
    "#946444",
    "#94888B",
    "#94A5A1",
    "#94A9C9",
    "#953F00",
    "#9556BD",
    "#958A9F",
    "#962B75",
    "#96531F",
    "#96656D",
    "#966716",
    "#9676A3",
    "#967874",
    "#969381",
    "#9695C5",
    "#96C57F",
    "#976F5C",
    "#976FD9",
    "#97703C",
    "#979440",
    "#97979E",
    "#97A094",
    "#980034",
    "#982E0B",
    "#985975",
    "#987C95",
    "#988376",
    "#989EB7",
    "#98AA7F",
    "#99375B",
    "#997D87",
    "#99ADC0",
    "#9B585E",
    "#9B8ACC",
    "#9B9700",
    "#9B9EE2",
    "#9BB195",
    "#9BBB57",
    "#9C2F90",
    "#9C6966",
    "#9C8333",
    "#9CB8E4",
    "#9CCC04",
    "#9D4948",
    "#9D8C76",
    "#9E0366",
    "#9E5585",
    "#9F4F67",
    "#9F94F0",
    "#9FA064",
    "#9FB2A4",
    "#A05837",
    "#A079BF",
    "#A168A6",
    "#A1C299",
    "#A1C2B6",
    "#A243A7",
    "#A24E3D",
    "#A28A9A",
    "#A2AA45",
    "#A2C3DF",
    "#A30059",
    "#A37F46",
    "#A3A489",
    "#A3C8C9",
    "#A449DC",
    "#A45B02",
    "#A50149",
    "#A55B54",
    "#A587AF",
    "#A60019",
    "#A68486",
    "#A697B6",
    "#A74571",
    "#A77500",
    "#A79A74",
    "#A8441D",
    "#A88C85",
    "#A8ABB4",
    "#A97399",
    "#A9795C",
    "#AA5199",
    "#AA62C3",
    "#AA6746",
    "#AA9A92",
    "#AABC7A",
    "#AB9180",
    "#ABAED1",
    "#ABE86B",
    "#AC84DD",
    "#AD6552",
    "#AD7C90",
    "#ADAAA9",
    "#ADAAFF",
    "#AE3B85",
    "#AE81FF",
    "#AE9C86",
    "#AF5D88",
    "#AFCE78",
    "#B0415D",
    "#B05B6F",
    "#B28D2D",
    "#B2B081",
    "#B2C2FE",
    "#B3008B",
    "#B4A04F",
    "#B4A200",
    "#B506D3",
    "#B56481",
    "#B5666B",
    "#B57BB3",
    "#B5885C",
    "#B599D7",
    "#B5B268",
    "#B6602B",
    "#B67180",
    "#B70546",
    "#B77B68",
    "#B787A8",
    "#B79762",
    "#B8552F",
    "#B88183",
    "#B8CB9C",
    "#B90076",
    "#B903AA",
    "#B94C59",
    "#BA0900",
    "#BA6200",
    "#BB1F69",
    "#BB3C42",
    "#BC65E9",
    "#BCB1E5",
    "#BD7322",
    "#BD9479",
    "#BE0028",
    "#BE4700",
    "#BE811A",
    "#BE9D91",
    "#BEC459",
    "#BF5650",
    "#C064B9",
    "#C0BAC0",
    "#C1B394",
    "#C23000",
    "#C2A580",
    "#C36D96",
    "#C44170",
    "#C4A8CB",
    "#C535A9",
    "#C59700",
    "#C5AAB6",
    "#C6005A",
    "#C64289",
    "#C6D300",
    "#C6DC99",
    "#C7706A",
    "#C7847B",
    "#C7C890",
    "#C86240",
    "#C895C5",
    "#C8A1A1",
    "#C9403A",
    "#CA834E",
    "#CB7E98",
    "#CBA1BC",
    "#CC0744",
    "#CCA763",
    "#CCAA35",
    "#CD00C5",
    "#CD7DAE",
    "#CE934C",
    "#CF6353",
    "#D09685",
    "#D0AC94",
    "#D1511C",
    "#D157A0",
    "#D16100",
    "#D1ACFE",
    "#D20096",
    "#D21656",
    "#D24CB2",
    "#D25B88",
    "#D27D67",
    "#D369B4",
    "#D45262",
    "#D499E3",
    "#D60034",
    "#D68E01",
    "#D790FF",
    "#D7BFC2",
    "#D7C54A",
    "#D83D66",
    "#D8939E",
    "#D97C8E",
    "#DA0004",
    "#DA007C",
    "#DA713C",
    "#DA71FF",
    "#DA8386",
    "#DB9D72",
    "#DCDE5C",
    "#DD3248",
    "#DD587B",
    "#DD9CB8",
    "#DDB588",
    "#DDB6D0",
    "#DDBC62",
    "#E05859",
    "#E08DA7",
    "#E20027",
    "#E269AA",
    "#E27172",
    "#E27A05",
    "#E2BC00",
    "#E30091",
    "#E383E6",
    "#E3A68B",
    "#E451D1",
    "#E45F35",
    "#E500F1",
    "#E55199",
    "#E58E56",
    "#E5A532",
    "#E66D53",
    "#E69034",
    "#E70452",
    "#E704C4",
    "#E773CE",
    "#E78FCF",
    "#E7A0CC",
    "#E83000",
    "#E87EAC",
    "#E98176",
    "#EA0072",
    "#EA1CA9",
    "#EA8B66",
    "#EB9A8B",
    "#EC5200",
    "#ED3488",
    "#F35691",
    "#F3BDA4",
    "#F4ABAA",
    "#F56D93",
    "#F77183",
    "#FC009C",
    "#FD0039",
    "#FF0020",
    "#FF2F80",
    "#FF3BC1",
    "#FF4526",
    "#FF4A46",
    "#FF4F78",
    "#FF5DA7",
    "#FF5F6B",
    "#FF6832",
    "#FF6C60",
    "#FF6EC2",
    "#FF8347",
    "#FF8A9A",
    "#FF8EB1",
    "#FF9079",
    "#FF913F",
    "#FF9E6B",
    "#FFA861",
    "#FFB500",
  ];

  static hashFnv32a(str, seed): number {
    /*jshint bitwise:false */
    var i,
      l,
      hval = seed === undefined ? 0x811c9dc5 : seed;

    for (i = 0, l = str.length; i < l; i++) {
      hval ^= str.charCodeAt(i);
      hval +=
        (hval << 1) + (hval << 4) + (hval << 7) + (hval << 8) + (hval << 24);
    }
    return hval >>> 0;
  }

  static getColor(str: string) {
    if (str.toLowerCase() === "outros" || str.toLowerCase() === "outras") {
      return "#97c2c5";
    }
    const hash = this.hashFnv32a(str, "varejo360");
    const position = hash % this.chartColors.length;
    return this.chartColors[position];
  }

  static getRandomColor() {
    return this.chartColors[
      Math.floor(Math.random() * this.chartColors.length)
    ];
  }

  static getActualDateAsString(): string {
    let date = new Date();
    return date
      .toLocaleString("pt-BR")
      .replace(new RegExp("/", "g"), "-")
      .replace(new RegExp(":", "g"), "-")
      .replace(" ", "_");
  }

  static formatChartNumbers(
    value,
    currency: boolean,
    percentage: boolean,
    precision = 0,
    abbreviation = false
  ): string {
    if (!value && value !== 0) {
      return;
    }
    let valueFormatted;
    if (percentage) {
      valueFormatted = this.formatPercentage(value, precision);
    } else if (currency) {
      valueFormatted = this.formatCurrency(value, abbreviation);
    } else {
      valueFormatted = this.formatNumber(value, abbreviation);
    }
    return valueFormatted;
  }

  static formatPercentage(val, precision): string {
    val = val / 100;
    let options = {
      style: "percent",
      maximumFractionDigits: precision,
      minimumFractionDigits: precision,
    };
    let currencyFormatter = new Intl.NumberFormat("pt-BR", options);
    return currencyFormatter.format(val);
  }

  static formatCurrency(val: number, abbreviation): string {
    const options = abbreviation
      ? {
          style: "currency",
          currency: "BRL",
          notation: "compact",
          compactDisplay: "short",
          maximumFractionDigits: 2,
          minimumFractionDigits: 2,
        }
      : {
          style: "currency",
          currency: "BRL",
          maximumFractionDigits: 2,
          minimumFractionDigits: 2,
        };
    let currencyFormatter = new Intl.NumberFormat("pt-BR", options);
    return currencyFormatter.format(val);
  }
  static formatNumber(val: number, abbreviation): string {
    const options = abbreviation
      ? {
          style: "decimal",
          notation: "compact",
          compactDisplay: "short",
        }
      : undefined;
    let currencyFormatter = new Intl.NumberFormat("pt-BR", options);
    return currencyFormatter.format(val);
  }

  static formatPercentilPoint(val: number, showPlusSign: boolean): string {
    const options = {
      style: "decimal",
      maximumFractionDigits: 2,
      minimumFractionDigits: 2,
    };
    let currencyFormatter = new Intl.NumberFormat("pt-BR", options);
    return `${showPlusSign && val >= 0 ? "+" : ""}${currencyFormatter.format(
      val
    )} pp`;
  }

  static noWhitespaceValidator(control: FormControl) {
    const isWhitespace = (control.value || "").trim().length === 0;
    const isValid = !isWhitespace;
    return isValid ? null : { whitespace: true };
  }

  static formatFileName(fileName: string, jsonConfig: object): string {
    if (!jsonConfig) return fileName;
    var regexDod = new RegExp("\\.", "g");
    var regexSpace = new RegExp("\\ ", "g");
    Object.keys(jsonConfig).forEach((key) => {
      fileName = fileName.replace(`<${key}>`, jsonConfig[key]);
    });
    return fileName.replace(regexDod, "_").replace(regexSpace, "-");
  }

  static getUrlParamFromString(urlString: string, paramName: string) {
    const value = urlString
      .split("&")
      .filter((item) => item.includes(paramName))[0];
    if (value) {
      return decodeURI(value.split("=")[1]);
    }
    return "";
  }
}
