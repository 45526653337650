import { HttpClientModule } from '@angular/common/http';
import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatListModule } from '@angular/material/list';
import { MatDividerModule } from '@angular/material/divider';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ComponentsModule } from './components/components.module';
import { NotFoundComponent } from './pages/not-found/not-found.component';
import { ToastrModule } from 'ngx-toastr';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatChipsModule } from '@angular/material/chips';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatTabsModule } from '@angular/material/tabs';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatDialogModule } from '@angular/material/dialog';
import { MatSelectModule } from '@angular/material/select';
import { MatMenuModule } from '@angular/material/menu';
import { httpInterceptProviders } from '@services/interceptors';
import { AuthService } from '@services/auth.service';
import { LoadingService } from '@services/loading.service';

import { MaintenanceComponent } from './pages/maintenance/maintenance.component';
import { MatCardModule } from '@angular/material/card';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { AgGridModule } from 'ag-grid-angular';

import { MonacoEditorModule } from '@materia-ui/ngx-monaco-editor';
import { ChangeReportService } from '@services/change-report.service';
import { AkitaNgDevtools } from '@datorama/akita-ngdevtools';
import { environment } from '../environments/environment';

import { DynamicHooksModule, HookParserEntry } from 'ngx-dynamic-hooks';
import { ParamSelectorComponent } from './components/param-selector/param-selector.component';
import { ReportComponent } from './pages/reports/report/report.component';

const componentParsers: Array<HookParserEntry> = [
  {component: ParamSelectorComponent},
  // ...
];


@NgModule({
  declarations: [
    AppComponent,
    NotFoundComponent,
    MaintenanceComponent
  ],
  imports: [
    AppRoutingModule,
    BrowserModule,
    BrowserAnimationsModule,
    ReactiveFormsModule,
    ComponentsModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    MatSidenavModule,
    MatDividerModule,
    MatIconModule,
    MatButtonModule,
    MatListModule,
    MatChipsModule,
    MatTabsModule,
    MatTooltipModule,
    MatDatepickerModule,
    MatDialogModule,
    MatSelectModule,
    MatMenuModule,
    MatCardModule,
    ToastrModule.forRoot({
      closeButton: true,
      tapToDismiss: true
    }),
    FontAwesomeModule,
    AgGridModule.forRoot(),
    MonacoEditorModule,
    environment.production ? [] : AkitaNgDevtools.forRoot(),
    DynamicHooksModule.forRoot({
      globalParsers: componentParsers
    })
  ],
  providers: [
    ChangeReportService,
    AuthService,
    LoadingService,
    httpInterceptProviders,
    ReportComponent
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
