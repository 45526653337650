import { ComponentFactoryResolver, Injectable, Sanitizer, SecurityContext, ViewContainerRef } from '@angular/core';
import { BarChartComponent } from '../client-component/bar-chart/bar-chart.component';
import { LegendaComponent } from '../client-component/legenda/legenda.component';
import { LineChartComponent } from '../client-component/line-chart/line-chart.component';
import { NewTableComponent } from '../client-component/new-table/new-table.component';
import { PieChartComponent } from '../client-component/pie-chart/pie-chart.component';
import { TableComponent } from '../client-component/table/table.component';
import { DashboardComponent } from '@models/dashboard-component';
import _ from 'lodash';

@Injectable({
  providedIn: 'root'
})
export class DashboardConstructorService {

  constructor(
    private componentFactoryResolver: ComponentFactoryResolver,
    private sanitizer: Sanitizer
  ) { }

  checkComponent(component: DashboardComponent, body: ViewContainerRef, reportId: number, filters?, reportName?: string, queryConfig?: object, fileName?: string) {
    switch(component.type) {
      case 'grafico-pizza':
        const pieChartFactory = this.componentFactoryResolver.resolveComponentFactory(PieChartComponent);
        const customPieChart = body.createComponent<PieChartComponent>(pieChartFactory);
        customPieChart.instance.fileName = fileName;
        customPieChart.instance.title = component.title;
        customPieChart.instance.style = component.style;
        customPieChart.instance.queryId = component.query_id;
        customPieChart.instance.queryConfig = component.query ? _.merge(_.cloneDeep(queryConfig), component.query.config) : queryConfig ;
        customPieChart.instance.pivot = component.pivot;
        customPieChart.instance.datasetName = component.datasetName;
        customPieChart.instance.dimension = component.dimension;
        component.minAngleToShowLabel || component.minAngleToShowLabel === 0
          ? customPieChart.instance.minAngleToShowLabel = component.minAngleToShowLabel
          : '';
        component.pivotTextSize 
          ? customPieChart.instance.pivotTextSize = component.pivotTextSize
          : '';
        component.labelTextSize
          ? customPieChart.instance.labelTextSize = component.labelTextSize
          : '';
        component.currencyFormat !== undefined 
          ? customPieChart.instance.currencyFormat = component.currencyFormat
          : '';
        customPieChart.instance.reportId = reportId;
        customPieChart.instance.filters = filters;
        reportName ? customPieChart.instance.reportName = reportName : '';
        component.legendTextSize ? customPieChart.instance.legendTextSize = component.legendTextSize : '';
        component.colorDimension !== undefined ? customPieChart.instance.colorDimension = component.colorDimension : '';
        component.showTooltipSample !== undefined ? customPieChart.instance.showTooltipSample = component.showTooltipSample : '';
        break;
      case 'tabela':
        const tableFactory = this.componentFactoryResolver.resolveComponentFactory(TableComponent);
        const customTable = body.createComponent<TableComponent>(tableFactory);
        customTable.instance.fileName = fileName;
        customTable.instance.queryId = component.query_id;
        customTable.instance.reportId = reportId;
        customTable.instance.lines = component.lines;
        customTable.instance.columns = component.columns;
        customTable.instance.values = component.values;
        customTable.instance.groupBy = component.groupBy;
        customTable.instance.queryConfig = component.query ? _.merge(_.cloneDeep(queryConfig), component.query.config) : queryConfig;
        customTable.instance.columnsToPercentage = component.columnsToPercentage;
        customTable.instance.filters = filters;
        component.title 
          ? customTable.instance.title = component.title
          : '';
        component.style
          ? customTable.instance.style = component.style
          : '';
        component.columnsToCurrency
          ? customTable.instance.columnsToCurrency = component.columnsToCurrency
          : '';
        component.columnsToPercentagePoint
          ? customTable.instance.columnsToPercentagePoint = component.columnsToPercentagePoint
          : '';
        reportName ? customTable.instance.reportName = reportName : '';
        component.cellPadding ? customTable.instance.cellPadding = component.cellPadding : '';
        component.tableScroll ? customTable.instance.tableScroll = component.tableScroll : '';
        break;
      case 'nova-tabela':
        const newTableFactory = this.componentFactoryResolver.resolveComponentFactory(NewTableComponent);
        const customNewTable = body.createComponent<NewTableComponent>(newTableFactory);
        component.chartHeight ? customNewTable.instance.height = component.chartHeight : '';
        component.chartWidth ? customNewTable.instance.width = component.chartWidth : '';
        component.gridOptions ? customNewTable.instance.gridOptions = component.gridOptions : '';
        component.title ? customNewTable.instance.title = component.title : '';
        customNewTable.instance.fileName = fileName;
        customNewTable.instance.queryId = component.query_id || component.query.id;
        customNewTable.instance.reportId = reportId;
        customNewTable.instance.filters = filters;
        customNewTable.instance.queryConfig = component.query ? _.merge(_.cloneDeep(queryConfig), component.query.config) : queryConfig;
        customNewTable.instance.userColumns = component.columns;
        reportName ? customNewTable.instance.reportName = reportName : '';
        component.pivotMode !== undefined ? customNewTable.instance.pivotMode = component.pivotMode : '';
        component.headerClass !== undefined ? customNewTable.instance.headerClass = component.headerClass : '';
        component.style ? customNewTable.instance.tableStyle = component.style : '';
        component.titleStyle ? customNewTable.instance.titleStyle = component.titleStyle : '';
        component.showScroll !== undefined ? customNewTable.instance.showScroll = component.showScroll : '';
        component.hideScroll !== undefined ? customNewTable.instance.hideScroll = component.hideScroll : '';
        component.wrapHeader !== undefined ? customNewTable.instance.wrapHeader = component.wrapHeader : '';
        component.hasTotalFooter !== undefined ? customNewTable.instance.hasTotalFooter = component.hasTotalFooter : '';
        break;
      case 'grafico-linha':
        const lineChartFactory = this.componentFactoryResolver.resolveComponentFactory(LineChartComponent);
        const customLineChart = body.createComponent<LineChartComponent>(lineChartFactory);
        customLineChart.instance.fileName = fileName;
        customLineChart.instance.queryId = component.query_id || component.query.id;
        customLineChart.instance.reportId = reportId;
        customLineChart.instance.datasetName = component.datasetName;
        customLineChart.instance.dimension = component.dimension;
        customLineChart.instance.xAxisName = component.xAxisName;
        customLineChart.instance.filters = filters;
        customLineChart.instance.percentageFormat = component.percentageFormat;
        customLineChart.instance.currencyFormat = component.currencyFormat;
        component.enableLegendClick !== undefined ? customLineChart.instance.enableLegendClick = component.enableLegendClick : '';
        reportName ? customLineChart.instance.reportName = reportName : '';
        customLineChart.instance.chartHeight = component.chartHeight;
        component.showDataLabels !== undefined ? customLineChart.instance.showDataLabels = component.showDataLabels : '';
        component.legendTextSize ? customLineChart.instance.legendTextSize = component.legendTextSize : '';
        customLineChart.instance.title = component.title;
        component.showLegend !== undefined ? customLineChart.instance.showLegend = component.showLegend : '';
        component.showXAxis !== undefined ? customLineChart.instance.showXAxis = component.showXAxis : '';
        customLineChart.instance.queryConfig = component.query ? _.merge(_.cloneDeep(queryConfig), component.query.config) : queryConfig;
        component.yAxisText ? customLineChart.instance.yAxisText = component.yAxisText : '';
        component.xAxisText ? customLineChart.instance.xAxisText = component.xAxisText : '';
        component.legendBeforeText ? customLineChart.instance.legendBeforeText = component.legendBeforeText : '';
        component.dotSize !== undefined ? customLineChart.instance.dotSize = component.dotSize : '';
        component.colorDimension !== undefined ? customLineChart.instance.colorDimension = component.colorDimension : '';
        component.precision ? customLineChart.instance.precision = component.precision : '';
        component.abbreviation ? customLineChart.instance.abbreviation = component.abbreviation : '';
        component.gridPadding !== undefined ? customLineChart.instance.gridPadding = component.gridPadding : '';
        component.chartStyle !== undefined ? customLineChart.instance.chartStyle = component.chartStyle : '';
        customLineChart.instance.fixedYAxisMin = component.fixedYAxisMin ?? 0;
        customLineChart.instance.fixedYAxisMax = component.fixedYAxisMax ?? null;
        customLineChart.instance.forceNiceScale = component.forceNiceScale ?? true;
        break;
      case 'grafico-barra':
        const barChartFactory = this.componentFactoryResolver.resolveComponentFactory(BarChartComponent);
        const customBarChart = body.createComponent<BarChartComponent>(barChartFactory);
        customBarChart.instance.fileName = fileName;
        customBarChart.instance.queryId = component.query_id || component.query.id;
        customBarChart.instance.datasetName = component.datasetName;
        customBarChart.instance.dimension = component.dimension;
        customBarChart.instance.xAxisName = component.xAxisName;
        customBarChart.instance.percentageFormat = component.percentageFormat;
        customBarChart.instance.currencyFormat = component.currencyFormat;
        customBarChart.instance.queryConfig = component.query ? _.merge(_.cloneDeep(queryConfig), component.query.config) : queryConfig ;
        component.stacked !== undefined ? customBarChart.instance.stacked = component.stacked : '';
        component.stackedType ? customBarChart.instance.stackedType = component.stackedType : '';
        customBarChart.instance.chartHeight = component.chartHeight;
        customBarChart.instance.tooltipTextSize = component.tooltipText;
        customBarChart.instance.title = component.title;
        customBarChart.instance.labelTextSize = component.labelTextSize;
        component.enableLegendClick !== undefined ? customBarChart.instance.enableLegendClick = component.enableLegendClick : '';
        component.labelName ? customBarChart.instance.labelName = component.labelName : '';
        component.showDataLabel !== undefined ? customBarChart.instance.showDataLabel = component.showDataLabel : '';
        component.showLegend !== undefined ? customBarChart.instance.showLegend = component.showLegend : '';
        component.color ? customBarChart.instance.color = component.color : '';
        component.showXAxis !== undefined ? customBarChart.instance.showXAxis = component.showXAxis : '';
        component.showYAxis !== undefined ? customBarChart.instance.showYAxis = component.showYAxis : '';
        component.showFullTooltip !== undefined ? customBarChart.instance.fullTooltip = component.showFullTooltip : '';
        component.dataLabelOffset ? customBarChart.instance.dataLabelOffset = component.dataLabelOffset : '';
        component.xDataLabelOffset ? customBarChart.instance.xDataLabelOffset = component.xDataLabelOffset : '';
        component.showGrid !== undefined ? customBarChart.instance.showGrid = component.showGrid : '';
        component.fixedAxis !== undefined ? customBarChart.instance.fixedAxis = component.fixedAxis : '';
        component.horizontal !== undefined ? customBarChart.instance.horizontal = component.horizontal : '';
        component.legendPosition ? customBarChart.instance.legendPosition = component.legendPosition : '';
        customBarChart.instance.reportId = reportId;
        customBarChart.instance.reportName = reportName;
        customBarChart.instance.filters = filters;
        component.yAxisText ? customBarChart.instance.yAxisText = component.yAxisText : '';
        component.xAxisText ? customBarChart.instance.xAxisText = component.xAxisText : '';
        component.colorDimension !== undefined ? customBarChart.instance.colorDimension = component.colorDimension : '';
        component.precision ? customBarChart.instance.precision = component.precision : '';
        component.abbreviation ? customBarChart.instance.abbreviation = component.abbreviation : '';
        component.chartStyle !== undefined ? customBarChart.instance.chartStyle = component.chartStyle : '';
        customBarChart.instance.tooltipFormat = component.tooltipFormat !== undefined ? component.tooltipFormat : '';
        customBarChart.instance.shortTooltipValue = component.shortTooltipValue !== undefined ? component.shortTooltipValue : false;
        customBarChart.instance.tooltipPrecision = component.tooltipPrecision !== undefined ? component.tooltipPrecision : 0;
        customBarChart.instance.fixedYAxisMin = component.fixedYAxisMin ?? 0;
        customBarChart.instance.fixedYAxisMax = component.fixedYAxisMax ?? null;
        customBarChart.instance.forceNiceScale = component.forceNiceScale ?? true;
        break;
      case 'legenda': 
        const legendaFactory = this.componentFactoryResolver.resolveComponentFactory(LegendaComponent);
        const customLegenda = body.createComponent<LegendaComponent>(legendaFactory);
        customLegenda.instance.queryId = component.query_id || component.query.id;
        customLegenda.instance.reportId = reportId;
        customLegenda.instance.queryConfig = component.query ? _.merge(_.cloneDeep(queryConfig), component.query.config) : queryConfig ;
        customLegenda.instance.filters = filters;
        component.style ? customLegenda.instance.style = component.style : '';
        component.text ? customLegenda.instance.componentText = component.text : '';
    }
  }
}
