import { Injectable } from "@angular/core";
import { HttpClient, HttpErrorResponse } from "@angular/common/http";
import { environment } from "src/environments/environment";
import { Report } from "@models/report";
import { map } from "rxjs/operators";
import { ReportPage } from "@models/report-page";
import { PagesDefaultFilter } from "@models/pages-default-filters";
import { ReportFolder } from "@models/report-folder";
import { FormControl } from "@angular/forms";
import { PageIndicator } from "@models/page-indicator";
import { Utils } from "../utils";
import { ReportContent } from "@models/report-content-list";
import { FilterService } from "./filter.service";
import { Observable } from "rxjs";
import Cookies from "js-cookie";

@Injectable({
  providedIn: "root",
})
export class ReportsService {
  selectedReport: any;

  constructor(private http: HttpClient, private filterService: FilterService) {}

  selectedReportId: number;
  reportFolders: ReportFolder[];
  selectedPage: ReportPage;
  indicators = new FormControl();

  getReportListByFilter(filter: string): Observable<Report[]> {
    return this.http
      .get<Report[]>(`${environment.root}/reports/search?filter=${filter}`)
      .pipe(
        map((response: Report[]) => {
          return Utils.orderArrayASCLocale(response, "title");
        })
      );
  }

  createReport(report: Report) {
    return this.http.post(`${environment.root}/reports`, report);
  }

  updateReport(report: Report) {
    return this.http.put(`${environment.root}/reports`, report);
  }

  deleteReport(reportId: number) {
    return this.http.delete(`${environment.root}/reports/${reportId}`);
  }

  getReportPages(
    reportId: number,
    current_page_id?: number,
    hash_filter?: string,
    hash_page_params?: string
  ) {
    let params = { params: {} };
    if (current_page_id) params.params["current_page_id"] = current_page_id;
    if (hash_filter) params.params["hash_filter"] = hash_filter;
    if (hash_page_params) params.params["hash_page_params"] = hash_page_params;

    return this.http
      .get(`${environment.root}/reports/${reportId}/pages`, params)
      .pipe(
        map((response: PagesDefaultFilter) => {
          response.pages = Utils.orderArrayASCLocale(
            response.pages,
            "folder"
          ).map((folder) => {
            folder.content = Utils.orderArrayASCLocale(
              folder.content,
              "title"
            ).map((page) => {
              page.title = `${folder.folder.split(".")[0]}.${page.title}`;
              return page;
            });
            return folder;
          });
          this.filterService.favoriteFilters.patchValue(
            response.favorite_filters
          );
          this.saveLocalReportFolders(response.pages);
          return response;
        })
      );
  }

  getReportsList() {
    return this.http.get(`${environment.root}/reports`).pipe(
      map((response: ReportContent) => {
        response.reports = Utils.orderArrayASCLocale(
          response.reports,
          "title"
        ) as Report[];
        localStorage.setItem("reports", JSON.stringify(response));
        return response;
      })
    );
  }

  getReportsListGroupedByCategory() {
    return this.http
      .get(`${environment.root}/reports/grouped-by-category`)
      .pipe(
        map((response: ReportContent) => {
          let reportsThatRepresentsTheCategories = [];
          Object.keys(response.reports).forEach((category) => {
            reportsThatRepresentsTheCategories.push(
              response.reports[category][0]
            );
          });
          response.reports = Utils.orderArrayASCLocale(
            reportsThatRepresentsTheCategories,
            "title"
          ) as Report[];
          localStorage.setItem("reports", JSON.stringify(response));
          return response;
        })
      );
  }

  getReportsListGroupedBySector() {
    return this.http.get(`${environment.root}/reports/grouped-by-sector`).pipe(
      map((response: ReportContent) => {
        Object.keys(response.reports).forEach((sector) => {
          let reportsThatRepresentsTheCategories = [];
          Object.keys(response.reports[sector]).forEach((category) => {
            reportsThatRepresentsTheCategories.push(
              response.reports[sector][category][
                Object.keys(response.reports[sector][category])
              ]
            );
          });
          response.reports[sector] = Utils.orderArrayASCLocale(
            reportsThatRepresentsTheCategories,
            "title"
          ) as Report[];
        });
        localStorage.setItem("reports", JSON.stringify(response));
        return response;
      })
    );
  }

  async getSelectedReport(reportId: number): Promise<Report> {
    this.selectedReport = await this.getReportById(reportId).toPromise();
    return this.selectedReport;
  }

  getReportById(reportId: number) {
    return this.http.get(`${environment.root}/reports/${reportId}`);
  }

  getUpdateTime() {
    return this.http.get(`${environment.root}/reports/updated_date`);
  }

  loadDashboard(reportId: number, pageId: number) {
    return this.http
      .get(`${environment.root}/reports/${reportId}/pages/${pageId}`)
      .pipe(
        map((response) => {
          if (response["config"] && response["config"]["mandatory_filter"]) {
            response["config"]["filtroObrigatorio"] =
              response["config"]["mandatory_filter"];
          }
          return response;
        })
      );
  }

  saveLocalReportFolders(reports: ReportFolder[]) {
    this.reportFolders = reports;
  }

  getLocalReportFolders(): ReportFolder[] {
    return this.reportFolders;
  }

  removeLocalFolders() {
    this.reportFolders = null;
  }

  getPageIndicators(reportId: number, filters: { filters; config? }) {
    return this.http
      .post(`${environment.root}/reports/${reportId}/indicators`, filters)
      .pipe(
        map((response: PageIndicator[]) => {
          response = Utils.sortKeysASC(response) as PageIndicator[];
          if (response[0]?.ano) {
            response = Utils.orderArrayDESC(response, "ano");
          }
          this.indicators.patchValue(response);
          return response;
        })
      );
  }

  sendPageParamsToCreateHash(reportId: number, pageId: number, pageParams) {
    var page_params = { ...pageParams };
    if (page_params["labels"]) {
      delete page_params["labels"];
    }
    return this.http
      .post(
        `${environment.root}/reports/${reportId}/pages/${pageId}/create-hash`,
        { page_params }
      )
      .pipe(
        map((response: { hash: string }) => {
          return response.hash;
        })
      );
  }

  getLocalPageById(reports: ReportFolder[], id: string): ReportPage {
    if (reports) {
      let report;
      reports.forEach((item) => {
        item.content.forEach((page) => {
          if (page.id == id) {
            report = page;
          }
        });
      });
      return report;
    }
  }
  getListOfSectors(): Observable<string[]> {
    return this.http.get<string[]>(`${environment.root}/reports/sectors`).pipe(
      map((response: string[]) => {
        return response.sort();
      })
    );
  }

  getNavbarReportMarkers(reportId: number): Observable<any> {
    return this.http.get<any>(
      `${environment.root}/reports/${reportId}/selector-markers`
    );
  }
}
