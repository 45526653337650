import { guid, ID } from '@datorama/akita';

export type Filter = {
  id: ID | null;
  category: string | null;
  filterName: string | null;
  icon: string | null;
  placeholder: string | null;
  name: string | number | null;
  isMandatory: boolean | null;
  unique: boolean;
  isExceto: boolean | null;
};

export function createFilter(category: string, filterName: string, icon: string, placeholder: string, name: string | number, isMandatory: boolean, unique: boolean, isExceto: boolean) {
  return {
    id: guid(),
    category,
    filterName,
    icon,
    placeholder,
    name,
    isMandatory,
    unique, 
    isExceto
  } as Filter;
}