import { Component, OnInit } from "@angular/core";
import { ApexOptions } from "ng-apexcharts";
import { ToastrService } from "ngx-toastr";
import { delay } from "rxjs/operators";
import { MainCrudService } from "src/app/services/main-crud.service";
import { Utils } from "src/app/utils";
import html2canvas from "html2canvas";
import { FilterService } from "src/app/services/filter.service";
import { LoadingService } from "src/app/services/loading.service";
import { ReportsService } from "src/app/services/reports.service";
import * as _ from "lodash";

export interface ChartDataset {
  group: string;
  data: any;
  chartOptions?: ApexOptions;
}

@Component({
  selector: "v360-pie-chart",
  templateUrl: "./pie-chart.component.html",
  styleUrls: ["./pie-chart.component.scss"],
})
export class PieChartComponent implements OnInit {
  // obrigatórios
  queryId: number;
  pivot = "";
  datasetName = "";
  dimension = "";

  title = "";
  style = "";
  chartStyle = "";
  reportName = "";
  legendTextSize = 14;
  labelTextSize = "16px";
  pivotTextSize = "16px";
  minAngleToShowLabel = 15;
  labels = [];
  deactivatedLabels = [];
  dataset: ChartDataset[];
  reportId: number;
  filters;
  dataSetError = false;
  dimensionData: any[];
  printCanvas;
  downloadOptions = [
    {
      label: "Excel",
      icon: "pi pi-file-excel",
      command: () => this.exportExcel(),
    },
    { label: "PNG", icon: "pi pi-file", command: () => this.exportPng() },
  ];
  showExportBtn;
  currencyFormat = false;
  colorDimension = "";
  showTooltipSample = false;
  queryConfig;
  fileName = "";
  selectedReport;

  constructor(
    private mainCrudService: MainCrudService,
    private toastr: ToastrService,
    private filterService: FilterService,
    private loadingService: LoadingService,
    private reportService: ReportsService
  ) {}

  async ngOnInit() {
    this.pivot = this.formatValue(this.pivot, this.queryConfig);
    if (!this.selectedReport) {
      this.selectedReport = await this.reportService.getSelectedReport(
        this.reportId
      );
    }
    this.getDataset(
      this.queryId,
      this.pivot,
      this.reportId,
      this.filters,
      this.queryConfig
    );
    if (!this.dimension && !this.colorDimension) {
      const paramValues = Object.values(this.queryConfig).map((v: string) => {
        return v.toLowerCase();
      });
      const report = await this.reportService.getSelectedReport(this.reportId);
      for (const param of paramValues) {
        if (_.has(report.colors, param)) {
          this.dimension = param;
          this.colorDimension = param;
        }
      }
    }
  }

  formatValue(value: string, queryConfig): string {
    // Verifica se o valor é um parametro
    if (value.includes("<") && value.includes(">")) {
      // Extrai o parametro
      const param = value.replace("<", "").replace(">", "");
      return queryConfig[param];
    }
    return value;
  }

  reloadDataset() {
    this.dataset = null;
    this.dataSetError = false;
    this.getDataset(
      this.queryId,
      this.pivot,
      this.reportId,
      this.filters,
      this.queryConfig
    );
  }

  deactivatedLabel(label: string) {
    if (this.deactivatedLabels.includes(label)) {
      this.deactivatedLabels = this.deactivatedLabels.filter(
        (val) => val !== label
      );
    } else {
      this.deactivatedLabels.push(label);
    }
    this.labels = this.labels.map((val) => {
      if (this.deactivatedLabels.includes(val.value)) {
        val.activated = false;
      } else {
        val.activated = true;
      }
      return val;
    });
    this.updateChart();
  }

  updateChart() {
    this.dataset = this.dataset.map((val) => {
      val.chartOptions = this.createChartOptions(
        val.data,
        this.datasetName,
        val.group,
        this.dataset.length
      );
      return val;
    });
  }

  getDataset(
    queryId: number,
    pivot: string,
    reportId: number,
    filters,
    queryConfig?
  ) {
    this.mainCrudService
      .getDataset(queryId, reportId, pivot, filters, queryConfig)
      .pipe(delay(0))
      .subscribe(
        (response: ChartDataset[]) => {
          this.dataSetError = false;
          this.dataset = response.map((val) => {
            val.chartOptions = this.createChartOptions(
              val.data,
              this.datasetName,
              val.group,
              response.length
            );
            return val;
          });
          response.map((dataset, index) => {
            dataset.data.map((data) => {
              const dimension = this.getDimension(data);
              const val = this.labels.filter((label) => {
                return label.value === dimension;
              });
              if (
                val.length === 0 &&
                dimension !== "OUTRAS" &&
                dimension !== "OUTROS"
              ) {
                this.labels.push({ value: dimension, activated: true });
              } else if (
                (dimension === "OUTRAS" || dimension === "OUTROS") &&
                index + 1 === response.length
              ) {
                this.labels.push({ value: dimension, activated: true });
              }
            });
          });
        },
        (error) => {
          this.toastr.error(
            error.error.msg || "Houve um erro ao carregar as informações."
          );
          this.dataSetError = true;
        }
      );
  }

  getDimension(val) {
    return val[this.dimension]
      ? val[this.dimension]
      : val[
          Object.values(this.queryConfig).filter((queryConfigValue) => {
            return val[queryConfigValue as string] != null;
          })[0] as string
        ];
  }

  getColorByString(string: string): string {
    const val = this.dimensionData.filter((val) => val.dimension === string);
    return `${val[0]?.color}`;
  }

  formatTitle(title: string, data: any): string {
    const key = title.split("${")[1].split("}")[0];
    return `${title.split(" ${")[0]} ${data[0][key]}`;
  }

  checkIfValueIsDisabled(valueToCheck: string): boolean {
    return this.deactivatedLabels.includes(valueToCheck);
  }

  getDimensionColor(dimensionValue: string) {
    if (
      this.colorDimension &&
      this.queryConfig &&
      Object.keys(this.queryConfig).includes(
        this.colorDimension.replace("<", "").replace(">", "")
      )
    ) {
      const dimension =
        this.queryConfig[this.colorDimension.replace("<", "").replace(">", "")];
      this.colorDimension = dimension;
    }
    let color;
    if (
      this.selectedReport?.colors &&
      this.colorDimension &&
      this.selectedReport.colors.hasOwnProperty(this.colorDimension)
    ) {
      const colors = this.selectedReport.colors[`${this.colorDimension}`];
      color = colors[`${dimensionValue.toUpperCase()}`]
        ? colors[`${dimensionValue.toUpperCase()}`]
        : Utils.getColor(dimensionValue);
    } else {
      color = Utils.getColor(dimensionValue);
    }
    const dimensionColorVal = this.dimensionData.filter((val) => {
      return val.dimension === dimensionValue;
    });
    if (dimensionColorVal.length === 0) {
      this.dimensionData.push({ dimension: dimensionValue, color });
    }
    return color;
  }

  exportPng() {
    this.loadingService.showLoading();
    let chart = document.getElementById("pivot-pie-chart");
    const out = this;
    html2canvas(chart)
      .then((canvas) => {
        out.printCanvas = canvas;
        let imageData = out.printCanvas.toDataURL("image/png");
        var link = document.createElement("a");
        link.download =
          `${Utils.formatFileName(
            this.fileName,
            this.queryConfig
          )} - ${Utils.getActualDateAsString()}.png` || "export.png";
        link.href = imageData;
        link.click();
        this.loadingService.closeLoading(true);
      })
      .catch((error) => {
        this.toastr.error(
          "Houve um erro ao gerar o PNG dos dados, tente novamente. Caso o problema persista, avise nosso suporte."
        );
      });
  }

  exportExcel() {
    this.loadingService.showLoading();
    this.mainCrudService
      .exportExcel(
        this.reportId,
        this.queryId,
        this.filterService.formatFilters(this.filterService.dashboardFilter),
        this.queryConfig
      )
      .subscribe(
        (response: any) => {
          let dataType = response.type;
          let binaryData = [];
          binaryData.push(response);
          let downloadLink = document.createElement("a");
          downloadLink.href = window.URL.createObjectURL(
            new Blob(binaryData, { type: dataType })
          );
          if (
            `${Utils.formatFileName(
              this.fileName,
              this.queryConfig
            )} - ${Utils.getActualDateAsString()}.xlsx`
          )
            downloadLink.setAttribute(
              "download",
              `${Utils.formatFileName(
                this.fileName,
                this.queryConfig
              )} - ${Utils.getActualDateAsString()}.xlsx` || "export.xlsx"
            );
          document.body.appendChild(downloadLink);
          downloadLink.click();
          this.loadingService.closeLoading(true);
        },
        (error) => {
          this.toastr.error(
            error.msg ||
              "Houve um erro ao exportar os dados, tente novamente.Caso o problema persista, avise nosso suporte."
          );
          this.loadingService.closeLoading(true);
        }
      );
  }

  createChartOptions(
    data: any,
    keySelector: string,
    group: string,
    datasetLength: number
  ): ApexOptions {
    const mainThis = this;
    const filteredData = data.filter(
      (val) => !this.checkIfValueIsDisabled(this.getDimension(val))
    );
    if (!this.dimensionData) {
      this.dimensionData = [];
    }
    const options = {
      series: filteredData.map((val) => parseFloat(val[keySelector])),
      labels: filteredData.map((val) => this.getDimension(val)),
      colors: filteredData.map((val) =>
        this.getDimensionColor(this.getDimension(val))
      ),
      chart: {
        type: "donut",
        sparkline: {
          enabled: true,
        },
        animations: {
          enabled: false,
        },
        width: "100%",
        parentHeightOffset: 0,
      },
      title: {
        text: this.title ? this.formatTitle(this.title, data) : "",
      },
      legend: {
        show: false,
      },
      dataLabels: {
        enabled: true,
        formatter: (val) => {
          return `${Math.round(val)}%`;
        },
        distributed: true,
        style: {
          fontSize: this.labelTextSize,
          fontFamily: "Montserrat, sans-serif",
          fontWeight: "bold",
          colors: ["#ffffff"],
        },
      },
      plotOptions: {
        pie: {
          offsetX: 0,
          offsetY: 0,
          startAngle: 0,
          expandOnClick: false,
          customScale: 1,
          dataLabels: {
            minAngleToShowLabel: this.minAngleToShowLabel,
          },
          donut: {
            size: "45%",
            labels: {
              show: true,
              name: {
                show: false,
                color: "#000000",
                formatter: (val) => {
                  return this.pivot;
                },
              },
              value: {
                show: true,
                color: "#000000",
                fontWeight: "bold",
                fontSize: this.pivotTextSize,
                formatter: (val) => {
                  return group;
                },
              },
              total: {
                show: true,
                color: "#000000",
                label: group,
                formatter: (val) => {
                  return group;
                },
              },
            },
          },
        },
      },
      tooltip: {
        custom: function ({ series, seriesIndex, dataPointIndex, w }) {
          let valueToShow;
          if (mainThis.currencyFormat) {
            let currencyFormatter = new Intl.NumberFormat("pt-BR", {
              style: "currency",
              currency: "BRL",
            });
            valueToShow = currencyFormatter.format(
              series[seriesIndex].toFixed(2)
            );
          } else {
            valueToShow = new Intl.NumberFormat("pt-BR").format(
              series[seriesIndex]
            );
          }
          const total = series.reduce((a, b) => a + b);
          return (
            `<div class="arrow_box">` +
            `<div><strong>${group}</strong></div>` +
            `<span>${w.globals.labels[seriesIndex]}: </span>` +
            `<span><strong>${Math.round(
              (series[seriesIndex] / total) * 100
            )}% ${
              mainThis.showTooltipSample ? `- ${valueToShow}` : ""
            }</strong></span>` +
            `</div>`
          );
        },
      },
      states: {
        normal: {
          filter: {
            type: "none",
            value: 0,
          },
        },
        active: {
          allowMultipleDataPointsSelection: false,
          filter: {
            type: "none",
            value: 0,
          },
        },
      },
    } as ApexOptions;
    if (datasetLength === 1) {
      this.chartStyle = "max-width: 500px; margin: 0 auto;";
    } else if (datasetLength === 2) {
      this.chartStyle = "max-width: 1000px; margin: 0 auto;";
    }
    return options;
  }
}
