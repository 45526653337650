import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';

import {LicenseManager} from "@ag-grid-enterprise/core";
import { enableAkitaProdMode } from '@datorama/akita';
LicenseManager.setLicenseKey("CompanyName=BENCHMKT INTELIGENCIA E PESQUISAS DE MERCADO LTDA,LicensedApplication=Plataforma Varejo 360,LicenseType=SingleApplication,LicensedConcurrentDeveloperCount=1,LicensedProductionInstancesCount=1,AssetReference=AG-021674,ExpiryDate=18_December_2022_[v2]_MTY3MTMyMTYwMDAwMA==0ac60460f0519e795d7b6e2d1e0e4ce8");

if (environment.production) {
  enableProdMode();
  enableAkitaProdMode();
}

platformBrowserDynamic().bootstrapModule(AppModule)
  .catch(err => console.error(err));
