import { Injectable } from '@angular/core';
import { QueryEntity } from '@datorama/akita';
import { PageParamStore, PageParamState } from './page-param.store';

@Injectable({ providedIn: 'root' })
export class PageParamQuery extends QueryEntity<PageParamState> {
  param: string;
  getWasParamInserted$ = this.selectAll({
    filterBy: page => page.param == this.param
  })
  getAll$ = this.selectAll()
  getPageParamUIState$ = this.select(state => state.ui.wasParamInserted)

  constructor(protected store: PageParamStore) {
    super(store);
  }
  setParam(param: string){
    this.param = param;
  }
}