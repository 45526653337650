import { Component, OnInit } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { delay } from 'rxjs/operators';
import { FilterService } from 'src/app/services/filter.service';
import { LoadingService } from 'src/app/services/loading.service';
import { MainCrudService } from 'src/app/services/main-crud.service';
import { ReportsService } from 'src/app/services/reports.service';

@Component({
  selector: 'v360-legenda',
  templateUrl: './legenda.component.html'
})
export class LegendaComponent implements OnInit {

  componentId;
  dataSetError;
  queryId;
  reportId;
  filters;
  queryConfig;

  style: string;
  componentText: string;
  showText;

  constructor(
    private mainCrudService: MainCrudService,
    private toastr: ToastrService,
    private filterService: FilterService,
    private loadingService: LoadingService,
    private reportService: ReportsService,
  ) { }

  ngOnInit(): void {
    this.componentId = `line-chart-${1 + Math.floor((100000 - 1) * Math.random())}`;
    this.getData(this.queryId, this.reportId, this.filters, this.queryConfig);
  }

  getData(queryId, reportId, filters, queryConfig) {
    this.mainCrudService.getDataset(queryId, reportId, null, filters, queryConfig)
    .pipe(delay(0))
    .subscribe((response: any[]) => {
      let keys = Object.keys(response[0]);
      this.showText = this.convertText(this.componentText, keys, response);
    }, error => {
      this.toastr.error(error.error.msg || 'Houve um erro ao carregar as informações.');
      this.dataSetError = true;
    });
  }

  convertText(texToConvert: string, keys: string[], valueToExtract): string {
    let text = this.componentText;
    keys.forEach(key => {
      text = text.replace(key, valueToExtract[0][key]);
    });
    return text
  }

  reloadDataset() {
    this.dataSetError = false;
    this.showText = null;
    this.getData(this.queryId, this.reportId, this.filters, this.queryConfig);
  }

}
