import { Injectable } from '@angular/core';
@Injectable({
  providedIn: 'root'
})
export class FilterInstancesService {
    filterInstancesArray: any[] = [];
    constructor() { }

    push(newInstance){
      this.filterInstancesArray.push(newInstance);
    }

    clearInstances(){
      this.filterInstancesArray = [];
    }

    get(){
        return this.filterInstancesArray;
    }
}
