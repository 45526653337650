import { Component, EventEmitter, OnInit, Output } from '@angular/core';

@Component({
  selector: 'v360-error',
  templateUrl: './error.component.html',
  styleUrls: ['./error.component.scss']
})
export class ErrorComponent implements OnInit {

  @Output('reload') reloadEvent = new EventEmitter();
  constructor() { }

  ngOnInit() {
  }

  reload() {
    this.reloadEvent.emit();
  }

}
