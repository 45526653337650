import { environment } from "./../../environments/environment";
import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { UserService } from "./user.service";
import { Credentials } from "@models/credentials";
import { map, tap } from "rxjs/operators";
import Cookies from "js-cookie";

@Injectable({
  providedIn: "root",
})
export class AuthService {
  constructor(private http: HttpClient, private userService: UserService) {}

  logIn(email: string, password: string) {
    return this.http
      .post(`${environment.root}/auth/token`, { email, password })
      .pipe(
        map((response: Credentials) => {
          environment.production
            ? window["__insp"]?.push(["identify", email])
            : null;
          this.saveCredentials(response);
          return response;
        })
      );
  }

  refreshToken() {
    return this.http
      .post(`${environment.root}/auth/refreshtoken`, {
        refresh_token: this.getCredentials()?.refreshToken,
      })
      .pipe(
        tap((response: Credentials) => {
          this.saveCredentials(response);
        })
      );
  }

  logout(): void {
    this.removeCredentials();
    this.userService.removeLocalUser();
  }

  changePwd(password: string) {
    return this.http
      .post(`${environment.root}/account/updatePwd`, { password })
      .pipe(
        map((response) => {
          this.userService.userChangedPwd();
          return response;
        })
      );
  }

  resetPwd(email: string) {
    return this.http.post(`${environment.root}/resetPwd`, { email });
  }

  isLogged(): boolean {
    if (this.getCredentials()?.token) {
      return true;
    }
    return false;
  }

  getCredentials(): Credentials {
    const token = Cookies.get(`token${environment.tokenSulfix}`);
    if (!token) {
      return JSON.parse(
        localStorage.getItem(`credentials${environment.tokenSulfix}`)
      );
    }
    const refreshToken = Cookies.get(`refreshToken${environment.tokenSulfix}`);
    return new Credentials(token, refreshToken, "Bearer");
  }

  removeCredentials() {
    const userInfos = this.userService.getLocalUser();
    localStorage.clear();
    this.userService.removeLocalUser();
    if (userInfos) {
      Cookies.remove(`token${environment.tokenSulfix}`, {
        domain: environment.migrationDomain,
        path: "/",
      });
      Cookies.remove(`refreshToken${environment.tokenSulfix}`, {
        domain: environment.migrationDomain,
        path: "/",
      });
      Cookies.remove(`userInfo${environment.tokenSulfix}`, {
        domain: environment.migrationDomain,
        path: "/",
      });
      if (userInfos.admin) {
        document.location.replace(environment.migrationUrl);
      }
    }
  }

  saveCredentials(credentials: Credentials) {
    localStorage.setItem("credentials", JSON.stringify(credentials));
    Cookies.set(`token${environment.tokenSulfix}`, credentials.token, {
      domain: environment.migrationDomain,
      path: "/",
    });
    Cookies.set(
      `refreshToken${environment.tokenSulfix}`,
      credentials.refreshToken,
      {
        domain: environment.migrationDomain,
        path: "/",
      }
    );
    Cookies.set(`type${environment.tokenSulfix}`, credentials.type, {
      domain: environment.migrationDomain,
      path: "/",
    });
  }
}
