import { NotFoundComponent } from './pages/not-found/not-found.component';
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuard } from './guards/auth-guard.guard';
import { MaintenanceComponent } from './pages/maintenance/maintenance.component';
import { MaintenanceGuard } from './guards/maintenance.guard';
import { AdminGuard } from './guards/admin.guard';

const routes: Routes = [
  {
    path: 'login',
    loadChildren: () => import('./pages/login/login.module').then(m => m.LoginModule),
    canLoad: [MaintenanceGuard]
  },
  {
    path: 'reports',
    loadChildren: () => import('./pages/reports/reports.module').then(m => m.ReportsModule),
    canLoad: [MaintenanceGuard, AuthGuard],
    canActivate: [AuthGuard]
  },
  {
    path: 'maintenance',
    component: MaintenanceComponent
  },
  {
    path: 'admin',
    loadChildren: () => import('./pages/admin-home/admin-home.module').then(m => m.AdminHomeModule),
    canActivate: [AdminGuard]
  },
  {
    path: '',
    redirectTo: '/reports',
    pathMatch: 'full'
  },
  {
    path: '**',
    component: NotFoundComponent
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
