import { Component, OnInit } from '@angular/core';
import { environment } from 'src/environments/environment';
import { Router } from '@angular/router';

@Component({
  selector: 'v360-maintenance',
  templateUrl: './maintenance.component.html',
  styleUrls: ['./maintenance.component.scss']
})
export class MaintenanceComponent implements OnInit {

  constructor(private router: Router) {
    if (environment.name !== 'maintenance') {
      router.navigateByUrl('/');
    }
  }

  ngOnInit(): void {
  }

}
