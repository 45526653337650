import { Injectable } from '@angular/core';
import { CanLoad, Route, Router, CanActivate } from '@angular/router';
import { environment } from 'src/environments/environment';
import { AuthService } from '@services/auth.service';
import { UserService } from '@services/user.service';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanLoad, CanActivate {
  constructor(
    private router: Router,
    private authService: AuthService,
    private userService: UserService,
  ) {}

  canActivate(route, state) {
    if (this.userService.getLocalUser()?.reset) {
      this.router.navigate(['/login/change-password']);
      return false;
    }
    return true;
  }

  canLoad(route: Route) {
    if (!this.authService.isLogged()) {
      environment.production ? window['__insp']?.push(['identify', this.userService.getLocalUser()?.email]) : null;
      this.router.navigate(['/login']);
      this.authService.logout();
      return false;
    }
    return true;
  }
}
