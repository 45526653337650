import { AgGridEvent, AllModules, Column, Module } from '@ag-grid-enterprise/all-modules';
import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import html2canvas from 'html2canvas';
import { ToastrService } from 'ngx-toastr';
import { FilterService } from 'src/app/services/filter.service';
import { LoadingService } from 'src/app/services/loading.service';
import { MainCrudService } from 'src/app/services/main-crud.service';
import { Utils } from 'src/app/utils';
import gridLocale from '../../../assets/grid-locale_BR.json';

function MedalCellRenderer() {}
MedalCellRenderer.prototype.init = function(params) {
  this.eGui = document.createElement('span');
  var text = '';

  if (!params.value && params.value !== 0) {
    text = '-'
  } else {
    if (params.valueFormatted) {
      text = params.valueFormatted;
    } else {
      text = params.value;
    }
  }
  if(params.colDef.showRowGroup === "rowIndex"){
    text = params.node.rowIndex;
  }
  this.eGui.innerHTML = text;
};
MedalCellRenderer.prototype.getGui = function () {
  return this.eGui;
};

function CustomGroupCellRenderer() {}
CustomGroupCellRenderer.prototype.init = function(params) {
  if(params.colDef.showRowGroup === "rowIndex"){
    this.groupGui = document.createElement('div');
    this.groupGui.classList.add('hide-text');
    this.groupGui.innerHTML = `<span>${params.node.rowIndex + 1}</span>`;
  } else {
    this.groupGui = document.createElement('div');
    this.groupGui.classList.add('hide-text');
    params.value !== undefined ? this.groupGui.innerHTML = `<span>${params.value}</span>`:this.groupGui.innerHTML = `<span>Total</span>`;
  }
}
CustomGroupCellRenderer.prototype.getGui = function() {
  return this.groupGui;
}
@Component({
  selector: 'v360-new-table',
  templateUrl: './new-table.component.html',
  styleUrls: ['./new-table.component.scss']
})
export class NewTableComponent implements OnInit {

  title;
  height = '400px';
  width = '100%';

  showExportBtn = false;
  downloadOptions = [
    {label: 'Excel', icon: 'pi pi-file-excel', command: () => this.exportExcel()},
    {label: 'PNG', icon: 'pi pi-file', command: () => this.exportPng(this.componentId)}
  ];

  queryId;
  reportId;
  filters;

  pivotMode = false;
  userColumns;
  columns;
  rowData;
  gridOptions;
  headerClass;
  localeText = gridLocale;
  processSecondaryColGroupDef;
  processSecondaryColDef;
  componentId;
  @ViewChild('grid') grid: any;
  printCanvas;
  reportName;
  dataSetError = false;
  noBorder = false;
  popupParent;
  eGui;
  groupGui;
  tableStyle = 'margin-bottom: 15px;';
  gridStyle = 'width: 100%; height: 100%;';
  titleStyle = 'text-align: center; margin: 0; padding: 15px 0;';
  public modules: Module[] = AllModules;
  showScroll = false;
  hideScroll = false;
  wrapHeader = false;
  hasTotalFooter = false;
  columnTypes;
  queryConfig;
  fileName = '';

  constructor(
    private mainCrudService: MainCrudService,
    private loadingService: LoadingService,
    private toastr: ToastrService,
    private filterService: FilterService
  ) {
    this.popupParent = document.querySelector('body');    
    this.columnTypes = {
      numberColumn: {
        cellStyle: {"textAlign": "right"}
      }
    }
  }


  ngOnInit(): void {
    this.rowData = null;
    this.componentId = `new-table-${1 + Math.floor((100000 - 1) * Math.random())}`;
    this.setDefaultStyles();
    this.gridOptions.onCellDoubleClicked = () => {};
    this.gridOptions.components = {
      'customCellRender': MedalCellRenderer
    }
    if (this.gridOptions.autoGroupColumnDef) {
      this.gridOptions.autoGroupColumnDef.cellRenderer = CustomGroupCellRenderer
      this.gridOptions.autoGroupColumnDef.tooltipValueGetter = function (params) {
        return params.value;
      };
    }
    this.gridOptions.onFirstDataRendered = (params) => {
      if (this.pivotMode) {
        this.resize();
      }
      this.colorizeHeader();
      if (this.showScroll) document.querySelector(`#${this.componentId} div.ag-body-viewport`).classList.add('scroll-visible');
      if (this.hideScroll) document.querySelector(`#${this.componentId} div.ag-center-cols-viewport`).classList.add('hide-scroll');
      if (this.wrapHeader) {
        document.querySelectorAll(`#${this.componentId} span.ag-header-cell-text`).forEach(el => el.classList.add('wrap-text'));
      }
    };
    this.columns = this.userColumns.map(column => this.columnFormatter(column));
    this.processSecondaryColDef = (colDef) => {
      colDef["headerClass"] = this.gridOptions.defaultColDef?.headerClass ? this.gridOptions.defaultColDef?.headerClass : "";
    };
    this.processSecondaryColGroupDef = (colGroupDef) => {
      colGroupDef["headerClass"] = this.gridOptions.defaultColDef?.headerClass ? this.gridOptions.defaultColDef?.headerClass : "";
    };
    this.getDataSet();
    this.columns = this.editaColunasComParametro(this.columns, this.queryConfig);
    this.gridStyle = `width: 100%; height: calc(100% - ${document.getElementById(this.componentId + '-title')?.offsetHeight || 0}px);`;
  }

  resize() {
    var allColumnIds = [];
    var columnsWithFlex = [];
    this.gridOptions.columnApi.getAllColumns().forEach(function (column: Column) {
      if (column.getFlex()) {
        columnsWithFlex.push(column.getColId());
      }
    });
    this.gridOptions.columnApi.getAllGridColumns().forEach(function (column: Column) {
      if (column.getColId().includes('ag-Grid-AutoColumn')) {
        allColumnIds.push(column.getColId());
      }
    });
    this.gridOptions.columnApi.autoSizeColumns(allColumnIds, true);
    if (columnsWithFlex.length > 0) {
      this.gridOptions.columnApi.getAllGridColumns().forEach(function (column: Column) {
        columnsWithFlex.forEach(flexColumn => {
          if (column.getColId().includes(flexColumn)) {
            column.setFlex(columnsWithFlex.length);
          }
        });
      });
    }
  }

  private extrairParametros(value: string): string[] {
    const regexp = /<[\w]+>/g;
    
    return [...value.match(regexp)].map(val => val.replace('<', '').replace('>', ''));
  }

  private editaColunasComParametro(colunas, queryConfig) {
    if(!queryConfig) {
      return colunas;
    }
    var colunasFormatadas = colunas.map(coluna => {
      var colunaFormatada = {...coluna};
      if(colunaFormatada.headerName?.includes('<')) {
        var parametros = this.extrairParametros(colunaFormatada.headerName);
        parametros.forEach(parametro => {
          colunaFormatada.headerName = colunaFormatada.headerName.replace(`<${parametro}>`, this.queryConfig['labels'][this.queryConfig[parametro]]);
        })
      }
      if(colunaFormatada.field?.includes('<')) {
        var parametro = colunaFormatada.field.replace('<', '').replace('>', '');
        colunaFormatada.field = this.queryConfig[parametro];
      }
      if(colunaFormatada.headerTooltip?.includes('<')) {
        var parametros = this.extrairParametros(colunaFormatada.headerTooltip);
        parametros.forEach(parametro => {
          colunaFormatada.headerTooltip = colunaFormatada.headerTooltip.replace(`<${parametro}>`, this.queryConfig[parametro]);
        })
      }
      return colunaFormatada;
    });

    return colunasFormatadas;
  }

  private setDefaultStyles() {
    if (!this.gridOptions.rowStyle) this.gridOptions.rowStyle = {};
    if (!this.gridOptions.defaultColDef) this.gridOptions.defaultColDef = {};
    if (!this.gridOptions.defaultColDef.cellStyle) this.gridOptions.defaultColDef.cellStyle = {};
    if (!this.gridOptions.rowStyle['font-size']) this.gridOptions.rowStyle['font-size'] = "14px";
    if (!this.gridOptions.defaultColDef.cellStyle["line-height"]) this.gridOptions.defaultColDef.cellStyle["line-height"] = "28px";
    if (!this.gridOptions.defaultColDef.cellStyle["padding"]) this.gridOptions.defaultColDef.cellStyle["padding"] = "0 1px";
    if (!this.gridOptions.rowHeight) this.gridOptions.rowHeight = 28;
    if (!this.gridOptions.headerHeight) this.gridOptions.headerHeight = 28;
    this.gridOptions.defaultColDef.suppressMenu = true;
    if (this.gridOptions?.rowHeight) {
      this.gridOptions.getRowHeight = (param) => {
        return this.gridOptions.rowHeight;
      }
    }
  }

  private columnFormatter(column) {
    const newColumn = {...column};
    newColumn.headerTooltip = newColumn.headerName;
    if (column.formatCurrency || column.formatType === '$') {
      newColumn.valueFormatter = (param) => Utils.formatChartNumbers(param.value, column.formatCurrency, false, column.precision, column.abbreviation);
      delete newColumn.formatCurrency;
      newColumn.type = "numberColumn";
      newColumn.tooltipValueGetter = function (params) {
        return Utils.formatChartNumbers(params.value, column.formatCurrency, false, column.precision, column.abbreviation);
      };
    } else if (column.formatPercentage || column.formatType === '%') {
      newColumn.valueFormatter = (param) => Utils.formatChartNumbers(param.value, false, column.formatPercentage, column.precision, column.abbreviation);
      delete newColumn.formatPercentage;
      newColumn.type = "numberColumn";
      newColumn.tooltipValueGetter = function (params) {
        return Utils.formatChartNumbers(params.value, false, column.formatPercentage, column.precision, column.abbreviation);
      };
    } else if(column.formatType === 'pp') {
      newColumn.valueFormatter = (param) => {
        return Utils.formatPercentilPoint(param.value, column.showPlusSign);
      }
      newColumn.type = "numberColumn";
      delete newColumn.formatNumber;
      newColumn.tooltipValueGetter = function (params) {
        return Utils.formatPercentilPoint(params.value, column.showPlusSign);
      };
    } else if (column.formatNumber) {
      newColumn.valueFormatter = (param) => {
        return Utils.formatChartNumbers(param.value, false, false, column.precision, column.abbreviation);
      }
      newColumn.type = "numberColumn";
      delete newColumn.formatNumber;
      newColumn.tooltipValueGetter = function (params) {
        return Utils.formatChartNumbers(params.value, false, false, column.precision, column.abbreviation);
      };
    } else {
      newColumn.tooltipValueGetter = function (params) {
        return params.value;
      };
    }
    if (!column.rowGroup) {
      newColumn.cellRenderer = 'customCellRender';
    }
    if (column.pivot) {
      newColumn.pivotComparator = function(a, b) {
        return column.order && column.order == 'asc' ? (a > b ? 1 : -1)
        : (a > b ? -1 : 1);
      }
    }
    newColumn.comparator = this.customComparator;
    if(this.hasTotalFooter && !column.rowGroup && !column.pivot && !column.colId){
      newColumn.aggFunc = 'sum';
    }
    return newColumn
  }

  onSortChanged(e: AgGridEvent) {
    e.api.refreshCells();
  }

  private customComparator(a, b, nodeA, nodeB, isInverted) {
    if (typeof a !== 'string') {
      if (a == b) {
          return 0;
      }
      return (a > b) ? 1 : -1;
    }
    return a.localeCompare(b, undefined, { sensitivity: 'accent' });
  }

  private colorizeHeader() {
    const emptyCell = document.querySelectorAll(`#${this.componentId} div.ag-header-group-cell-no-group`);
    emptyCell.forEach(el => {
      const separator = el.querySelector('.ag-header-cell-resize');
      separator.remove();
      el.classList.add(this.gridOptions.defaultColDef?.headerClass ? this.gridOptions.defaultColDef?.headerClass : "");
    })
  }

  getDataSet() {
    this.mainCrudService.getDataset(this.queryId, this.reportId, null, this.filters, this.queryConfig)
    .subscribe((response: any[]) => {
      this.dataSetError = false;
      this.rowData = response;
    }, error => {
      this.dataSetError = true;
    });
  }

  reloadDataset() {
    this.getDataSet();
  }

  exportExcel() {
    this.loadingService.showLoading();
    this.mainCrudService.exportExcel(this.reportId, this.queryId, this.filterService.formatFilters(this.filterService.dashboardFilter), this.queryConfig)
    .subscribe((response: any) => {
      let dataType = response.type;
      let binaryData = [];
      binaryData.push(response);
      let downloadLink = document.createElement('a');
      downloadLink.href = window.URL.createObjectURL(new Blob(binaryData, {type: dataType}));
      if (`${Utils.formatFileName(this.fileName, this.queryConfig)} - ${Utils.getActualDateAsString()}.xlsx`)
          downloadLink.setAttribute('download', `${Utils.formatFileName(this.fileName, this.queryConfig)} - ${Utils.getActualDateAsString()}.xlsx` || 'export.xlsx');
      document.body.appendChild(downloadLink);
      downloadLink.click();
      this.loadingService.closeLoading(true);
    }, error => {
      this.toastr.error(error.msg || 'Houve um erro ao exportar os dados, tente novamente.Caso o problema persista, avise nosso suporte.');
      this.loadingService.closeLoading(true);
    });
  }
  exportPng(componentId: string) {
    this.loadingService.showLoading();
    let chart = document.getElementById(componentId);
    let downloadName = Utils.formatFileName(this.fileName, this.queryConfig);
    const out = this;
    if (!downloadName) {
      downloadName = 'admin-page';
    }
    html2canvas(chart)
      .then((canvas) => {
        out.printCanvas = canvas;
        let imageData = out.printCanvas.toDataURL("image/png");
        var link = document.createElement('a');
        link.download = `${downloadName} - ${Utils.getActualDateAsString()}.png` || 'export.png';
        link.href = imageData
        link.click();
        this.loadingService.closeLoading(true);
      }).catch(error => {
        this.toastr.error('Houve um erro ao gerar o PNG dos dados, tente novamente. Caso o problema persista, avise nosso suporte.')
      })
  }
}
