<div
  class="controls"
  *ngIf="dataset"
  [hidden]="!showExportBtn"
  (mouseenter)="showExportBtn = true"
  (mouseleave)="showExportBtn = false"
>
  <button
    type="button"
    pButton
    icon="pi pi-download"
    label=""
    (click)="menu.toggle($event)"
    [pTooltip]="'Baixar dados'"
  ></button>
  <p-menu #menu [popup]="true" [model]="downloadOptions"></p-menu>
</div>
<div
  id="pivot-pie-chart"
  *ngIf="dataset"
  [style]="style"
  (mouseenter)="showExportBtn = true"
  (mouseleave)="showExportBtn = false"
>
  <div style="text-align: center; display: flex" [style]="chartStyle">
    <apx-chart
      *ngFor="let group of dataset"
      [series]="group.chartOptions.series"
      [chart]="group.chartOptions.chart"
      [labels]="group.chartOptions.labels"
      [legend]="group.chartOptions.legend"
      [colors]="group.chartOptions.colors"
      [title]="group.chartOptions.title"
      [plotOptions]="group.chartOptions.plotOptions"
      [tooltip]="group.chartOptions.tooltip"
      [dataLabels]="group.chartOptions.dataLabels"
      [states]="group.chartOptions.states"
    ></apx-chart>
  </div>
  <div class="label">
    <div
      *ngFor="let label of labels"
      class="item"
      [style.font-size.px]="legendTextSize"
      [ngClass]="{ deactivated: !label.activated }"
    >
      <div [style.background-color]="getColorByString(label.value)"></div>
      {{ label.value }}
    </div>
  </div>
</div>
<v360-error *ngIf="dataSetError" (reload)="reloadDataset()"></v360-error>
<v360-loading *ngIf="!dataset && !dataSetError"></v360-loading>
