
import { Injectable } from '@angular/core';
import { ID } from '@datorama/akita';
import { createPageParamStateModel, PageParamStateModel } from './page-param.model';
import { PageParamQuery } from './page-param.query';
import { PageParamStore } from './page-param.store';

@Injectable({ providedIn: 'root' })
export class PageParamServiceAkita {
  allPageParam$: PageParamStateModel[];

  constructor(private pageParamStore: PageParamStore,
              private pageParamQuery: PageParamQuery) {
                this.pageParamQuery.getAll$.subscribe(allPageParam => {
                  this.allPageParam$ = allPageParam
                })
  }

  add(param: string, value: string, initialized: boolean, label: string) {
    if(this.pageParamQuery.hasEntity(pageParam => pageParam.param == param)){
      return;
    }
    const pageParam = createPageParamStateModel(param, value, initialized, label) 
    this.updatePageParamUIState(false)
    this.pageParamStore.add(pageParam)
    if (!this.pageParamQuery.hasActive()) {
      let entity = this.pageParamQuery.getAll({
        limitTo: 1,
        filterBy: entity => entity.param === param
      })
      this.pageParamStore.setActive(entity[0].id)
    }
  }

  initialize(param: string){
    this.pageParamStore.update(null, e => e.param === param ? { ...e, initialized: true } : e)
    this.updateUIStateIfAllParamsAreInitialized()
  }

  updateUIStateIfAllParamsAreInitialized(){
    let pageParamsInitialized = this.allPageParam$.filter(pageParam => {
      return pageParam.initialized == true
    })
    if(pageParamsInitialized.length == this.allPageParam$.length){
      this.updatePageParamUIState(true);
    }
  }

  getActive(){
    return this.pageParamQuery.getActive()
  }

  selectActive(){
    for(let i = 0; i < this.allPageParam$.length; i++){
      let activeEntity = this.pageParamQuery.getActive()
      if(activeEntity && activeEntity.initialized){
        this.activeNext()
      }
    }
  }

  observeActive(){
    return this.pageParamQuery.selectActive(entity => entity.param)
  }

  activeNext(){
    this.pageParamStore.setActive({ next: true, wrap: false });
  }

  setLoading(loadingState: boolean){
    this.pageParamStore.setLoading(loadingState)
  }

  getLoadingState(){
    return this.pageParamQuery.selectLoading()
  }

  updatePageParamUIState(wasParamInserted: boolean) {
    this.pageParamStore.update({
      ui: {
        wasParamInserted
      }
    });
  }

  getAll(){
    return this.pageParamQuery.getAll$
  }

  updateValue(param: string, value: string, label: string){
    this.pageParamStore.update(null, e => e.param === param ? { ...e, value: value, label: label } : e)
  }

  getPageParamUIState(){
    return this.pageParamQuery.getPageParamUIState$
  }

  set(pageParams: PageParamStateModel[]){
    this.pageParamStore.set(pageParams)
  }

  delete(ids: ID[]) {
    if(!this.pageParamQuery.hasEntity(ids)){
      return;
    }
    this.pageParamStore.remove(ids);
  }

  deleteByParam(param: string){
    this.pageParamStore.remove(this.pageParamQuery.getAll({
      limitTo: 1,
      filterBy: entity => entity.param == param
    })[0].id)
  }

  getAllParams(){
    return this.allPageParam$
  }

  reset(){
    this.pageParamStore.remove(null)
  }
}