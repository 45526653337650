import { Injectable } from '@angular/core';
import { EntityState, EntityStore, StoreConfig, EntityUIStore, ActiveState } from '@datorama/akita';
import { PageParamStateModel } from './page-param.model';


export interface PageParamState extends EntityState<PageParamStateModel>, ActiveState{
  ui: {
    wasParamInserted: boolean
  }
}


export function createInitialState(): PageParamState {
  return {
    ui: {
      wasParamInserted: true
    },
    active: null
  };
}

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'page-param'})
export class PageParamStore extends EntityStore<PageParamState, PageParamStateModel> {

  constructor() {
    super(createInitialState());
  }
}