import { Injectable } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { LoadingComponent } from '../components/loading/loading.component';

@Injectable({
  providedIn: 'root'
})
export class LoadingService {

  constructor(
    private dialog: MatDialog,
  ) { }

  waitingLoadingCount = 0;
  private loadingDialogRef: MatDialogRef<LoadingComponent>;

  showLoading() {
    this.waitingLoadingCount++;
    if (!this.loadingDialogRef) {
      this.loadingDialogRef = this.dialog.open(LoadingComponent, {
        width: "250px",
        disableClose: true,
      });
    }
  }

  closeLoading(force?: boolean) {
    if (force) {
      this.waitingLoadingCount = 0;
      this.loadingDialogRef?.close();
      this.loadingDialogRef = null;
      return;
    }
    if (this.waitingLoadingCount > 0) {
      this.waitingLoadingCount--;
    }
    if (this.loadingDialogRef && this.waitingLoadingCount === 0) {
      this.loadingDialogRef?.close();
      this.loadingDialogRef = null;
    }
  }
}
