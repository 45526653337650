<div class="sandboxshot"></div>
<v360-navbar
  [reportsList]="null"
  [showLink]="
    !this.selectedReport?.demo &&
    !this.selectedReport?.wip &&
    !(this.reports.length <= 1)
  "
  [category]="category"
  [reportTitle]="reportTitle"
  [pageTitle]="pageTitle"
  [showTableLoading]="showTableLoading"
></v360-navbar>

<p-sidebar
  [(visible)]="opened"
  [styleClass]="'modal-page'"
  class="page-list-sidebar"
>
  <v360-report-page-list
    [dataSource]="reportPageTreeDataSource"
    [defaultPageParams]="queryConfig"
    [pageId]="pageId"
    [reportId]="reportId"
    [reportTreeControl]="reportPageTreeControl"
    [reports]="reports"
    (changeReportPageEvent)="changeReportPage($event)"
  >
  </v360-report-page-list>
</p-sidebar>

<p-sidebar
  [(visible)]="filterListModalOpened"
  position="right"
  [dismissible]="false"
  styleClass="filter-modal"
  [showCloseIcon]="false"
  autoZIndex="false"
  baseZIndex="9"
  (onShow)="adjustInkBarPrimeNg()"
>
  <div class="filter-modal-content">
    <div class="header">
      <button class="filter-settings" mat-icon-button>
        <mat-icon>settings</mat-icon>
      </button>
      <h1>{{ selectedFilter?.name }}</h1>
      <button
        data-cy="x-close-button"
        mat-icon-button
        (click)="cancelSelectedfilters()"
      >
        <mat-icon>close</mat-icon>
      </button>
    </div>
    <div
      class="selected-filters"
      *ngIf="selectedFilterValues && selectedFilterValues.length > 0"
    >
      <mat-chip-list class="selected-filters-list" [selectable]="false">
        <mat-chip
          class="custom"
          *ngFor="let filter of selectedFilterValues"
          [disabled]="!canDeleteChip"
          data-cy="selected-filter-tag"
          [removable]="true"
          [matTooltip]="filter.placeholder + ': ' + filter.name"
          (removed)="removeSelectedFilters(filter)"
          [selectable]="false"
          [ngClass]="{
            'selected-category': filter.category === selectedFilter.identity,
            exceto: filter.isExceto
          }"
        >
          <mat-icon class="tag">{{ filter.icon }}</mat-icon>
          <span>{{ filter.name }}</span>
          <mat-icon
            *ngIf="showDeleteChip(filter)"
            matChipRemove
            data-cy="remove-selected-filter-tag"
            >cancel</mat-icon
          >
        </mat-chip>
      </mat-chip-list>
      <mat-chip
        *ngIf="selectedFiltersTagExpandable"
        class="collapse"
        (click)="collapseSelectedFiltersTag()"
        data-cy="toggle-selected-filter-tag-list"
        [matTooltip]="
          selectedFiltersTagCollapsed ? 'Mostrar menos' : 'Mostrar mais'
        "
      >
        <mat-icon *ngIf="!selectedFiltersTagCollapsed">expand_more</mat-icon>
        <mat-icon *ngIf="selectedFiltersTagCollapsed">expand_less</mat-icon>
      </mat-chip>
    </div>
    <p-tabView
      *ngIf="allFiltersList"
      (onChange)="filterTabChanged($event.index)"
      [(activeIndex)]="filtersTabIndex"
    >
      <p-tabPanel rightIcon="star">
        <div class="favorite-table-empty" *ngIf="favoriteFilters?.length === 0">
          <p>
            Você ainda não tem nenhum filtro salvo na sua lista de filtros
            favoritos.
          </p>
          <p>
            Para adicionar um filtro na lista de favoritos, utilize a
            <mat-icon>star_outline</mat-icon> que está ao lado esquerdo do botão
            de filtros.
          </p>
        </div>
        <div *ngIf="favoriteFilters?.length > 0">
          <v360-filter-favorite-table
            (delete)="deleteFavoriteFilter($event)"
            (selected)="selectFavoriteFilter($event)"
          >
          </v360-filter-favorite-table>
        </div>
      </p-tabPanel>
      <p-tabPanel
        *ngFor="let tab of allFiltersList; let index = index"
        rightIcon="{{ tab.icon }}"
        [selected]="index == 0"
      >
        <div class="input-filters">
          <ng-container *ngFor="let filter of tab.filters">
            <v360-filter-with-actions
              [filter]="filter"
              [category]="tab"
              [filter-items]="filter.filterItems"
              [isMandatory]="filter.isMandatory"
              [unique]="filter.unique || false"
              [filterSize]="filter.size"
              (toastFromFilter)="toastFromFilter($event)"
              (filterSelected)="filterChanged($event)"
              (isFieldOpened)="filterFieldOpened($event)"
            ></v360-filter-with-actions>
          </ng-container>
        </div>
      </p-tabPanel>
    </p-tabView>
    <div class="actions">
      <button
        mat-raised-button
        data-cy="apply-filter-btn"
        class="primary"
        (click)="applyFilters()"
      >
        Aplicar
      </button>
      <button
        mat-raised-button
        data-cy="clean-selected-filter-btn"
        (click)="removeSelectedFilters()"
      >
        Limpar
      </button>
      <button
        mat-raised-button
        data-cy="undo-selected-filter-btn"
        color="warn"
        (click)="cancelSelectedfilters()"
      >
        Desfazer
      </button>
    </div>
  </div>
  <div class="overlay-custom" *ngIf="filterFieldOpenedCount > 0"></div>
</p-sidebar>
<div class="body-content">
  <section class="body-content__side-bar">
    <section class="body-content__side-bar--controls">
      <button
        pButton
        type="button"
        class="p-button-rounded"
        icon="pi pi-bars"
        (click)="opened = !opened"
        pTooltip="Páginas"
        tooltipPosition="right"
        data-cy="open-report-list-btn"
      ></button>
      <button
        pButton
        class="p-button-rounded"
        icon="pi pi-refresh"
        (click)="reloadDashboardReport()"
        tooltipPosition="right"
        pTooltip="Recarregar"
        data-cy="refresh-page"
      ></button>
      <button
        pButton
        class="p-button-rounded"
        icon="pi pi-window-maximize"
        (click)="toggleFullscreen()"
        *ngIf="!isFullscreen"
        tooltipPosition="right"
        pTooltip="Tela cheia"
        data-cy="open-fullscreen"
      ></button>
      <button
        pButton
        class="p-button-rounded"
        icon="pi pi-window-minimize"
        (click)="toggleFullscreen()"
        *ngIf="isFullscreen"
        tooltipPosition="right"
        pTooltip="Sair da tela cheia"
        data-cy="close-fullscreen"
      ></button>
      <button
        pButton
        class="p-button-rounded"
        icon="pi pi-camera"
        (click)="openScreenshotArea()"
        tooltipPosition="right"
        pTooltip="Tirar screenshot"
        data-cy="take-screenshot"
      ></button>
    </section>
  </section>
  <div class="content-report">
    <div class="applied-filters-tags">
      <div class="filters-tag-list">
        <mat-chip-list class="applied-filters" [selectable]="false">
          <mat-chip
            [id]="filter.filterName"
            (click)="openMandatoryFilterAlert(filter, $event)"
            class="custom"
            *ngFor="let filter of filtroObrigatorioObjectList"
          >
            <mat-icon class="tag">{{ filter.icon }}</mat-icon>
            <span>{{ filter.name }}</span>
          </mat-chip>
          <mat-chip
            (click)="openFilter(filter, $event)"
            class="custom"
            *ngFor="let filter of appliedFilters$ | async"
            [removable]="true"
            [disabled]="!canDeleteChip || !allPageParamsAreInitialized"
            data-cy="applied-filter-tag"
            [selectable]="false"
            [matTooltip]="filter.placeholder + ': ' + filter.name"
            (removed)="removeAppliedFilters(filter)"
            [ngClass]="{ exceto: filter.isExceto }"
          >
            <mat-icon class="tag">{{ filter.icon }}</mat-icon>
            <span>{{ filter.name }}</span>
            <mat-icon
              *ngIf="showDeleteChip(filter)"
              matChipRemove
              data-cy="remove-applied-filter-tag"
              >cancel</mat-icon
            >
          </mat-chip>
        </mat-chip-list>
        <div class="hidden-filters">
          <div *ngFor="let tab of allFiltersList">
            <ng-container *ngFor="let filter of tab.filters">
              <v360-filter-with-actions
                [isChips]="true"
                [filter]="filter"
                [category]="tab"
                [filter-items]="filter.filterItems"
                (filterSelected)="filterChanged($event)"
                (isFieldOpened)="filterFieldOpened($event)"
                [isMandatory]="filter.isMandatory"
                [unique]="filter.unique || false"
                (applyFilters)="applyFilters()"
                (toastFromFilter)="toastFromFilter($event)"
                [filterSize]="filter.size"
              ></v360-filter-with-actions>
            </ng-container>
          </div>
        </div>
        <mat-chip
          *ngIf="appliedFiltersTagExpandable"
          class="collapse"
          (click)="collapseAppliedFiltersTag()"
          data-cy="tootle-applied-filter-tag-list"
          [matTooltip]="
            appliedFiltersTagCollapsed ? 'Mostrar menos' : 'Mostrar mais'
          "
        >
          <mat-icon *ngIf="!appliedFiltersTagCollapsed">expand_more</mat-icon>
          <mat-icon *ngIf="appliedFiltersTagCollapsed">expand_less</mat-icon>
        </mat-chip>
      </div>
      <button
        class="fav-btn"
        mat-icon-button
        (click)="showSaveFilter = true"
        data-cy="favorite-filter"
        *ngIf="!this.selectedReport?.demo && !this.selectedReport?.wip"
        matTooltip="Favoritar filtros aplicados"
      >
        <mat-icon *ngIf="!isFavorite()">star_outline</mat-icon>
        <mat-icon *ngIf="isFavorite()">star</mat-icon>
      </button>
      <button
        mat-button
        class="indicator-btn"
        (click)="showIndicator = !showIndicator"
        [ngClass]="{
          'indicator-red': indicator === 0,
          'indicator-yellow': indicator === 1,
          'indicator-error': indicatorError
        }"
        data-cy="indicator-button"
        *ngIf="indicator !== 10 || indicatorError"
        [matTooltip]="
          !indicatorError
            ? 'Clique para visualizar o tamanho da amostra filtrada.'
            : 'Amostra indisponível'
        "
      >
        <span>Amostra</span>
        <mat-icon
          class="indicator"
          [ngClass]="{
            'indicator-red': indicator === 0,
            'indicator-yellow': indicator === 1,
            'indicator-error': indicatorError
          }"
          *ngIf="!showTableLoading"
        >
          circle
        </mat-icon>
        <mat-icon
          svgIcon="bx-loader"
          class="loader"
          *ngIf="showTableLoading"
        ></mat-icon>
      </button>
      <button
        pButton
        *ngIf="!this.selectedReport?.demo && !this.selectedReport?.wip"
        [disabled]="!allPageParamsAreInitialized"
        label="Filtros"
        icon="pi pi-filter"
        class="filter-btn-open btn__open-filter"
        iconPos="right"
        (click)="openFilterModal()"
        data-cy="open-filters-menu"
      ></button>
    </div>
    <v360-table-indicators
      [show]="showIndicator"
      [showLoading]="showTableLoading"
      [hasError]="indicatorError"
      (tableClosed)="closeTable($event)"
    ></v360-table-indicators>
    <v360-save-filter
      [show]="showSaveFilter"
      [isFavorite]="isFavorite()"
      (saveFilter)="saveFavoriteFilter($event)"
      (cancel)="showSaveFilter = false"
      (remove)="deleteFavoriteFilter($event)"
      (updateFilter)="updateFavoriteFilter($event)"
    >
    </v360-save-filter>
    <div class="new-dash">
      <ng-template #body></ng-template>
    </div>
  </div>
  <div class="footer" data-cy="report-footer">
    <div *ngIf="!isIqviaPanel">Varejo 360</div>
    <div *ngIf="isIqviaPanel">IQVIA</div>
    <div>
      <div *ngIf="userService.getLocalUser().trialLimit" style="color: red">
        Degustação disponível até
        <strong
          >{{
            userService.getLocalUser().trialLimit | date : "dd/MM/yyyy"
          }}.</strong
        >
      </div>
      <div
        *ngIf="selectedReport?.wip && !selectedReport?.demo"
        style="color: red"
      >
        Base de dados em construção, em breve disponível para contratação.
      </div>
      <div
        *ngIf="!selectedReport?.wip && selectedReport?.demo"
        style="color: red"
      >
        Consulte <strong>vendas@varejo360.com</strong> para ter acesso ao
        relatório completo.
      </div>
    </div>
    <div>
      <div data-cy="updated-report-date">
        Dados atualizados até {{ reportUpdateTime | date : "dd/MM/yyyy" }}
      </div>
    </div>
  </div>
  <p-overlayPanel
    #mandatoryAlert
    [style]="{ 'max-width': '450px' }"
    [dismissable]="false"
  >
    <ng-template pTemplate>
      <div>
        <span
          >Atenção! Para essa página ser carregada, você precisa selecionar um
          filtro de {{ mandatoryText }}.</span
        >
      </div>
      <br />
      <div>
        <span>Para isso, clique na tag {{ mandatoryText }}.</span>
      </div>
    </ng-template>
  </p-overlayPanel>
</div>
