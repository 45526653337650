import { Component, OnInit, Inject } from '@angular/core';
import { FavoriteFilter } from '@models/favorite-filter';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'v360-delete-favorite-filter',
  templateUrl: './delete-favorite-filter.component.html'
})
export class DeleteFavoriteFilterComponent implements OnInit {

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: FavoriteFilter
  ) { }

  ngOnInit(): void {
  }

}
