<div 
  (mouseover)="showExportBtn = true" 
  (mouseout)="showExportBtn = false"
  [style]="chartStyle">
  <div class="controls" *ngIf="chartOptions" [hidden]="!showExportBtn">
    <button type="button" pButton icon="pi pi-download" label="" (click)="menu.toggle($event)" [pTooltip]="'Baixar dados'"></button>
    <p-menu #menu [popup]="true" [model]="downloadOptions"></p-menu>
  </div>
  <div [id]="componentId" *ngIf="chartOptions" style="text-align:center; display: flex;">
    <apx-chart style="width: 99%;"
      [series]="chartOptions.series"
      [chart]="chartOptions.chart"
      [labels]="chartOptions.labels"
      [legend]="chartOptions.legend"
      [colors]="chartOptions.colors"
      [title]="chartOptions.title"
      [plotOptions]="chartOptions.plotOptions"
      [tooltip]="chartOptions.tooltip"
      [dataLabels]="chartOptions.dataLabels"
      [states]="chartOptions.states"
      [markers]="chartOptions.markers"
      [stroke]="chartOptions.stroke"
      [yaxis]="chartOptions.yaxis"
      [xaxis]="chartOptions.xaxis"
      [fill]="chartOptions.fill"
      [grid]="chartOptions.grid"
    ></apx-chart>
  </div>
  <v360-error *ngIf="dataSetError" (reload)="reloadDataset()"></v360-error>
  <v360-loading *ngIf="!chartOptions && !dataSetError"></v360-loading>
</div>