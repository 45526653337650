import { ReportFolder } from './report-folder';
import { FlatTreeControl } from '@angular/cdk/tree';
import { MatTreeFlattener } from '@angular/material/tree';

export interface FlatNode {
  expandable: boolean;
  name: string;
  id: string;
  level: number;
  config: any;
}
export class ReportPageTree {
  name: string;
  id: string;
  content?: ReportPageTree[];
  config?: any;

  static convertFromFolder(folderList: ReportFolder[]) {
    return folderList.map(folder => {
      return {
        name: folder.folder.split('.')[1]?.trim(),
        id: folder.dashboard_id,
        content: folder.content.map(page => {
          return {
            name: `${page.title}`,
            title: page.title,
            id: page.id,
            config: page.config
          }
        })
      }
    });
  }

  static getTreeControl() {
    return new FlatTreeControl<FlatNode>(
      (node) => node.level,
      (node) => node.expandable
    );
  }

  static getTreeFlatten() {
    const _transformer = (node: ReportPageTree, level: number) => {
      return {
        expandable: !!node.content && node.content.length > 0,
        name: node.name,
        id: node.id,
        config: node.config ?? {},
        level: level
      };
    };
    return new MatTreeFlattener(
      _transformer,
      (node) => node.level,
      (node) => node.expandable,
      (node) => node.content
    );
  }
}
