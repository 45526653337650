import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import { UserService } from '../services/user.service';

@Injectable({
  providedIn: 'root'
})
export class AdminGuard implements CanActivate {
  constructor(
    private userService: UserService,
    private router: Router
  ) {}

  canActivate() {
    if (!this.userService.getLocalUser()) {
      this.router.navigateByUrl('/login', {replaceUrl: true});
      return false;
    }
    if (this.userService.userIsAdmin()) {
      return true;
    }
    this.router.navigateByUrl('/reports', {replaceUrl: true});
    return false;
  }
}