import { Injectable, EventEmitter } from '@angular/core';
import { FormControl } from '@angular/forms';
import { Filter } from '@models/filter';
import { HttpClient, HttpParams } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { FavoriteFilter } from '@models/favorite-filter';
import { map, catchError } from 'rxjs/operators';
import { throwError } from 'rxjs';
import { FilterQuery } from '@models/filters-query';
import { createFilter, Filter as FilterAkita } from "@state/filter/filter.model"
import { Utils } from '../utils';
import { FilterServiceAkita } from '@state/filter/filter.service';

@Injectable({
  providedIn: 'root'
})
export class FilterService {
  filters;
  selectedFilter = new FormControl();
  appliedFilters;
  loadingValues = new FormControl();
  selectedFavoriteFilter = new FormControl();
  filterRemoved: EventEmitter<any> = new EventEmitter();
  removeAllEvent: EventEmitter<any> = new EventEmitter();
  loadAllItems: EventEmitter<any> = new EventEmitter();
  selectedFiltersByField = new Object();
  filterHash: string;
  favoriteFilters = new FormControl();

  // TODO: remover variavel, incluir novo formato de filtro
  dashboardFilter = new Object() as any;

  constructor(
    private http: HttpClient,
    private filterServiceAkita: FilterServiceAkita
  ) {
    this.filterServiceAkita.getAppliedFilters().subscribe((appliedFilters) => {
      this.appliedFilters = appliedFilters;
    });
  }
  getFilterList(reportId: number) {
    const reportParam = new HttpParams().set('report', reportId.toString());
    return this.http.get(`${environment.root}/filters`, { params: reportParam })
      .pipe(
        map(((response: { filter: Filter[] }) => {
          this.filters = response.filter;
          return response.filter;
        }))
      );
  }

  applyFilters() {
    const filtersToApply = [];
    if (this.selectedFilter.value && this.selectedFilter.value.length > 0) {
      this.selectedFilter.value.forEach(filter => {
        filtersToApply.push(createFilter(filter.category, filter.filterName, filter.icon, filter.placeholder, filter.name, filter.isMandatory ? filter.isMandatory: false, filter.unique ? filter.unique: false, filter.isExceto ? filter.isExceto: false));
      })
      this.filterServiceAkita.set(filtersToApply);
    }else{
      this.filterServiceAkita.reset();
    }
  }

  isFilterItem(object: any): object is FilterAkita {
    return 'filterName' in object;
  }

  getFilterValues(reportId: number, filterQuery: FilterQuery) {
    let keys = Object.keys(filterQuery.filters);
    keys.forEach(val => {
      if (filterQuery.filters[val].length === 0) {
        delete filterQuery.filters[val];
      }
    });
    this.loadingValues.patchValue(true);
    return this.http.post(`${environment.root}/reports/${reportId}/filters`, filterQuery)
      .pipe(
        map(response => {
          this.loadingValues.patchValue(false);
          return response;
        }),
        catchError(e => {
          this.loadingValues.patchValue(false);
          return throwError(e);
        })
      );
  }


  emitLoadAllItems(allFiltersList) {
    this.loadAllItems.emit(allFiltersList);
  }

  sendFiltersToCreateHash(reportId: number, filter) {
    return this.http.post(`${environment.root}/filters/${reportId}`, { filters: filter.filters })
      .pipe(
        map((response: { hash: string }) => {
          this.filterHash = response.hash;
          return response;
        })
      );
  }

  addSelectedFilter(filterItem: { [key: string]: FilterAkita[] }) {
    for (const key in filterItem) {
      if ((this.dashboardFilter[Utils.removeNotPrefix(key).string] && Utils.removeNotPrefix(key).hasNotPrefix)) {
        delete this.dashboardFilter[Utils.removeNotPrefix(key).string];
      } else if (this.dashboardFilter[`!${key}`] && !(Utils.removeNotPrefix(key).hasNotPrefix)) {
        delete this.dashboardFilter[`!${key}`];
      }
      this.dashboardFilter[key] = filterItem[key].map(filter => filter);
    }
    const filters = [];
    for (const key in this.dashboardFilter) {
      this.dashboardFilter[key].forEach((filter: string) => {
        if (filter) {
          filters.push(filter);
        }
      });
    }
    this.selectedFilter.patchValue(filters)
  }

  removeAllSelectedFilters(except?: string[]) {
    if (except && except.length > 0) {
      Object.keys(this.dashboardFilter).filter(item => !except.includes(item)).forEach(item => {
        delete this.dashboardFilter[item];
      })
      var keepValues = this.selectedFilter.value.filter(item => except.includes(item.filterName));
      this.selectedFilter.patchValue(keepValues);
    } else {
      this.removeAllEvent.emit();
      for (const key in this.dashboardFilter) {
        this.dashboardFilter[key] = [];
      }
      this.selectedFilter.reset();
    }
  }

  removeSelectedFilter(filter: FilterAkita) {
    this.removeSelectedDashFilter(filter);
    this.selectedFilter.patchValue(this.selectedFilter.value.filter(item => filter.name !== item.name));
    this.filterRemoved.emit(filter);
  }

  removeAppliedFilter(filter: FilterAkita) {
    this.removeSelectedFilter(filter);
    this.filterServiceAkita.delete([filter.id])
  }

  removeBulkSelectedFilter(filters: FilterAkita[]) {
    let val = this.selectedFilter.value.filter(filter => {
      let remover = false;
      filters.forEach(item => remover = item.name === filter.name && item.filterName === filter.filterName);
      return !remover;
    });
    this.selectedFilter.patchValue(val);
  }

  removeBulkAppliedFilter(filters: {}) {
    let filterList = [];
    Object.keys(filters).forEach(key => {
      filterList = [...filters[key]];
    });
    let val = this.appliedFilters.filter(filter => {
      return filterList.filter(item => item.name === filter.name && item.filterName === filter.filterName).length < 1;
    }).map(filter => {
      return filter.id;
    });
    this.filterServiceAkita.delete(val)
    this.removeBulkSelectedFilter(filterList)
  }

  removeBulkDashboardFilter(filters) {
    for (const key in filters) {
      this.dashboardFilter[key] = this.dashboardFilter[key].filter(filter => {
        let remover = false;
        filters[key].forEach(item => remover = item.name === filter.name && item.filterName === filter.filterName);
        return !remover;
      });
    }
  }

  removeDashboardFilter(filter: FilterAkita) {
    const filters = {};
    for (const key in this.dashboardFilter) {
      const filterObject = Utils.removeNotPrefix(key);
      if (filter.filterName === filterObject["string"]) {
        filters[key] = this.dashboardFilter[key].filter(item => item.name !== filter.name);
      } else {
        filters[key] = this.dashboardFilter[key];
      }
    }
    this.dashboardFilter = { ...filters };
  }

  removeSelectedDashFilter(filter: FilterAkita) {
    const filters = {};
    for (const key in this.dashboardFilter) {
      const filterObject = Utils.removeNotPrefix(key);
      if (filter.filterName === filterObject["string"]) {
        filters[key] = this.dashboardFilter[key].filter(item => item.name !== filter.name);
      } else {
        filters[key] = this.dashboardFilter[key];
      }
    }
    this.dashboardFilter = { ...filters };
  }

  cancelSelectedFilters() {
    if (!this.appliedFilters || this.appliedFilters.length == 0) {
      this.clearAllFilters();
    } else {
      const filters: { [key: string]: FilterAkita[] } = {};
      this.appliedFilters.forEach(appliedFilter => {
        if (!filters[appliedFilter.filterName]) {
          if (appliedFilter["isExceto"] == true) {
            filters[`!${appliedFilter.filterName}`] = [appliedFilter];
          } else {
            filters[appliedFilter.filterName] = [appliedFilter];
          }
        } else {
          filters[appliedFilter.filterName].push(appliedFilter);
        }
      })
      this.dashboardFilter = filters;
      this.selectedFilter.patchValue(this.appliedFilters);
    }
  }

  clearAllFilters() {
    this.dashboardFilter = {};
    this.selectedFilter.reset();
    this.filterServiceAkita.reset();
  }

  formatFilters(filters: { [key: string]: FilterAkita[] }) {
    const formatedFilters = {};
    for (const key in filters) {
      formatedFilters[key] = filters[key]
        .map((filter) => {
          return `${filter.name}`;
        })
    }
    return formatedFilters;
  }

  extractFilter(filter: FilterAkita[]) {
    const filters: { [key: string]: FilterAkita[] } = {};
    filter.forEach(f => {
      if (!filters[f.filterName]) {
        if (f["isExceto"] == true) {
          if (filters[`!${f.filterName}`]) {
            filters[`!${f.filterName}`] = [...filters[`!${f.filterName}`], f];
          } else {
            filters[`!${f.filterName}`] = [f];
          }
        } else {
          if (filters[f.filterName]) {
            filters[f.filterName] = [...filters[f.filterName], f];
          } else {
            filters[f.filterName] = [f];
          }
        }
      } else {
        filters[f.filterName].push(f);
      }
    });

    return this.formatFilters(filters);
  }

  private extractDefaultFilterName(name: string, key: string) {
    key = Utils.removeNotPrefix(key)["string"];
    const intKeys = ['ano', 'mes', 'semana', 'embalagem', 'semestre', 'trimestre'];
    if (intKeys.includes(key)) {
      return parseInt(name);
    }
    return name.trim().replace('markcommaseparator', ',');
  }

  extractDefaultFilters(filter: { [key: string]: string[] | string }, isMandatory?: boolean): { [key: string]: FilterAkita[] } {
    const newValues = {};
    for (const key in filter) {
      if (key == 'field') continue;
      const filters = [];
      (filter[key] as string[])
        .map((value) => {
          const filterObject = Utils.removeNotPrefix(key);
          const obj = {
            filterName: filterObject["string"],
            name: this.extractDefaultFilterName(value, filterObject["string"]),
            category: '',
            icon: '',
            placeholder: '',
            unique: filter['field'] === 'unique' || false,
            isMandatory: isMandatory || false
          };

          obj["isExceto"] = (filterObject["hasNotPrefix"] == true);

          for (const index in this.filters) {
            for (const filter in this.filters[index].filters) {
              if (this.filters[index].filters[filter].filterName === filterObject["string"]) {
                obj.icon = this.filters[index].icon;
                obj.category = this.filters[index].identity;
                obj.placeholder = this.filters[index].filters[
                  filter
                ].placeHolder;
              }
            }
          }
          return obj;
        })
        .forEach((value) => filters.push(value));
      newValues[key] = filters;
    }
    return newValues;
  }

  saveFilter(reportId: number, filter: { [key: string]: string }, name: string) {
    return this.http.post(`${environment.root}/filters/favorites/${reportId}`, { title: name, filters: filter })
      .pipe(
        map((response: FavoriteFilter) => {
          this.favoriteFilters.patchValue([...this.favoriteFilters.value, response]);
          return response;
        })
      );
  }

  deleteFavoriteFilter(filter: FavoriteFilter) {
    return this.http.delete(`${environment.root}/filters/favorites/${filter.id}`)
      .pipe(
        map(response => {
          this.favoriteFilters.patchValue(this.favoriteFilters.value.filter(favoriteFilter => favoriteFilter.id !== filter.id));
          return response;
        })
      );
  }

  updateFavoriteFilter(filter: FavoriteFilter) {
    return this.http.put(`${environment.root}/filters/favorites/${filter.id}`, { title: filter.title })
      .pipe(
        map(response => {
          this.favoriteFilters.patchValue(
            this.favoriteFilters.value.map(favorite => favorite.id === filter.id ? filter : favorite)
          );
          return response;
        })
      )
  }

  getFavoriteFilter(hash: string) {
    return this.http.get(`${environment.root}/filters/favorites?h=${hash}`)
      .pipe(
        map(response => {
          return response;
        })
      )
  }
}
