<p-multiSelect #select 
  (onPanelShow)="selectOpen()" 
  (onPanelHide)="selectClose($event)"
  (onChange)="itemChanged($event)"
  [options]="_items" 
  [(ngModel)]="selectedItems"
  [placeholder]="placeholder" 
  [disabled]="!_active && !allPageParamsAreInitialized"
  [showToggleAll]="!unique"
  emptyFilterMessage="Não foi encontrado nenhum item"
  emptyMessage="Não foi encontrado nenhum item"
  optionLabel="name"
  class="param-selector"
  itemSize="40">
  <ng-template let-value pTemplate="item">
    <span class="item-name" [pTooltip]="value.name" tooltipPosition="top">{{ value.name }}</span>
    <button *ngIf="!unique" pButton type="button" label="SOMENTE" (click)="somente($event, value)"
      class="p-button-outlined-inicial">
    </button>
  </ng-template>
  <ng-template pTemplate="footer">
    <div class="overlay-footer">
      <div class="overlay-footer--controls">
        <button *ngIf="!unique" pButton type="button" label="Limpar" class="secondary-button"
          (click)="clearSelected($event)"></button>
        <button data-cy="success-button-filter" pButton type="button" label="OK" class="success-button" (click)="close($event)"></button>
      </div>
    </div>
  </ng-template>
</p-multiSelect>
<p-overlayPanel #mandatoryOverlay [style]="{'max-width': '450px'}" *ngIf="!overlayOpened && (!wasInitialized && _active)" [dismissable]="false">
  <ng-template pTemplate>
    <div><span>Atenção! Para essa página ser carregada, você precisa selecionar um valor para {{ placeholder }}.</span></div>
    <br>
    <div><span>Para isso, clique no texto sublinhado acima.</span></div>
  </ng-template>
</p-overlayPanel>