
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { FilterStore } from './filter.store';
import { Filter, createFilter } from './filter.model';
import { ID } from '@datorama/akita';
import { FilterQuery } from './filter.query';

@Injectable({ providedIn: 'root' })
export class FilterServiceAkita {

  constructor(private filterStore: FilterStore,
              private filterQuery: FilterQuery) {
  }

  add(category: string, filterName: string, icon: string, placeholder: string, name: string | number, mandatory: boolean = false, unique: boolean = false, exceto:boolean = false) {
    if(this.filterQuery.hasEntity(filter => filter.name == name)){
      return;
    }
    const filter = createFilter(category, filterName, icon, placeholder, name, mandatory, unique, exceto) 
    this.filterStore.add(filter)
  }

  set(filters: Filter[]){
    this.filterStore.set(filters)
  }

  delete(ids: ID[]) {
    if(!this.filterQuery.hasEntity(ids)){
      return;
    }
    this.filterStore.remove(ids);
  }

  reset(){
    this.filterStore.remove(null)
  }

  upsertFilter(filter: Filter) {
    this.filterStore.upsert(filter.id, filter, (id, newState) => ({ id, ...newState }));
  }

  updateMandatoryFilterStatus(filtroObrigatorioKeys){
    this.filterStore.update(null, filter => ({ isMandatory: filtroObrigatorioKeys.includes(filter.filterName) }))
  }

  getAppliedFiltersFromFilterName(filterName: string){
    this.filterQuery.setFilterName(filterName);
    return this.filterQuery.getFiltersByFilterName$
  }

  getAppliedFilters(){
    return this.filterQuery.getAppliedFilters$
  }
}