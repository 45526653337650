import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent, HttpErrorResponse } from '@angular/common/http';
import { Credentials } from '@models/credentials';
import { catchError, switchMap, filter, take } from 'rxjs/operators';
import { BehaviorSubject, Observable, throwError } from 'rxjs';
import { AuthService } from '@services/auth.service';
import { Router } from '@angular/router';
import { environment } from 'src/environments/environment';

@Injectable()
export class RefreshTokenInterceptor implements HttpInterceptor {
  private isRefreshing = false;
  private refreshTokenSubject: BehaviorSubject<any> = new BehaviorSubject<any>(null);

  constructor(
    public authService: AuthService,
    private router: Router,
  ) {}

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const credentials = this.authService.getCredentials();
    if (credentials && !request.url.includes('refreshToken')) {
      request = this.addToken(request, credentials.token);
    }
    if (environment.sendHeaders) {
      request = request.clone({
        setHeaders: {
          'X-Forwarded-For': '127.0.0.1'
        }
      });
    }
    return next.handle(request).pipe(catchError(error => {
      if (environment.name === 'maintenance') {
        this.authService.logout();
        this.router.navigateByUrl('maintenance', { replaceUrl: true });
      }
      if (error instanceof HttpErrorResponse && error.status === 401) {
        if (error.url.includes('/refreshtoken')) {
          this.authService.logout();
          this.router.navigate(['login']);
          this.isRefreshing = false;
        }
        else if (error.url.includes('/token')) {
          return throwError(error);
        }
        return this.handle401Error(request, next);
      } else {
        return throwError(error);
      }
    }));
  }

  private addToken(request: HttpRequest<any>, token: string) {
    return request.clone({
      setHeaders: {
        'Authorization': `Bearer ${token}`
      }
    });
  }

  private handle401Error(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (!this.isRefreshing) {
      this.isRefreshing = true;
      this.refreshTokenSubject.next(null);
      return this.authService.refreshToken()
        .pipe(
          switchMap((response: Credentials) => {
            this.isRefreshing = false;
            this.refreshTokenSubject.next(response.token);
            return next.handle(this.addToken(request, response.token));
          }), catchError(error => {
            this.isRefreshing = false;
            return throwError(error);
          })
        );
    } else {
      return this.refreshTokenSubject.pipe(
        filter(token => token !== null),
        take(1),
        switchMap(jwt => {
          return next.handle(this.addToken(request, jwt));
        })
      )
    }
  }
}
