import { AuthService } from '@services/auth.service';
import { MatInputModule } from '@angular/material/input';
import { NgModule, LOCALE_ID } from '@angular/core';
import { CommonModule, registerLocaleData } from '@angular/common';
import { NavbarComponent } from './navbar/navbar.component';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatButtonModule } from '@angular/material/button';
import { LoadingComponent } from './loading/loading.component';
import { MatDialogModule } from '@angular/material/dialog';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatSelectModule } from '@angular/material/select';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { MatChipsModule } from '@angular/material/chips';
import { MatIconModule } from '@angular/material/icon';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatTabsModule } from '@angular/material/tabs';
import { MatListModule } from '@angular/material/list';
import { CancelFiltersComponent } from './cancel-filters/cancel-filters.component';
import { ScrollingModule } from '@angular/cdk/scrolling';
import { MatTooltipModule } from '@angular/material/tooltip';
import { TableIndicatorsComponent } from './table-indicators/table-indicators.component';
import { MatTableModule } from '@angular/material/table';
import { FormatTextPipe } from '../pipes/format-text.pipe';
import localePt from '@angular/common/locales/pt';
import { ChangeBrowserComponent } from './change-browser/change-browser.component';
import { MatMenuModule } from '@angular/material/menu';
import { SaveFilterComponent } from './save-filter/save-filter.component';
import { DeleteFavoriteFilterComponent } from './delete-favorite-filter/delete-favorite-filter.component';
import { ReportPageListComponent } from './report-page-list/report-page-list.component';
import { MatTreeModule } from '@angular/material/tree';
import { FilterFavoriteTableComponent } from './filter-favorite-table/filter-favorite-table.component';
import { MatSortModule } from '@angular/material/sort';
import { PreviewTableComponent } from './preview-table/preview-table.component';
import { QueriesTableComponent } from './queries-table/queries-table.component';
import { TableModule } from 'primeng/table';
import { MultiSelectModule } from 'primeng/multiselect';
import {InputTextModule} from 'primeng/inputtext';
import {ButtonModule} from 'primeng/button';
import { AgGridModule } from 'ag-grid-angular';
import {DropdownModule} from 'primeng/dropdown';
import { ConfirmationService } from 'primeng/api';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { FilterWithActionsComponent } from './filter-with-actions/filter-with-actions.component';
import { OverlayPanelModule } from 'primeng/overlaypanel';
import { ListboxModule } from 'primeng/listbox';
import { RadioButtonModule } from 'primeng/radiobutton';
import { FilterButtonOverlayService } from '../services/filter-button-overlay.service';
import { ClickOutsideModule } from 'ng-click-outside';
import {TooltipModule} from 'primeng/tooltip';
import {ToggleButtonModule} from 'primeng/togglebutton';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import {DialogModule} from 'primeng/dialog';
import { MatCardModule } from '@angular/material/card';
import { ReportPageTreeElementComponent } from './report-page-tree-element/report-page-tree-element.component';
import { ParamSelectorComponent } from './param-selector/param-selector.component';
import { DynamicHooksModule } from 'ngx-dynamic-hooks';
registerLocaleData(localePt, 'pt-BR');
@NgModule({
  declarations: [
    NavbarComponent,
    LoadingComponent,
    CancelFiltersComponent,
    TableIndicatorsComponent,
    FormatTextPipe,
    ChangeBrowserComponent,
    SaveFilterComponent,
    DeleteFavoriteFilterComponent,
    ReportPageListComponent,
    FilterFavoriteTableComponent,
    PreviewTableComponent,
    QueriesTableComponent,
    FilterWithActionsComponent,
    ReportPageTreeElementComponent,
    ParamSelectorComponent
  ],
  imports: [
    CommonModule,
    MatDialogModule,
    MatButtonModule,
    MatToolbarModule,
    MatProgressSpinnerModule,
    MatSelectModule,
    MatFormFieldModule,
    FormsModule,
    MatInputModule,
    ReactiveFormsModule,
    MatChipsModule,
    MatSidenavModule,
    MatTabsModule,
    MatListModule,
    ScrollingModule,
    MatTableModule,
    MatMenuModule,
    MatTreeModule,
    MatSortModule,
    TableModule,
    MultiSelectModule,
    InputTextModule,
    ButtonModule,
    AgGridModule.withComponents([]),
    DropdownModule,
    ConfirmDialogModule,
    OverlayPanelModule,
    ListboxModule,
    RadioButtonModule,
    ClickOutsideModule,
    TooltipModule,
    ToggleButtonModule,
    InfiniteScrollModule,
    DialogModule,
    MatCardModule,
    MatIconModule,
    MatTooltipModule,
    DynamicHooksModule
  ],
  providers: [
    AuthService,
    ConfirmationService,
    FilterButtonOverlayService,
    {
      provide: LOCALE_ID,
      useValue: 'pt-BR'
    }
  ],
  exports: [
    NavbarComponent,
    LoadingComponent,
    TableIndicatorsComponent,
    ChangeBrowserComponent,
    SaveFilterComponent,
    DeleteFavoriteFilterComponent,
    ReportPageListComponent,
    FilterFavoriteTableComponent,
    PreviewTableComponent,
    QueriesTableComponent,
    FilterWithActionsComponent,
    ParamSelectorComponent
  ]
})
export class ComponentsModule { }
