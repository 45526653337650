import { HttpClient } from '@angular/common/http';
import { Injectable} from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { QueryTable } from '@models/query-table';
import { DashboardPageModel } from '@models/dashboard-page';
import { Utils } from '../utils';
import { Query } from '@models/query';
import { remove } from 'lodash';

@Injectable({
  providedIn: 'root'
})
export class MainCrudService {

  constructor(
    private http: HttpClient
  ) { }


  getDashboardTypes(): Observable<DashboardPageModel[]>{
    return this.http.get<DashboardPageModel[]>(`${environment.root}/dashboard/types`)
    .pipe(
      map((response: DashboardPageModel[]) => {
        return Utils.orderArrayASC(response, 'title')
      })
    );
  }

  testQuery(query: string) {
    const queryObj = {query};
    return this.http.post(`${environment.root}/dashboard/query/run`, queryObj)
    .pipe(map(response => {
      return response;
    }));
  }

  getQueryById(queryId: number): Observable<Query>{
    return this.http.get<Query>(`${environment.root}/dashboard/query/${queryId}`);
  }

  getQueryByTitle(queryTitle: string): Observable<Query>{
    return this.http.get<Query>(`${environment.root}/dashboard/query/title/${queryTitle}`);
  }

  saveQuery(query: string, title: string) {
    return this.http.post(`${environment.root}/dashboard/query`, {query, title});
  }

  updateQuery(queryId: number, query: string, title: string) {
    return this.http.put(`${environment.root}/dashboard/query/${queryId}`, {query, title});
  }

  getDataset(queryId: number, reportId: number, pivot?: string, filters?, queryConfig?) {
    const body = {};
    if (filters) {
      const keys = Object.keys(filters);
      keys.forEach(val => {
        if(filters[val].length === 0) {
          delete filters[val];
        }
      });
      body['filters'] = filters
    }

    queryConfig ? body['queryConfig'] = {...queryConfig}: '';
    
    if(body['queryConfig'] && body['queryConfig']['labels']) {
      delete body['queryConfig']['labels']
    }
    return this.http.post(`${environment.root}/dashboard/query/run/${queryId}?reportId=${reportId}${pivot ? `&pivot=${pivot}` : ''}`,
      body)
  }

  deleteQuery(queryId: number) {
    return this.http.delete(`${environment.root}/dashboard/query/${queryId}`);
  }

  getSavedQueries() {
    return this.http.get(`${environment.root}/dashboard/query`);
  }

  getTables(): Observable<QueryTable[]> {
    return this.http.get<QueryTable[]>(`${environment.root}/dashboard/query/tables`)
    .pipe(
      map((response: QueryTable[]) => {
        return Utils.orderArrayASC(response, 'id')
      })
    );
  }

  getDashboardPages() {
    return this.http.get(`${environment.root}/dashboard/page`);
  }

  saveDashboardPage(dashboardCrud: DashboardPageModel) {
    return this.http.post(`${environment.root}/dashboard/page`, dashboardCrud);
  }

  atualizaDashboardPage(dashboardCrud: DashboardPageModel) {
    return this.http.put(`${environment.root}/dashboard/page/${dashboardCrud.id}`, dashboardCrud);
  }

  deletePage(pageId: number) {
    return this.http.delete(`${environment.root}/dashboard/page/${pageId}`);
  }

  exportExcel(reportId: number, queryId: number, filters, query_config: {}) {
    var queryConfig = {...query_config};
    if(queryConfig['labels']) {
      delete queryConfig['labels'];
    }
    return this.http.post(`${environment.root}/dashboard/export/${queryId}?report=${reportId}`, {filters, queryConfig}, {
      responseType: 'arraybuffer'});
  }
}
