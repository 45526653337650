<div
  (mouseover)="showExportBtn = true" 
  (mouseout)="showExportBtn = false"
  [style.height]="height"
  [style.width]="width"
  [style.display]="'inline-table'"
  [style]="tableStyle">
  <div class="controls" [hidden]="!showExportBtn" *ngIf="!dataSetError">
    <button type="button" pButton icon="pi pi-download" label="" (click)="menu.toggle($event)" [pTooltip]="'Baixar dados'"></button>
    <p-menu #menu [popup]="true" [model]="downloadOptions"></p-menu>
  </div>
  <div 
    [style.height]="'100%'"
    [style.width]="'100%'">
    <h3 [id]="componentId+'-title'" *ngIf="title" [style]="titleStyle">{{ title }}</h3>
    <ag-grid-angular
      #grid
      [id]="componentId"
      [style]="gridStyle"
      class="ag-theme-alpine"
      [rowData]="rowData"
      [columnDefs]="columns"
      [localeText]="localeText"
      [gridOptions]="gridOptions"
      [modules]="modules"
      [pivotMode]="pivotMode"
      [processSecondaryColDef]="processSecondaryColDef"
      [processSecondaryColGroupDef]="processSecondaryColGroupDef"
      [groupSuppressAutoColumn]="true"
      [enableBrowserTooltips]="true"
      [popupParent]="popupParent"
      [groupIncludeTotalFooter]="hasTotalFooter"
      [columnTypes]="columnTypes"
      [rowBuffer]="10"
      [enableRangeSelection]="true"
      (sortChanged)="onSortChanged($event)"
    >
    </ag-grid-angular>
  </div>
  <v360-error *ngIf="dataSetError" (reload)="reloadDataset()"></v360-error>
</div>
