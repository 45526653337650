import {
  Component,
  OnInit,
  OnDestroy,
  ViewChild,
  HostListener,
  ViewContainerRef,
  EventEmitter,
} from "@angular/core";
import { Router, ActivatedRoute } from "@angular/router";
import { UserService } from "@services/user.service";
import { ReportsService } from "@services/reports.service";
import {
  Title,
  SafeResourceUrl,
  DomSanitizer,
} from "@angular/platform-browser";
import { forkJoin, Observable, Subject, Subscription } from "rxjs";
import { take, takeUntil } from "rxjs/operators";
import { FilterService } from "@services/filter.service";

import { FilterServiceAkita } from "@state/filter/filter.service";
import {
  createFilter,
  Filter as FilterAkita,
} from "@state/filter/filter.model";

import { Filter } from "@models/filter";
import { MatDialog } from "@angular/material/dialog";
import { ReportPage } from "@models/report-page";
import { ReportPageTree } from "@models/report-page-tree";
import { ReportFolder } from "@models/report-folder";
import { PageIndicator } from "@models/page-indicator";
import { MatIconRegistry } from "@angular/material/icon";
import { LoadingService } from "@services/loading.service";
import { ToastrService } from "ngx-toastr";
import { FavoriteFilter } from "@models/favorite-filter";
import { DeleteFavoriteFilterComponent } from "src/app/components/delete-favorite-filter/delete-favorite-filter.component";
import { MatTreeFlatDataSource } from "@angular/material/tree";
import { HttpErrorResponse } from "@angular/common/http";
import { DashboardConstructorService } from "@services/dashboard-constructor.service";
import { Utils } from "src/app/utils";
import { FilterInstancesService } from "src/app/components/filter-with-actions/filter-instances.service";
import sandboxshot from "sandboxshot";
import { environment } from "src/environments/environment";
import { Report } from "@models/report";
import { ChangeReportService } from "@services/change-report.service";
import { FilterQuery } from "@models/filters-query";
import { Filter as FilterModel } from "@state/filter/filter.model";
import * as _ from "lodash";
import { PageParamServiceAkita } from "@state/page/param/page-param.service";
import Cookies from "js-cookie";

@Component({
  selector: "v360-report",
  templateUrl: "./report.component.html",
  styleUrls: ["./report.component.scss"],
})
export class ReportComponent implements OnInit, OnDestroy {
  protected _onDestroy = new Subject<void>();

  reportPageTreeControl = ReportPageTree.getTreeControl();
  reportPageTreeDataSource = new MatTreeFlatDataSource(
    this.reportPageTreeControl,
    ReportPageTree.getTreeFlatten()
  );

  reports = [];
  reportPageSidenavOpened = false;
  opened = false;

  page: ReportPage;
  navbarTitle = "";
  pageList: ReportFolder[];
  reportURL: SafeResourceUrl =
    this.sanitizer.bypassSecurityTrustResourceUrl("");
  reportTitle = "";
  pageTitle = "";
  pageTitleTooltip = "";
  fileName = "";

  @ViewChild("tree") tree;
  pageListModalOpened = false;
  filterListModalOpened = false;
  isFullscreen = false;

  selectedFiltersTagExpandable = false;
  selectedFiltersTagCollapsed = false;
  appliedFiltersTagExpandable = false;
  appliedFiltersTagCollapsed = false;
  filtersTabIndex = 1;

  allFiltersList: Filter[];
  selectedFilter: Filter;
  selectedFilterValues: FilterModel[];
  appliedFilterValues: FilterModel[];
  fieldFilterSelected;
  state;
  reportId: number;
  pageId: number;
  indicator: number = 10;
  showIndicator = false;
  showTableLoading = true;
  defaultFilterLoaded = false;

  reportUpdateTime: string;
  favoriteFilters: FavoriteFilter[] = [];
  deletingFavoriteFilter = false;

  favoriteFilterLoaded = false;
  selectedFavoriteFilter: FavoriteFilter;

  currentSubscriptionToGetIndicators: Subscription;
  currentSubscriptionToGetFilterValues: Subscription;

  currentSubscriptionToGetReportPages: Subscription;

  loadPageTransaction;
  filterTransaction;
  pageTransaction;
  lookerLoadingTransaction;

  showSaveFilter = false;
  selectedReport;
  showSample;

  filterFieldOpenedCount = 0;
  closeFilterField = false;
  isIqviaPanel = false;

  // New dashboard
  @ViewChild("body", { read: ViewContainerRef }) body: ViewContainerRef;
  pageComponents;
  filterLoadedEvent = new EventEmitter();
  openOverlay = new EventEmitter();
  canDeleteChip = true;

  temFiltroObrigatorio = false;
  filtroObrigatorio;
  filtroObrigatorioKeys = [];
  filtroObrigatorioObjectList = [];

  @ViewChild("mandatoryAlert") mandatoryAlertOverlay;
  isOverlayOpened = false;
  mandatoryText = "";
  mandatoryOverflowInterval;

  toastOptions = {
    warning: (text) => {
      this.toastr.warning(text);
    },
    success: (text) => {
      this.toastr.success(text);
    },
    info: (text) => {
      this.toastr.info(text);
    },
    error: (text) => {
      this.toastr.error(text);
    },
  };
  hasVarejo360Watermark: boolean;
  allReportsForCurrentCategory: Report[];
  category: string;

  appliedFilters$: Observable<FilterAkita[]>;
  pageParams: any;
  allPageParamsAreInitialized: boolean;
  queryConfig: any;
  defaultPageParams: { [key: string]: string };
  pageParamsByHash: { [key: string]: string };
  hashFilter: string;
  hashPageParams: string;
  isPageLoading: boolean;
  pageParamsLabels;
  indicatorError: boolean = false;

  constructor(
    private reportsService: ReportsService,
    public userService: UserService,
    private activatedRoute: ActivatedRoute,
    private titleService: Title,
    private router: Router,
    private sanitizer: DomSanitizer,
    private filterService: FilterService,
    private dialog: MatDialog,
    private iconRegistry: MatIconRegistry,
    private loadingService: LoadingService,
    private toastr: ToastrService,
    private dashboardConstructorService: DashboardConstructorService,
    private filterInstancesService: FilterInstancesService,
    private changeReportService: ChangeReportService,
    private filterServiceAkita: FilterServiceAkita,
    private pageParamServiceAkita: PageParamServiceAkita
  ) {
    this.isIqviaPanel = environment.name == "iqvia";
    if (!this.isIqviaPanel) {
      if (!(window as any).Beamer?.initialized) {
        (window as any).Beamer?.init();
      }
    }
    this.hasVarejo360Watermark = !this.isIqviaPanel;

    this.iconRegistry.addSvgIcon(
      "bx-loader",
      sanitizer.bypassSecurityTrustResourceUrl("assets/icons/bx-loader.svg")
    );
    this.state = this.router.getCurrentNavigation().extras.state?.data;
  }

  @HostListener("document:fullscreenchange", ["$event"])
  fullscreenListener() {
    this.isFullscreen = !this.isFullscreen;
    if (this.filtroObrigatorioObjectList.length > 0) {
      this.showMandatoryAlert(this.filtroObrigatorioObjectList[0].filterName);
    }
  }

  @HostListener("window:popstate", ["$event"])
  onPopState(event) {
    this.filterInstancesService.clearInstances();
    if (Object.keys(this.activatedRoute.snapshot.queryParams).length > 0) {
      window.location.reload();
    }
  }

  @HostListener("document:keydown.escape", ["$event"]) onKeydownHandler(
    event: KeyboardEvent
  ) {
    if (this.opened) {
      this.opened = false;
    } else if (this.showIndicator) {
      this.showIndicator = false;
    } else if (this.showSaveFilter) {
      this.showSaveFilter = false;
    }
  }

  ngOnInit(): void {
    this.userService.getUserInfo().subscribe();
    this.filterService.loadingValues.valueChanges.subscribe((val) => {
      if (val) {
        this.canDeleteChip = false;
      } else {
        this.canDeleteChip = true;
      }
    });
    this.defaultFilterLoaded = false;
    this.loadReport();
    this.selectedFilterListener();

    const queryParams = this.activatedRoute.snapshot.queryParams;
    if (queryParams?.hash_filter) {
      this.filterService.filterHash = queryParams?.hash_filter;
    }

    this.changeReportService
      .getChangeReport()
      .pipe(takeUntil(this._onDestroy))
      .subscribe((open) => {
        if (!(!this.changeReportService.isOpenedByBreadcrumb && !open)) {
          this.opened = open;
        }
      });

    this.pageParamServiceAkita
      .getLoadingState()
      .pipe(takeUntil(this._onDestroy))
      .subscribe((isPageLoading) => (this.isPageLoading = isPageLoading));

    this.appliedFilters$ = this.filterServiceAkita.getAppliedFilters();

    this.pageParamServiceAkita
      .getPageParamUIState()
      .pipe(takeUntil(this._onDestroy))
      .subscribe((allPageParamsAreInitialized) => {
        this.allPageParamsAreInitialized = allPageParamsAreInitialized;
      });

    this.pageParamServiceAkita
      .getAll()
      .pipe(takeUntil(this._onDestroy))
      .subscribe((appliedPageParams) => {
        if (
          !this.isPageLoading &&
          this.allPageParamsAreInitialized &&
          this.defaultPageParams
        ) {
          let appliedPageParamsObj = Object.assign(
            {},
            ...appliedPageParams.map((item) => ({ [item.param]: item.value }))
          );
          this.queryConfig = _.merge(
            _.cloneDeep(this.defaultPageParams),
            appliedPageParamsObj,
            { labels: this.pageParamsLabels }
          );
          this.getIndicator();
          this.sendFilterAndPageParamsToApi();
          this.formatarPageTitleTooltip(this.pageTitleTooltip);
        }
      });
  }

  @HostListener("document:copiedToClipboard")
  showToastWhenScreenshotsCopiedToClipboard() {
    this.toastr.success("Screenshot copiado com sucesso!");
  }

  @HostListener("document:saveLocally")
  showToastWhenScreenshotsSavedLocally() {
    this.toastr.success("Screenshot salvo com sucesso!");
  }

  adjustInkBarPrimeNg() {
    let inkBar = document.getElementsByClassName(
      "p-tabview-ink-bar"
    ) as HTMLCollectionOf<HTMLElement>;
    let inkBarLeft = this.filtersTabIndex * 96;
    if (inkBar[0].style.left == "") {
      inkBar[0].style.left = "96px";
    } else {
      inkBar[0].style.left = `${inkBarLeft}px`;
    }
  }
  // Aqui é carregado tudo na tela. Há um listener na mudança de url com o id
  async loadReport() {
    this.activatedRoute.queryParams
      .pipe(takeUntil(this._onDestroy))
      .subscribe((query) => {
        this.mandatoryAlertOverlay?.hide();
        if (Object.keys(query).length === 0 && this.filterService.filterHash) {
          this.filterService.filterHash = null;
          this.loadingService.showLoading();
          this.getReportPages();
          return;
        }
        return;
      });
    this.activatedRoute.params
      .pipe(takeUntil(this._onDestroy))
      .subscribe(async (params) => {
        this.mandatoryAlertOverlay?.hide();
        this.filtersTabIndex = 1;
        this.loadingService.showLoading();
        this.reportsService.getUpdateTime().subscribe((reponse: any) => {
          this.reportUpdateTime = reponse.updated_date;
        });
        this.reportId = params.reportId;
        this.pageId = params.pageId;

        this.selectedReport = await this.reportsService.getSelectedReport(
          this.reportId
        );
        if (_.isEmpty(this.selectedReport)) {
          this.toastr.error(
            "Você não possui acesso a este relatório, entre em contato com nosso suporte."
          );
          this.router.navigate(["reports"]);
        }

        this.allReportsForCurrentCategory = this.selectedReport;

        let queryParams: { [key: string]: string } = _.cloneDeep(
          this.activatedRoute.snapshot.queryParams
        );
        if (queryParams?.filter) {
          queryParams["hash_filter"] = queryParams.filter;
        }
        this.getReportPages(queryParams);
      });
  }
  openFilter(filter, event) {
    if (this.selectedReport?.demo || this.selectedReport?.wip) {
      return;
    }
    this.mandatoryAlertOverlay.hide();
    let filterName = filter.filterName.replace("!", "");
    let isExceto: boolean = false;

    let filtersArr = {};
    for (let index in this.filterService.dashboardFilter) {
      this.filterService.dashboardFilter[index].forEach((filter) => {
        if (!filtersArr[index]) {
          filtersArr[index] = [];
        }
        filtersArr[index].push(`${filter.name}`);
      });
    }
    const filterInstance = this.filterInstancesService
      .get()
      .filter((instance) => {
        return instance.filter.filterName == filterName;
      })[0];
    if (filterInstance) {
      this.loadFilterValuesChips(
        { fields: [filterName], filters: filtersArr },
        filterName,
        filterInstance
      );
      Object.keys(filtersArr).forEach((filterNameFromFiltersArr) => {
        if (
          filterNameFromFiltersArr.replace("!", "") == filterName &&
          filterNameFromFiltersArr != filterName
        ) {
          isExceto = true;
        }
      });
      filterInstance.open(event, isExceto);
    }
  }

  isScreenshotOpened(): boolean {
    return sandboxshot.isOpened();
  }

  getReportPages(queryParams?: { [key: string]: string }) {
    if (!this.reportId) {
      return;
    }
    this.reportsService
      .getReportPages(
        this.reportId,
        this.pageId,
        queryParams?.hash_filter,
        queryParams?.hash_page_params
      )
      .subscribe(
        (response) => {
          this.state = response.default_filters;
          this.defaultPageParams = response.default_page_params;
          this.pageParamsByHash = response.page_params_by_hash || {};
          this.createInitialStructure(this.pageId);
          this.favoriteFilters = this.filterService.favoriteFilters.value;
        },
        (error) => {
          this.toastr.error(error.error.msg);
          this.router.navigate(["reports"]);
        }
      );
  }

  async createInitialStructure(pageId) {
    this.loadFiltersStructure(this.reportId, this.state);
    this.page = this.reportsService.getLocalPageById(
      this.reportsService.getLocalReportFolders(),
      pageId
    );
    this.category = this.selectedReport?.subtitle;
    this.fileName = this.page?.title;
    this.reportTitle = (
      await this.reportsService.getSelectedReport(this.reportId)
    ).title;
    this.titleService.setTitle(
      `${this.isIqviaPanel ? "" : "V360 - "}${
        this.page?.title || "Área do cliente"
      }`
    );
    this.navbarTitle = `${this.userService.getLocalUser().company.name} - ${
      this.page?.title
    }`;
  }

  loadFiltersStructure(reportId: number, defaultFilters) {
    this.filterService
      .getFilterList(reportId)
      .pipe(takeUntil(this._onDestroy))
      .subscribe(
        (response: Filter[]) => {
          this.allFiltersList = response;
          if (!this.defaultFilterLoaded) {
            this.loadDefaultFilters(defaultFilters);
          } else {
            this.loadDashboardReport(this.reportId, this.pageId);
          }
          [this.selectedFilter] = this.allFiltersList;
        },
        (error) => {
          this.toastr.error(
            error.msg ||
              "Houve um erro ao buscar as informações da estrutura dos filtros."
          );
        }
      );
  }

  openFilterModal() {
    if (this.filtroObrigatorioObjectList.length > 0) {
      return;
    }
    this.filterFieldOpenedCount = 0;
    this.filterListModalOpened = true;
    if (this.filtersTabIndex === 0) {
      this.selectedFilter = {
        name: "Favoritos",
        filters: [],
        icon: "",
        identity: "",
      };
      return;
    }
    const chipWrapper = document.getElementsByClassName(
      "mat-chip-list-wrapper"
    ) as any;

    setTimeout(() => {
      const listHeight = chipWrapper[0].offsetHeight;
      if (listHeight > 50) {
        this.selectedFiltersTagExpandable = true;
      } else {
        this.selectedFiltersTagExpandable = false;
      }
    }, 200);

    this.selectedFilter = this.allFiltersList.find(
      (filter, index) => index === this.filtersTabIndex - 1
    );
    this.loadFilterValues(
      this.createFilterQuery(
        this.selectedFilter,
        this.filterService.dashboardFilter as { [key: string]: FilterAkita[] }
      )
    );
    this.openOverlay.emit(true);
  }

  createFilterQueryForHiddenFields() {
    let selectedValues = this.filterService.dashboardFilter;
    let fields = [
      ...Object.keys(selectedValues).map((key) => key.replace("!", "")),
      ...this.filtroObrigatorioKeys.map((item) => Object.keys(item)[0]),
    ];
    const filterQuery = {
      fields: fields,
      filters: {},
    };
    if (Object.keys(selectedValues).length > 0) {
      for (const key in selectedValues) {
        if (selectedValues[key].length > 0) {
          filterQuery.filters =
            this.filterService.formatFilters(selectedValues);
        }
      }
    }
    return filterQuery;
  }

  createFilterQuery(
    filter: Filter,
    selectedValues: { [key: string]: FilterAkita[] },
    selectedField?
  ): FilterQuery {
    let fields;
    if (filter.filters.length > 1) {
      fields = filter.filters
        .map((values) => {
          if (
            !selectedField ||
            selectedField !== values.filterName ||
            (selectedValues[selectedField] &&
              selectedValues[selectedField].length === 0)
          ) {
            return values.filterName;
          }
        })
        .filter((val) => val);
    } else {
      fields = [filter.filters[0].filterName];
    }
    const filterQuery = {
      fields: fields,
      filters: {},
    };
    if (Object.keys(selectedValues).length > 0) {
      for (const key in selectedValues) {
        if (selectedValues[key].length > 0) {
          filterQuery.filters =
            this.filterService.formatFilters(selectedValues);
        }
      }
    }
    return filterQuery;
  }

  async loadInitialFilterValues(filterQuery: FilterQuery) {
    try {
      let response = await this.filterService
        .getFilterValues(this.reportId, filterQuery)
        .toPromise();
      const mandatoryFilterKey = this.filtroObrigatorioKeys?.map(
        (item) => Object.keys(item)[0]
      );
      const appliedFiltersCategory = [
        ...new Set(this.appliedFilterValues?.map((item) => item.filterName)),
      ];
      this.allFiltersList.forEach((filter) => {
        filter.filters = filter.filters.map((filterItem) => {
          if (mandatoryFilterKey.includes(filterItem.filterName)) {
            const mandatoryFilter = this.filtroObrigatorioKeys.filter(
              (item) => Object.keys(item)[0] === filterItem.filterName
            )[0];
            filterItem.unique = mandatoryFilter[filterItem.filterName]?.unique;
            filterItem.isMandatory = true;
            const isFiltroObrigatiorio = !appliedFiltersCategory.includes(
              filterItem.filterName
            );
            const isNewValue =
              this.filtroObrigatorioObjectList.filter(
                (item) => item.filterName === filterItem.filterName
              ).length === 0;
            if (isFiltroObrigatiorio && isNewValue) {
              this.filtroObrigatorioObjectList.push({
                filterName: filterItem.filterName,
                name: filterItem.filterName,
                icon: filter.icon,
              });
            }
          }
          if (response[filterItem.filterName]) {
            filterItem.filterItems = response[filterItem.filterName];
          }
          return filterItem;
        });
      });
      this.filterService.emitLoadAllItems(this.allFiltersList);
    } catch (error) {
      console.error(error);
      this.toastr.error(
        "Houve um erro ao atualizar os valores dos filtros, tente novamente. Caso o problema persista, contate o suporte"
      );
    }
  }

  loadFilterValues(filterQuery: FilterQuery) {
    if (this.currentSubscriptionToGetFilterValues) {
      this.currentSubscriptionToGetFilterValues.unsubscribe();
    }
    this.currentSubscriptionToGetFilterValues = this.filterService
      .getFilterValues(this.reportId, filterQuery)
      .pipe(takeUntil(this._onDestroy))
      .subscribe(
        (response) => {
          this.selectedFilter.filters = this.selectedFilter.filters.map(
            (filter) => {
              if (response[filter.filterName]) {
                filter.filterItems = response[filter.filterName];
              }
              return filter;
            }
          );
        },
        (err) => {
          console.error(err);
          this.toastr.error(
            "Houve um erro ao atualizar os valores dos filtros, tente novamente. Caso o problema persista, contate o suporte"
          );
        }
      );
  }
  loadFilterValuesChips(filterQuery: FilterQuery, filterName, filterInstance) {
    if (this.currentSubscriptionToGetFilterValues) {
      this.currentSubscriptionToGetFilterValues.unsubscribe();
    }
    this.currentSubscriptionToGetFilterValues = this.filterService
      .getFilterValues(this.reportId, filterQuery)
      .pipe(takeUntil(this._onDestroy))
      .subscribe(
        (response) => {
          let filterItemsForThisFilter = response[filterName]
            ? response[filterName]
            : [];
          filterInstance.loadFilterItems(filterItemsForThisFilter);
        },
        (err) => {
          console.error(err);
          this.toastr.error(
            "Houve um erro ao atualizar os valores dos filtros, tente novamente. Caso o problema persista, contate o suporte"
          );
        }
      );
  }

  selectedFilterListener() {
    const that = this;
    this.filterService.selectedFilter.valueChanges
      .pipe(takeUntil(this._onDestroy))
      .subscribe((selectedFilterValues) => {
        this.selectedFilterValues = selectedFilterValues
          ? [...selectedFilterValues]
          : [];
        Object.keys(this.filterService.dashboardFilter).forEach(
          (dashboardFilterKey) => {
            if (Utils.hasPrefix(dashboardFilterKey, "!")) {
              let keyWithoutPrefix =
                Utils.removeNotPrefix(dashboardFilterKey).string;
              this.selectedFilterValues.forEach((filter) => {
                if (keyWithoutPrefix == filter.filterName) {
                  if (!filter["isExceto"]) {
                    filter["isExceto"] = true;
                  }
                }
              });
            }
          }
        );
        if (that.selectedFilter && this.selectedFilter.name !== "Favoritos") {
          this.loadFilterValues(
            this.createFilterQuery(
              that.selectedFilter,
              this.filterService.dashboardFilter as {
                [key: string]: FilterAkita[];
              },
              this.fieldFilterSelected
            )
          );
        }
        const chipWrapper = document.getElementsByClassName(
          "mat-chip-list-wrapper"
        ) as any;

        setTimeout(() => {
          const listHeight = chipWrapper[0].offsetHeight;
          if (listHeight > 50) {
            this.selectedFiltersTagExpandable = true;
          } else {
            this.selectedFiltersTagExpandable = false;
          }
        }, 200);
      });
  }

  appliedFilterListener() {
    this.appliedFilters$
      .pipe(takeUntil(this._onDestroy))
      .subscribe((appliedFilters) => {
        this.appliedFilterValues = appliedFilters;
        if (this.defaultFilterLoaded && !this.favoriteFilterLoaded) {
          this.sendFilterAndPageParamsToApi();
        } else if (this.favoriteFilterLoaded) {
          this.sendFilterToDashboard();
          this.router.navigate(
            [`/reports/${this.reportId}/page/${this.pageId}`],
            { queryParams: { hash_filter: this.filterService.filterHash } }
          );
          this.favoriteFilterLoaded = false;
        }
        if (this.showSample) {
          this.getIndicator();
        }
        if (
          !this.appliedFilterValues ||
          this.appliedFilterValues.length === 0
        ) {
          this.appliedFiltersTagExpandable = false;
        } else {
          const htmlItems = document.getElementsByClassName(
            "mat-chip-list-wrapper"
          ) as HTMLCollectionOf<HTMLElement>;
          setTimeout(() => {
            for (let item in htmlItems) {
              if (htmlItems[item].offsetHeight) {
                var chipListHeight = htmlItems[item].offsetHeight;
              }
            }
            if (chipListHeight > 50) {
              this.appliedFiltersTagExpandable = true;
            } else {
              this.appliedFiltersTagExpandable = false;
            }
          }, 200);
        }
        this.defaultFilterLoaded = true;
      });
  }

  sendFilterAndPageParamsToApi() {
    const appliedFilters: { filters; config? } = {
      filters: { ...this.filterService.dashboardFilter },
    };
    for (const key in appliedFilters.filters) {
      appliedFilters.filters[key] = this.filterService.extractFilter(
        appliedFilters.filters[key]
      )[key];
      if (
        !appliedFilters.filters[key] ||
        appliedFilters.filters[key].length === 0
      ) {
        delete appliedFilters.filters[key];
      }
    }
    let hashFilterObs = this.filterService.sendFiltersToCreateHash(
      this.reportId,
      appliedFilters
    );
    let hashPageParamsObs = this.reportsService.sendPageParamsToCreateHash(
      this.reportId,
      this.pageId,
      this.queryConfig
    );

    forkJoin([hashFilterObs, hashPageParamsObs])
      .pipe(takeUntil(this._onDestroy))
      .subscribe(
        (hashs) => {
          this.hashFilter = hashs[0].hash;
          this.hashPageParams = hashs[1];
          this.router.navigate(
            [`/reports/${this.reportId}/page/${this.pageId}`],
            {
              queryParams: {
                hash_filter: hashs[0].hash,
                hash_page_params: hashs[1],
              },
            }
          );
          this.sendFilterToDashboard();
        },
        (error) => {
          console.error(error);
          this.toastr.error(
            error.msg ||
              "Houve um erro ao enviar as informações de filtro/página."
          );
        }
      );
  }

  formatIndicatorConfigs(indicatorConfigs: string[], queryConfig): string[] {
    if (!indicatorConfigs) {
      return [];
    }
    return indicatorConfigs.map((value: string) => {
      if (value.includes("<") && value.includes(">")) {
        return this.queryConfig[value.replace("<", "").replace(">", "")];
      }
      return value;
    });
  }

  getIndicator() {
    const selectedPage = this.pageComponents;
    // this.reportsService.getLocalPageById(
    //   this.reportsService.getLocalReportFolders(),
    //   this.pageId.toString()
    // );
    if (!this.showSample) {
      return;
    }
    const indicatorFilters: { filters; config? } = {
      filters: { ...this.filterService.dashboardFilter },
    };
    for (const key in indicatorFilters.filters) {
      indicatorFilters.filters[key] = this.filterService.extractFilter(
        indicatorFilters.filters[key]
      )[key];
      if (
        !indicatorFilters.filters[key] ||
        indicatorFilters.filters[key].length === 0
      ) {
        delete indicatorFilters.filters[key];
      }
    }

    if (selectedPage?.config) {
      indicatorFilters.config = {
        to_show: this.formatIndicatorConfigs(
          selectedPage?.config["to_show"],
          this.queryConfig
        ),
      };
    }

    this.showTableLoading = true;
    if (this.currentSubscriptionToGetIndicators) {
      this.currentSubscriptionToGetIndicators.unsubscribe();
    }
    this.currentSubscriptionToGetIndicators = this.reportsService
      .getPageIndicators(this.reportId, indicatorFilters)
      .pipe(takeUntil(this._onDestroy))
      .subscribe(
        (response: PageIndicator[]) => {
          this.showTableLoading = false;
          const semaforo = response.map((indicator) => indicator.semaforo);
          this.indicator = semaforo.includes("0")
            ? 0
            : semaforo.includes("1")
            ? 1
            : 2;
          this.currentSubscriptionToGetIndicators = null;
          this.indicatorError = false;
        },
        (error) => {
          this.indicatorError = true;
          this.showTableLoading = false;
          console.error(error);
        }
      );
  }

  sendFilterToDashboard() {
    const formatedFilters = this.filterService.formatFilters(
      this.filterService.dashboardFilter
    );
    this.body?.clear();
    for (const key in formatedFilters) {
      if (!formatedFilters[key]) {
        delete formatedFilters[key];
      }
    }
    if (
      this.pageComponents &&
      this.pageComponents["components"].length > 0 &&
      this.filtroObrigatorioObjectList.length === 0
    ) {
      for (const component of this.pageComponents["components"]) {
        this.dashboardConstructorService.checkComponent(
          component,
          this.body,
          this.reportId,
          formatedFilters,
          this.pageTitle,
          this.queryConfig,
          this.fileName
        );
      }
    }
    this.loadingService.closeLoading(true);
    this.filterListModalOpened = false;
  }

  loadDefaultFilters(filter: { [key: string]: string[] }) {
    const defaultFilters = this.filterService.extractDefaultFilters(filter);
    const filtersToApply = [];
    Object.entries(defaultFilters).forEach(([key, category]) => {
      category.forEach((filter) => {
        filtersToApply.push(
          createFilter(
            filter.category,
            filter.filterName,
            filter.icon,
            filter.placeholder,
            filter.name,
            filter.isMandatory,
            filter.unique,
            filter.isExceto
          )
        );
      });
    });

    this.filterService.addSelectedFilter(defaultFilters);
    this.filterService.applyFilters();
    this.appliedFilterListener();

    this.loadDashboardReport(this.reportId, this.pageId);
    this.filterLoadedEvent.emit();
  }

  removeWrongFilter(wrongFilterName: string, subCategory: string) {
    const wrongFilters = this.appliedFilterValues.map(
      (filter) => filter.name === wrongFilterName
    );
    this.filterService.removeBulkAppliedFilter({ [subCategory]: wrongFilters });
    this.filterService.removeBulkDashboardFilter({
      [subCategory]: wrongFilters,
    });
  }

  removerFiltroObrigatorio() {
    this.filterService.removeBulkAppliedFilter(this.filtroObrigatorio);
    this.filterService.removeBulkDashboardFilter(this.filtroObrigatorio);
    this.filtroObrigatorio = null;
  }

  changeReportPage(event: { pageId: number }) {
    if (
      this.activatedRoute.snapshot.params["pageId"] === event.pageId.toString()
    ) {
      this.opened = false;
    } else {
      this.filtroObrigatorioKeys = [];
      this.updateMandatoryChips();

      this.pageParamServiceAkita.setLoading(true);

      const baseLinkIfContainsHash = `/reports/${this.reportId}/page/${event.pageId}`;

      let url = this.router.url;

      if (url.includes("filter") && !url.includes("hash_filter")) {
        url = url.replace("filter", "hash_filter");
      }

      if (url.includes("hash_filter") && url.includes("hash_page_params")) {
        this.router.navigate([baseLinkIfContainsHash], {
          queryParams: {
            hash_filter:
              this.activatedRoute.snapshot.queryParamMap.get("hash_filter"),
            hash_page_params:
              this.activatedRoute.snapshot.queryParamMap.get(
                "hash_page_params"
              ),
          },
        });
      } else if (url.includes("hash_filter")) {
        this.router.navigate([baseLinkIfContainsHash], {
          queryParams: {
            hash_filter:
              this.activatedRoute.snapshot.queryParamMap.get("hash_filter"),
          },
        });
      } else if (url.includes("hash_page_params")) {
        this.router.navigate([baseLinkIfContainsHash], {
          queryParams: {
            hash_page_params:
              this.activatedRoute.snapshot.queryParamMap.get(
                "hash_page_params"
              ),
          },
        });
      } else {
        this.router.navigate([baseLinkIfContainsHash]);
      }

      this.opened = false;
    }
  }

  ngOnDestroy() {
    if (document.fullscreen) {
      document.exitFullscreen();
    }
    this.pageParamServiceAkita.reset();
    this.hashFilter = "";
    this.hashPageParams = "";
    this._onDestroy.next();
    this._onDestroy.complete();
    this.filterService.clearAllFilters();
    this.filterInstancesService.clearInstances();
    this.titleService.setTitle(
      `${this.isIqviaPanel ? "" : "V360 - "}Área do cliente`
    );
    this.loadingService.closeLoading();
  }

  filterTabChanged(event: number) {
    this.filtersTabIndex = event;
    if (this.filtersTabIndex === 0) {
      this.selectedFilter = {
        name: "Favoritos",
        filters: [],
        icon: "",
        identity: "",
      };
      return;
    }
    this.allFiltersList.forEach((filter, index) => {
      if (index === event - 1) {
        this.selectedFilter = filter;
      }
    });
    this.loadFilterValues(
      this.createFilterQuery(
        this.selectedFilter,
        this.filterService.dashboardFilter as { [key: string]: FilterAkita[] }
      )
    );
  }

  filterChanged(event: { [key: string]: FilterAkita[] }) {
    this.filtroObrigatorioObjectList = this.filtroObrigatorioObjectList.filter(
      (item) => {
        return item.filterName !== Object.keys(event)[0];
      }
    );
    if (this.filtroObrigatorioObjectList.length > 0) {
      this.showMandatoryAlert(this.filtroObrigatorioObjectList[0].name);
    }
    this.favoriteFilterLoaded = false;
    this.fieldFilterSelected = Object.keys(event)[0];
    this.filterService.addSelectedFilter(event);
  }

  removeSelectedFilters(filter?: FilterAkita) {
    if (this.selectedReport?.demo || this.selectedReport?.wip) {
      return;
    }
    this.favoriteFilterLoaded = false;
    this.fieldFilterSelected = filter?.filterName;
    if (!filter) {
      const except = this.filtroObrigatorioKeys?.map(
        (item) => Object.keys(item)[0]
      );
      this.filterService.removeAllSelectedFilters(except);
      this.selectedFiltersTagCollapsed = false;
      return;
    }
    this.filterService.removeSelectedFilter(filter);
  }

  cancelSelectedfilters() {
    this.filterService.cancelSelectedFilters();
    this.filterListModalOpened = false;
  }

  removeAppliedFilters(filter: FilterAkita) {
    if (this.selectedReport?.demo || this.selectedReport?.wip) {
      return;
    }
    this.fieldFilterSelected = filter.filterName;
    this.loadingService.showLoading();
    this.filterService.removeDashboardFilter(filter);
    this.filterService.removeAppliedFilter(filter);
    const htmlItems = document.getElementsByClassName(
      "mat-chip-list-wrapper"
    ) as HTMLCollectionOf<HTMLElement>;
    setTimeout(() => {
      for (let item in htmlItems) {
        if (htmlItems[item].offsetHeight) {
          var chipListHeight = htmlItems[item].offsetHeight;
        }
      }
      if (chipListHeight > 50) {
        this.appliedFiltersTagExpandable = true;
      } else {
        this.appliedFiltersTagExpandable = false;
      }
    }, 200);
  }

  applyFilters() {
    if (this.hasNotChangedFiltersOnSidebar()) {
      this.filterListModalOpened = false;
      return;
    }
    this.loadingService.showLoading();
    this.updateMandatoryChips();
    this.filterService.applyFilters();
  }

  hasNotChangedFiltersOnSidebar(): boolean {
    let reducedSelectedItemsObject = this.selectedFilterValues.map(
      (selectedItem) => {
        return {
          name: selectedItem.name,
          isExceto:
            selectedItem.isExceto !== undefined &&
            selectedItem.isExceto &&
            selectedItem.isExceto == true,
        };
      }
    );
    let reducedAppliedItemsObject = this.appliedFilterValues.map(
      (appliedItem) => {
        return { name: appliedItem.name, isExceto: appliedItem.isExceto };
      }
    );

    return _.isEqual(reducedAppliedItemsObject, reducedSelectedItemsObject);
  }

  updateMandatoryChips() {
    if (this.filtroObrigatorioKeys.length > 0) {
      const flattenedFiltroObrigatorioArray = this.filtroObrigatorioKeys.map(
        (keys) => {
          return Object.keys(keys)[0];
        }
      );
      const selectedFilterArray = this.filterService.selectedFilter.value.map(
        (selectedFilter) => {
          return {
            ...selectedFilter,
            isMandatory: flattenedFiltroObrigatorioArray.includes(
              selectedFilter.filterName
            ),
          };
        }
      );
      this.filterService.selectedFilter.setValue(selectedFilterArray, {
        emitEvent: false,
      });
    }
  }

  collapseSelectedFiltersTag() {
    this.selectedFiltersTagCollapsed = !this.selectedFiltersTagCollapsed;
    const htmlItems = document.getElementsByClassName("selected-filters-list");
    const chipList = htmlItems[0];
    if (!chipList.classList.contains("collapsed")) {
      chipList.classList.add("collapsed");
      return;
    }
    chipList.classList.remove("collapsed");
  }

  openScreenshotArea() {
    sandboxshot.openBox(this.hasVarejo360Watermark);
  }

  collapseAppliedFiltersTag() {
    this.appliedFiltersTagCollapsed = !this.appliedFiltersTagCollapsed;
    const htmlItems = document.getElementsByClassName("applied-filters");
    const chipList = htmlItems[0];
    if (!chipList.classList.contains("collapsed")) {
      chipList.classList.add("collapsed");
      return;
    }
    chipList.classList.remove("collapsed");
  }

  loadPageList() {
    this.reports = [];
    this.reportsService
      .getReportPages(
        this.selectedReport.id,
        this.selectedReport.query_config?.filter
      )
      .subscribe(
        (response) => {
          if (this.selectedReport.id == this.reportId) {
            this.pageList = response.pages;
            this.reportPageTreeDataSource.data =
              ReportPageTree.convertFromFolder(this.pageList);
            this.reportPageTreeControl.expandAll();
          }
          let reportObject = JSON.parse(
            `{"selected": ${
              this.selectedReport.id == this.reportId
            },"reportId": ${JSON.stringify(
              this.selectedReport.id
            )},"pages": ${JSON.stringify(
              response.pages
            )}, "title": ${JSON.stringify(this.selectedReport.subtitle)}}`
          );
          this.reports = [...this.reports, reportObject];
        },
        (error) => {
          this.toastr.error(error.error.msg);
          this.router.navigate(["reports"]);
        }
      );
  }

  replaceForSingleQuote(text: string) {
    if (!text) {
      return "";
    }
    return text.replace(/"/g, "'");
  }

  private extrairParametros(value: string): string[] {
    if (value) {
      const regexp = /<[\w]+>/g;
      if (value.match(regexp)) {
        return [...value.match(regexp)].map((val) =>
          val.replace("<", "").replace(">", "")
        );
      }
    }
  }

  extractDefaultValue(defaultValue: string, pageParams: any, items: any) {
    if (!defaultValue) {
      return "";
    }
    let hasValidValue = false;
    const defaultValueReplaced = defaultValue.replace(/<\w*>/g, (parameter) => {
      parameter = parameter.substring(1, parameter.length - 1);
      return pageParams[parameter] ?? "";
    });
    for (const item of items) {
      if (Object.values(item)[0] == defaultValueReplaced) {
        hasValidValue = true;
      }
    }
    return hasValidValue ? defaultValueReplaced : "";
  }

  async getPageParamValuesFromFilter(filterName: string) {
    const filterQuery = {
      fields: [filterName],
      filters: {},
    };
    return this.filterService
      .getFilterValues(this.reportId, filterQuery)
      .pipe(take(1));
  }

  formatarPageTitleTooltip(pagina: string): string {
    if (!pagina || !this.queryConfig) {
      return "";
    }
    var parametros = this.extrairParametros(pagina);
    var paginaFormatada = `${pagina}`;
    parametros?.forEach((parametro) => {
      if (this.queryConfig[parametro]) {
        paginaFormatada = paginaFormatada.replace(
          `<${parametro}>`,
          this.pageParamsLabels[this.queryConfig[parametro]]
        );
      }
    });
    return paginaFormatada;
  }

  wrapTextToSelectItem(
    parameter: string,
    response,
    pageParamValuesFromFilterObjsArr
  ) {
    const containParentheses =
      parameter.includes("(") && parameter.includes(")");
    parameter = parameter
      .replace("(", "")
      .replace(")", "")
      .replace("<", "")
      .replace(">", "");
    let items;
    if (response.config.page_params[parameter]?.values instanceof Array) {
      items = response.config.page_params[parameter]?.values;
    } else {
      items =
        pageParamValuesFromFilterObjsArr[
          response.config.page_params[parameter]?.values
        ];
    }
    let defaultValue =
      this.pageParamsByHash[parameter] ??
      this.extractDefaultValue(
        response.config.page_params[parameter]?.default_value,
        this.defaultPageParams,
        items
      );
    if (
      !_.isEmpty(defaultValue) &&
      this.queryConfig &&
      !this.queryConfig[parameter]
    ) {
      this.queryConfig[parameter] = defaultValue;
    }

    const paramSelector = `<v360-param-selector [mandatory]="${true}" [reportId]="${
      this.reportId
    }" [unique]="${
      response.config.page_params[parameter]?.multiple_selection ? false : true
    }" [default_value]="'${this.replaceForSingleQuote(
      defaultValue
    )}'" [items]="'${this.replaceForSingleQuote(
      JSON.stringify(items)
    )}'" [placeholder]="'${parameter}'"></v360-param-selector>`;

    const span = `<span class="page-params nav-item">${parameter}</span>`;
    return response.config?.page_params[parameter]
      ? containParentheses
        ? `(${paramSelector})`
        : paramSelector
      : containParentheses
      ? `(${span})`
      : span;
  }

  async loadDashboardReport(reportId: number, pageId: number) {
    this.reportsService
      .loadDashboard(reportId, pageId)
      .pipe(takeUntil(this._onDestroy))
      .subscribe(
        async (response: any) => {
          this.pageTitle = this.page?.title;
          if (response.config?.page_params) {
            this.pageParams = response.config?.page_params;
            let pageParamValuesFromFilterObjsArr = {};
            for (const pageParam in response.config.page_params) {
              if (
                !(
                  response.config.page_params[pageParam].values instanceof Array
                )
              ) {
                let pageParamValuesFromFilterObj = await (
                  await this.getPageParamValuesFromFilter(
                    response.config.page_params[pageParam].values
                  )
                ).toPromise();
                pageParamValuesFromFilterObjsArr[
                  response.config.page_params[pageParam].values
                ] = pageParamValuesFromFilterObj[
                  response.config.page_params[pageParam].values
                ].map((value) => {
                  return { [value]: value };
                });
              }
            }
            if (!this.queryConfig && this.defaultPageParams) {
              this.queryConfig = _.merge(
                _.cloneDeep(this.queryConfig),
                this.defaultPageParams,
                { labels: this.pageParamsLabels }
              );
            }
            this.pageParamsLabels = Object.keys(response.config.page_params)
              .map((key) => {
                var values = {};
                if (
                  typeof response.config.page_params[key].values === "string"
                ) {
                  values[key] = this.queryConfig ? this.queryConfig[key] : "";
                } else {
                  response.config.page_params[key].values.forEach((value) => {
                    values[value[Object.keys(value)[0]]] =
                      Object.keys(value)[0];
                  });
                }
                return values;
              })
              .reduce((previousValue, currentValue) => {
                return { ...previousValue, ...currentValue };
              });
            this.queryConfig = _.merge(_.cloneDeep(this.queryConfig), {
              labels: this.pageParamsLabels,
            });
            this.pageTitle = this.page?.title
              .split(" ")
              .map((item) => {
                if (item.match(/<\w*>/)) {
                  return this.wrapTextToSelectItem(
                    item,
                    response,
                    pageParamValuesFromFilterObjsArr
                  );
                }
                return `<span class="nav-item">${item}&nbsp;</span>`;
              })
              .join("");
            this.formatarPageTitleTooltip(this.pageTitleTooltip);
          }
          this.loadPageList();
          this.temFiltroObrigatorio = !!response.config?.filtroObrigatorio;
          if (this.filtroObrigatorio) {
            this.removerFiltroObrigatorio();
          }
          if (this.temFiltroObrigatorio) {
            this.filtroObrigatorioKeys = Object.keys(
              response.config.filtroObrigatorio
            ).map((key) => {
              return { [key]: response.config.filtroObrigatorio[key] };
            });
            // Verifica se o filtro aplicado é do tipo exceto para quando o filtro obrigatório é do tipo unico.
            const keys = this.filtroObrigatorioKeys.map((item) => {
              if (item[Object.keys(item)[0]]["unique"]) {
                let isExceto = false;
                let values = this.appliedFilterValues
                  ?.filter(
                    (filter) => filter.filterName === Object.keys(item)[0]
                  )
                  .map((filter) => {
                    isExceto = filter.isExceto ? true : false;
                    return filter.name;
                  });
                if (!values) {
                  values = [];
                }
                return {
                  key: Object.keys(item)[0],
                  values: values,
                  isExceto: isExceto,
                };
              }
            });
            const moreThanOneKey = keys
              .filter((item) => item.values.length > 1 && !item.isExceto)
              .map((item) => item.key);
            const isExcetoKey = keys
              .filter((item) => item.values.length > 0 && item.isExceto)
              .map((item) => item.key);
            var newValues =
              this.appliedFilterValues?.filter((item) => {
                return (
                  !moreThanOneKey.includes(item.filterName) &&
                  !isExcetoKey.includes(item.filterName)
                );
              }) || [];
            if (isExcetoKey.length > 0 || moreThanOneKey.length > 0) {
              this.appliedFilterValues
                .filter(
                  (item) =>
                    isExcetoKey.includes(item.filterName) ||
                    moreThanOneKey.includes(item.filterName)
                )
                .forEach((item) => {
                  this.filterService.removeDashboardFilter(item);
                });
              this.filterService.selectedFilter.patchValue(newValues);
              const filtersToApply = [];
              Object.entries(newValues).forEach(([key, filter]) => {
                filtersToApply.push(
                  createFilter(
                    filter.category,
                    filter.filterName,
                    filter.icon,
                    filter.placeholder,
                    filter.name,
                    filter.isMandatory,
                    filter.unique,
                    filter.isExceto
                  )
                );
              });

              this.filterServiceAkita.set(filtersToApply);
              this.filterService.applyFilters();
            }
          }
          this.filtroObrigatorioObjectList = [];
          await this.loadInitialFilterValues(
            this.createFilterQueryForHiddenFields()
          );
          if (
            response.config?.disable_sample !== null &&
            response.config?.disable_sample
          ) {
            this.showSample = false;
            this.indicator = 10;
          } else {
            this.showSample = true;
          }
          this.body?.clear();
          if (this.filtroObrigatorioObjectList.length > 0) {
            this.showMandatoryAlert(
              this.filtroObrigatorioObjectList[0].filterName
            );
          }
          this.pageComponents = response;

          if (
            this.allPageParamsAreInitialized &&
            this.filtroObrigatorioObjectList.length === 0 &&
            response.components &&
            response.components.length > 0
          ) {
            if (
              !_.isEmpty(this.pageParamsByHash) &&
              _.isEmpty(this.queryConfig)
            ) {
              this.queryConfig = _.merge(
                _.cloneDeep(this.queryConfig),
                this.pageParamsByHash,
                { labels: this.pageParamsLabels }
              );
            }
            for (const component of response.components) {
              this.dashboardConstructorService.checkComponent(
                component,
                this.body,
                reportId,
                this.filterService.formatFilters(
                  this.filterService.dashboardFilter
                ),
                this.pageTitle,
                this.queryConfig,
                this.fileName
              );
              this.loadingService.closeLoading(true);
              this.pageParamServiceAkita.setLoading(false);
            }
            const htmlItems = document.getElementsByClassName(
              "mat-chip-list-wrapper"
            ) as HTMLCollectionOf<HTMLElement>;
            setTimeout(() => {
              for (let item in htmlItems) {
                if (htmlItems[item].offsetHeight) {
                  var chipListHeight = htmlItems[item].offsetHeight;
                }
              }
              if (chipListHeight > 50) {
                this.appliedFiltersTagExpandable = true;
              } else {
                this.appliedFiltersTagExpandable = false;
              }
            }, 200);
          } else {
            this.loadingService.closeLoading(true);
            this.pageParamServiceAkita.setLoading(false);
          }
          if (this.showSample) {
            this.getIndicator();
          }
        },
        (response: HttpErrorResponse) => {
          this.loadingService.closeLoading();
          this.pageParamServiceAkita.setLoading(false);
          if (response.error.msg[0] === "{") {
            this.router.navigate([JSON.parse(response.error.msg).redirectTo]);
          } else {
            this.toastr.error(
              response?.error?.msg || "Houve um erro, tente novamente."
            );
            this.router.navigate(["reports"]);
          }
        }
      );
  }

  reloadDashboardReport() {
    this.loadDashboardReport(this.reportId, parseInt(this.page?.id));
  }

  toggleFullscreen() {
    const el = document.documentElement;
    if (this.isFullscreen) {
      document.exitFullscreen();
    } else {
      el.requestFullscreen()
        .then()
        .catch((err) => {
          console.error(err);
        });
    }
  }

  closeTable(e: Event) {
    this.showIndicator = false;
  }

  saveFavoriteFilter(event: { title: string }) {
    this.loadingService.showLoading();
    this.filterService
      .saveFilter(
        this.reportId,
        this.filterService.extractFilter(this.appliedFilterValues),
        event.title
      )
      .subscribe(
        (response) => {
          this.loadingService.closeLoading();
          this.favoriteFilters = this.filterService.favoriteFilters.value;
          this.filterService.filterHash = response.hash;
          this.router.navigate(
            [`/reports/${this.reportId}/page/${this.pageId}`],
            { queryParams: { hash_filter: response.hash } }
          );
          this.toastr.success("Filtro adicionado a lista de favoritos.");
        },
        (error) => {
          console.error(error);
          this.loadingService.closeLoading();
          this.toastr.error(
            "Houve um erro ao adicionar o filtro na lista de favoritos, tente novamente mais tarde."
          );
        }
      );
  }

  updateFavoriteFilter(event: { title: string }) {
    this.loadingService.showLoading();
    const filter = this.filterService.selectedFavoriteFilter
      .value as FavoriteFilter;
    filter.title = event.title;
    this.filterService.updateFavoriteFilter(filter).subscribe(
      (response) => {
        this.loadingService.closeLoading();
        this.toastr.success("Nome do filtro atualizado.");
      },
      (error) => {
        console.error(error);
        this.loadingService.closeLoading();
        this.toastr.error(
          "Houve um erro ao atualizar o nome o filtro na lista de favoritos, tente novamente mais tarde."
        );
      }
    );
  }

  deleteFavoriteFilter(filter: FavoriteFilter) {
    this.deletingFavoriteFilter = true;
    const removeFavoriteDialog = this.dialog.open(
      DeleteFavoriteFilterComponent,
      {
        data: filter,
      }
    );
    removeFavoriteDialog.afterClosed().subscribe((val) => {
      if (val.status) {
        const queryParams: { [key: string]: string } =
          this.activatedRoute.snapshot.queryParams;
        this.loadingService.showLoading();
        this.filterService.deleteFavoriteFilter(filter).subscribe(
          (response) => {
            this.loadingService.closeLoading();
            this.toastr.success("Filtro removido da lista de favoritos.");
            this.favoriteFilters = this.filterService.favoriteFilters.value;
            this.deletingFavoriteFilter = false;
          },
          (error) => {
            console.error(error);
            this.loadingService.closeLoading();
            this.toastr.error(
              "Houve um erro ao remover o filtro da lista de favoritos, tente novamente mais tarde."
            );
            this.deletingFavoriteFilter = false;
          }
        );
      }
    });
  }

  selectFavoriteFilter(filter: FavoriteFilter) {
    if (!this.deletingFavoriteFilter) {
      this.selectedFavoriteFilter = filter;
      this.loadingService.showLoading();
      this.filterService.getFavoriteFilter(filter.hash).subscribe(
        (response: { [key: string]: string[] }) => {
          this.filterService.removeAllSelectedFilters();
          this.loadingService.closeLoading();
          this.favoriteFilterLoaded = true;
          const newFilters = this.filterService.extractDefaultFilters(response);
          this.filterService.addSelectedFilter(newFilters);
          this.filterService.filterHash = filter.hash;
        },
        (error) => {
          console.error(error);
          this.loadingService.closeLoading();
          this.toastr.error(
            error.msg ||
              "Houve um erro ao buscar as informações do filtro favorito."
          );
        }
      );
    }
  }

  isFavorite() {
    const queryParams: { [key: string]: string } =
      this.activatedRoute.snapshot.queryParams;
    const favorite = this.favoriteFilters?.find(
      (favorite) => favorite.hash === queryParams?.hash_filter
    );
    this.filterService.selectedFavoriteFilter.setValue(favorite);
    return favorite;
  }

  filterFieldOpened(event) {
    if (event) {
      this.filterFieldOpenedCount++;
    } else {
      if (this.filterFieldOpenedCount > 0) {
        this.filterFieldOpenedCount--;
      }
    }
  }

  toastFromFilter(toast) {
    this.toastOptions[toast.type](toast.message);
    let toastHTML = document.getElementsByClassName(
      "toast-container"
    ) as HTMLCollectionOf<HTMLElement>;
    Object.assign(toastHTML[0].style, {
      zIndex: "99999",
    });
  }

  showDeleteChip(filter): boolean {
    const mandatoryFilter = this.filtroObrigatorioKeys?.filter(
      (item) => Object.keys(item)[0] === filter.filterName
    );
    if (mandatoryFilter?.length > 0) {
      if (mandatoryFilter[0][filter.filterName].unique) {
        return false;
      }
      const appliedFilterWithSameCategory = this.appliedFilterValues.filter(
        (item) => item.filterName === filter.filterName
      );
      if (appliedFilterWithSameCategory.length === 1) {
        return false;
      }
    }
    return true;
  }

  showMandatoryAlert(key: string) {
    const id = key;
    let chip;
    if (!this.mandatoryOverflowInterval) {
      this.mandatoryOverflowInterval = setInterval(() => {
        chip = document.getElementById(`${id}`);
        if (chip) {
          this.mandatoryText = id.replace("_", " ");
          chip.click();
          clearInterval(this.mandatoryOverflowInterval);
          this.mandatoryOverflowInterval = null;
        }
      }, 500);
    }
  }

  openMandatoryFilterAlert(filter, event: PointerEvent) {
    if (this.isOverlayOpened) {
      this.mandatoryAlertOverlay.hide();
      this.openFilter(filter, event);
    } else {
      this.mandatoryAlertOverlay.toggle(event);
    }
    this.isOverlayOpened = !this.isOverlayOpened;
  }
}
