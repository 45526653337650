import { Injectable } from '@angular/core';
import { CanLoad, Route, Router } from '@angular/router';
import { environment } from 'src/environments/environment';


@Injectable({
  providedIn: 'root'
})
export class MaintenanceGuard implements CanLoad {

  constructor(
    private router: Router
  ) {}

  canLoad(route: Route) {
    if (environment.name === 'maintenance') {
      this.router.navigateByUrl('/maintenance', {replaceUrl: true});
      return false;
    }
    return true;
  }
}