export const environment = {
  production: true,
  name: "iqvia",
  root: "https://api.varejo360.com",
  sendHeaders: false,
  s3ImageAddress:
    "https://storage.googleapis.com/v360-platform-dev.appspot.com",
  migrationUrl: "https://app.plataformaiqvia.com.br",
  migrationDomain: ".plataformaiqvia.com.br",
  tokenSulfix: "iqvia",
};
