import { EventEmitter, Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ChangeReportService {

  openChangeReportDialogEmitter: EventEmitter<any> = new EventEmitter();

  isOpenedByBreadcrumb;

  constructor() { }

  openedByBreadcrumb(flag){
    this.isOpenedByBreadcrumb = flag;
  }

  open(){
    this.openChangeReportDialogEmitter.emit(true)
  }

  close(){
    this.openChangeReportDialogEmitter.emit(false)
    this.isOpenedByBreadcrumb = undefined
  }

  getChangeReport(){
    return this.openChangeReportDialogEmitter;
  }
}
